@import '../../../styles/variables';

.#{$pdp-prefix}-container__row--cpg-summary {
  .#{$pdp-prefix}-media__text {
    font-size: $font-size-14;
    line-height: 1.29;
  }

  .#{$pdp-prefix}-color--BLACK.#{$pdp-prefix}-media__text {
    margin-top: 2px;
  }

  .#{$pdp-prefix}-color--GRAY.#{$pdp-prefix}-media__text + .#{$pdp-prefix}-color--BLACK.#{$pdp-prefix}-media__text {
    margin-top: 8px;
  }
}
