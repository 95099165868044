.#{$pdp-prefix}-share__content {
  &__list {
    display: flex;
    padding: 6px 18px 2px $andes-spacing-16;

    &__item {
      align-items: center;
      display: flex;
    }

    svg {
      width: 20px;

      &.ui-pdp-icon--email {
        width: 18px;
      }

      &.ui-pdp-icon--twitter {
        width: 25px;
      }
    }

    &__item:not(:last-of-type) {
      margin-right: 20px;
    }
  }
}
