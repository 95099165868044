@import './no-last-questions';

.#{$pdp-prefix}-qadb__#{$no-last-questions} {
  display: flex;
  margin-top: $andes-spacing-32;

  .#{$no-last-questions}__subtitle {
    padding-left: 3px;
  }
}
