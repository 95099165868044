.#{$pdp-prefix}-qadb__search-bar {
  margin-top: 40px;

  .ui-pdp-search-bar__title {
    font-size: 18px;
  }

  &__input {
    &.andes-form-control {
      padding-top: 0;
    }

    .andes-form-control__field {
      padding-right: 64px;

      &::placeholder {
        font-size: $font-size-16;
      }
    }

    &--shows-close {
      .andes-form-control__field {
        padding-right: 94px;
      }
    }
  }

  &__input-container {
    margin-top: 16px;
  }

  &__search {
    width: 64px;

    &:focus {
      box-shadow: 0 0 0 3px rgba($andes-blue-200, 0.3);
    }
  }

  &__clear-search {
    right: 80px;
  }

  &__auto-suggest {
    position: absolute;
    width: 100%;
    box-shadow: 0 8px 16px 0 rgba($andes-black, 0.15);
    background-color: $andes-white;
    border-radius: 6px;

    &__row {
      width: 100%;
      background-color: $andes-white;
      padding-left: 16px;
      padding-bottom: 18px;
      padding-top: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;

      &__icon {
        margin-right: 16px;
      }

      &:focus {
        outline: 0;
      }
    }

    &__row:hover {
      cursor: pointer;
      background-color: color(color_gray_lighter);
    }
  }

  &__tooltip {
    width: 418px;
    padding-right: 38px;
  }
}
