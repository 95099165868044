.#{$pdp-prefix-reviews}__comments {
  &__button {
    font-size: $font-size-14;
    display: block;
    margin-top: 25px;

    &:hover {
      text-decoration: none;
    }
  }

  &__review-comment {
    margin-top: 30px;
  }
}
