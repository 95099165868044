@import '../../styles/mixins';

.#{$pdp-prefix}-products {
  margin-top: $andes-spacing-32;

  &__list {
    margin-top: $andes-spacing-8;
  }

  &__label {
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    line-height: $line-height-s;
  }

  &__button {
    padding: 0;

    .andes-button__content {
      font-size: $font-size-14;
      justify-content: left;
    }

    .ui-pdp-price__part {
      color: inherit;
      margin-left: $andes-spacing-4;
      line-height: 18px;
    }
  }
}
