@import '../../../styles/variables';
@import '../../../styles/mixins';

.#{$vpp-prefix}-price-per-quantity-shortcuts {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: $andes-spacing-8;
  width: 100%;

  &__shortcut {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $andes-spacing-4;
    width: 100%;
    min-width: 155px;
    height: 60px;
    padding: $andes-spacing-8 $andes-spacing-12;
    background: $andes-bg-white;
    border: 1px solid $andes-gray-250;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    transition: border-color 0.2s ease;
    overflow: hidden;

    &:hover:not(&--selected) {
      border: 1px solid $andes-blue-base;
    }

    &--selected {
      border: 2px solid $andes-blue-base;
    }

    &--price {
      overflow: hidden;
      height: 20px;

      &-value.andes-money-amount {
        display: inline-flex;
      }

      &-discount {
        text-transform: uppercase;
      }
    }

    &--best-offer {
      position: absolute;
      top: 0;
      right: 0;
      text-transform: uppercase;
    }
  }
}
