@import './list';

.#{$pdp-prefix}-list {
  $self: &;

  overflow: auto;

  &__wrapper {
    display: flex;
  }

  &__item {
    padding-left: 0;
    padding-right: $andes-spacing-24;
    width: 50%;

    &::before {
      display: none;
    }
  }

  &--floating {
    #{$self}__item {
      width: 33.33%;
    }
  }

  &__item__label {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
  }

  &__text {
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
  }
}
