.#{$pdp-prefix}-qadb__how-to-make-question-button {
  display: flex;
  justify-content: center;
  margin-top: $andes-spacing-16;

  &__icon {
    position: absolute;
    left: 0;
    width: 19px;
    height: 18px;
    transform: translateY(-50%);
    top: 50%;
  }

  .andes-button__content {
    position: relative;
  }

  .andes-button__text {
    margin-left: 33px;
  }
}
