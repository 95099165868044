.onboarding {
  &__modal {
    border-radius: 6px;
    max-width: 644px;

    .andes-modal__content {
      height: 100%;
      justify-content: center;
      padding: 48px;
    }
  }

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    margin: 0 auto;
    max-width: 450px;

    .ui-pdp-container__row {
      justify-content: center;
      width: 100%;

      &:first-child {
        display: flex;
        justify-content: center;
      }

      .ui-pdp-media {
        margin-bottom: 20px;
        width: 100%;

        &__body {
          margin-left: 14px;
        }

        &__title {
          line-height: 22px;
        }

        &__text {
          line-height: 18px;
        }
      }

      .ui-pdp-actions {
        margin: 0 auto;
        width: 100%;
        max-width: 248px;
      }
    }
  }

  // Styles for specific modal types
  &__modal.full-modal {
    .ui-pdp-container__row {
      .ui-vpp-styled-label__cbt-image-label {
        line-height: 28px;
      }

      .ui-vpp-image-component {
        height: auto;
        margin: 0;
      }
    }
  }

  &__modal.full-modal-fs {
    max-height: 35em;

    .ui-pdp-container__row {
      .ui-vpp-styled-label__cbt-image-label {
        line-height: 28px;
      }

      .ui-vpp-image-component {
        height: auto;
        margin: 0;
      }

      &--full-label {
        .ui-pdp-icon {
          vertical-align: middle;
        }
      }

      &--full-first-info-row,
      &--full-second-info-row {
        margin-bottom: 40px;

        .ui-pdp-price__part {
          line-height: 1.35;
        }
      }
    }
  }

  &__modal.mcoin-modal {
    .andes-modal__content {
      padding: 30px;
    }

    .ui-pdp-actions {
      margin-top: 10px;
    }

    .mcoin-second-info-row,
    .mcoin-third-info-row {
      width: fit-content;

      &.mcoin-second-info-row {
        margin-top: 5px;
      }

      .ui-pdp-media__body {
        margin-left: 8px;
      }
    }
  }

  &__modal.cbt-modal {
    max-height: 426px;
    max-width: 480px;

    &--short {
      max-height: 380px;
    }

    .andes-modal__content {
      padding: 32px 48px 40px;
    }

    .ui-pdp-container__row {
      .ui-pdp-media {
        margin-bottom: 16px;

        &.cbt-third-info-row {
          margin-bottom: 0;
        }

        &:not(.cbt-image-label) .ui-pdp-media__body {
          width: 320px;
        }

        &__title--plain,
        &__text {
          line-height: 20px;
        }
      }

      .cbt-image-label {
        align-items: center;
        justify-content: center;
        margin-bottom: 0;

        .ui-pdp-icon--cbt-world {
          width: 23.4px;
          height: 23.4px;
        }

        .ui-pdp-icon--cbt-summary {
          width: 198px;
          height: 15px;
        }

        .ui-pdp-media__body {
          flex: unset;
          margin-left: 8px;
        }
      }

      .ui-pdp-actions {
        margin-top: 13px;
      }
    }
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  &__modal.cbt-modal--short {
    max-height: 348px;
  }

  &__modal.loan-summary-modal {
    height: 415px;

    .onboarding__container {
      max-width: 400px;
    }

    .loan-summary-image {
      margin: 0;
    }

    .loan-summary-label {
      line-height: 1.3;
      margin-bottom: 30px;
    }
  }

  &__modal.external-credits-modal {
    max-width: 480px;
    max-height: 387px;

    .andes-modal__content {
      padding: 32px;
    }

    .external-credits-modal-image {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $andes-spacing-20;
      margin-top: unset;

      img {
        width: 223px;
        height: 128px;
      }
    }

    .external-credits-modal-title {
      font-size: $andes-spacing-24;
      margin-bottom: $andes-spacing-8;
    }

    .external-credits-modal-subtitle {
      margin-bottom: 35px;
      width: 97%;
      line-height: 1.25;
    }

    .ui-pdp-container__row--modal-actions {
      .ui-pdp-actions {
        width: unset;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .andes-modal__close-button {
      color: color(color_gray_middle);
      top: $andes-spacing-32;
      right: $andes-spacing-32;
      margin-top: unset;

      &::after,
      &::before {
        background-color: color(color_gray_middle);
      }
    }
  }

  &__modal.external-credits-mc-modal {
    max-width: 480px;
    max-height: 369px;

    .andes-modal__content {
      padding: $andes-spacing-32;
    }

    .external-credits-modal-image {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $andes-spacing-32;
      margin-top: unset;

      img {
        width: auto;
        height: 115px;
      }
    }

    .external-credits-modal-title {
      font-size: $andes-spacing-24;
      margin-bottom: $andes-spacing-8;
    }

    .external-credits-modal-subtitle {
      margin-bottom: $andes-spacing-32;
      width: 90%;
      line-height: 1.25;
    }

    .ui-pdp-container__row--modal-actions {
      .ui-pdp-actions {
        width: unset;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .andes-modal__close-button {
      color: color(color_gray_middle);
      top: $andes-spacing-32;
      right: $andes-spacing-32;
      margin-top: unset;

      &::after,
      &::before {
        background-color: color(color_gray_middle);
      }
    }
  }

  &__modal.contacts-fraud-modal {
    max-width: calc(100% - 40px);

    .contacts-fraud-icon-modal {
      margin-block: $andes-spacing-8 $andes-spacing-32;
    }

    .andes-modal__content {
      padding: $andes-spacing-24 $andes-spacing-28;
    }

    .contacts-fraud-modal-title {
      line-height: $line-height-s;
      margin-bottom: $andes-spacing-16;
    }

    .contacts-fraud-modal-text-list {
      margin-bottom: $andes-spacing-24;
    }
  }

  &__modal.long-term-rental-modal {
    max-width: 480px;
    max-height: 400px;

    .long-term-rental-modal-image {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $andes-spacing-20;
      margin-top: unset;
    }

    .andes-modal-dialog__content {
      padding: $andes-spacing-32;
    }

    .long-term-rental-modal-title {
      margin-bottom: $andes-spacing-8;
    }

    .long-term-rental-modal-subtitle {
      margin-bottom: 35px;
      line-height: $line-height-s;
    }

    .ui-pdp-container__row--modal-actions {
      .ui-pdp-actions {
        width: unset;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.ui-pdp-component-list__item--desktop-onboarding {
  .andes-money-amount__cents.andes-money-amount {
    &__cents--superscript-XSMALL {
      font-size: 10px;
      font-weight: $font-weight-semibold;
      margin-top: 1px;
    }

    &__cents--superscript-SMALL {
      font-size: 10px;
      font-weight: $font-weight-semibold;
      margin-top: 1px;
    }
  }
}

/* stylelint-disable-next-line no-duplicate-selectors */
.onboarding__container {
  .ui-pdp-container__row.ui-pdp-container__row--meli-usd-modal-title {
    span {
      display: inline-flex;
    }
  }
}
