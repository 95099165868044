@import 'variables';
@import '../components/comments/comments';
@import '../components/rating/rating';

.#{$pdp-prefix-reviews} {
  &__header {
    margin-bottom: $andes-spacing-32;

    &__title {
      color: color(color_gray_dark);
      margin-bottom: $andes-spacing-32;
    }
  }

  .andes-tab__link {
    font-size: $font-size-16;
    padding: 0.8em 1.5em;
  }
}
