@import './on-demand-iframe';

$modal-width: 412px;
$desktop-height-breakpoint: 900px;

.iframe-modal--hidden {
  display: none;
}

.andes-modal__overlay--large > .on-demand-iframe--desktop {
  border-radius: 0;
}

.on-demand-iframe--desktop {
  display: block;
  position: absolute;
  right: 0;
  height: 100vh;
  width: $modal-width;
  animation: translate-in 0.3s ease-in-out;

  .andes-modal__header {
    padding: $andes-spacing-20 $andes-spacing-24;

    .andes-modal__header__contents {
      min-height: 16.25px;
    }

    .andes-modal__close-button {
      path {
        stroke: none;
      }
    }
  }

  .andes-modal__scroll {
    height: 100%;
    overflow-y: hidden;
  }

  .andes-modal__content {
    height: calc(100% - 64px);
  }

  iframe {
    height: 100%;
  }
}

@keyframes translate-in {
  from {
    width: 0;
  }

  to {
    width: $modal-width;
  }
}

@media (max-height: $desktop-height-breakpoint) {
  .on-demand-iframe--desktop {
    .andes-modal__header {
      padding: $andes-spacing-16 $andes-spacing-24;
    }

    .andes-modal__content {
      height: calc(100% - 48.25px);
    }
  }
}
