.#{$pdp-prefix}-shipping {
  $self: &;

  .ui-pdp-price__part {
    color: color(color_gray_dark);

    &__previous {
      color: color(color_gray_middle);
    }
  }

  &__action-icon {
    width: 10px;
    height: 14px;
    margin-right: 6px;
  }

  &--md {
    .#{$pdp-prefix}-media__text {
      font-size: $font-size-14;
      line-height: 1.29;

      .ui-pdp-price__part {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        display: inline-block;

        .price-tag-cents {
          vertical-align: top;
          font-size: 0.7em;
          position: relative;
        }
      }
    }
  }

  &--first-buy {
    .#{$pdp-prefix}-media__title .#{$pdp-prefix}-color--BLACK.#{$pdp-prefix}-family--REGULAR {
      white-space: nowrap;
    }
  }

  &--upselling-meliplus-cashback {
    .ui-pdp-price__part {
      color: $andes-success-green;
      font-weight: 600;
    }
  }
}
