@import '../../../styles/variables';
@import '../../../styles/mixins';

$namespace: 'dots-navigation';
$transition-time: 350ms;

.#{$namespace} {
  align-items: center;
  display: flex;
  height: 12px;
  justify-content: center;
  list-style: none;
  margin: 0.5rem 0 0;
  padding: 0;
  pointer-events: none;
  position: relative;
  width: 100%;

  li {
    @include square(10px);

    margin: 0 4px;
    position: relative;
    transition: width $transition-time, height $transition-time, background-color $transition-time;
  }

  li span {
    background-color: $andes-gray-100-solid;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: background-color $transition-time;
    width: 100%;
  }

  // sass-lint:disable no-qualifying-elements //
  li span.#{$namespace}--active {
    background-color: $andes-blue-500;
    transition: background-color $transition-time;
  }

  // sass-lint:disable no-qualifying-elements //
  li.#{$namespace}__circle span {
    border-radius: 50%;
  }

  // sass-lint:disable no-qualifying-elements //
  li.#{$namespace}__triangle {
    height: 12px;

    span {
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
    }
  }

  // sass-lint:disable no-qualifying-elements //
  &.#{$namespace}--dark-theme > li > span {
    background-color: $andes-white-400;

    &.#{$namespace}--active {
      background-color: $andes-bg-white;
    }
  }

  // sass-lint:disable no-qualifying-elements //
  li.#{$namespace}--distance-1 {
    @include square(8px);

    transition: width $transition-time, height $transition-time;

    // sass-lint:disable no-qualifying-elements //
    &.#{$namespace}__triangle {
      height: 10px;
    }
  }

  // sass-lint:disable no-qualifying-elements //
  li.#{$namespace}--distance-2 {
    @include square(6px);

    transition: width $transition-time, height $transition-time;

    &.#{$namespace}__triangle {
      height: 8px;
    }
  }

  // sass-lint:disable no-qualifying-elements //
  li.#{$namespace}--distance-3 {
    height: 0;
    margin: 0;
    opacity: 0;
    transition: width $transition-time, height $transition-time;
    width: 0;
  }
}
