// VPP Common
@import '../../../styles/common-andes-fixes';
@import '../../../styles/common-navigation-fixes';
@import '@vpp-frontend-components/styled-label/index';
@import '../../../styles/vpp.technical-error';

// HI Component web
@import '~@hi-components-web/calculator/index';

// CxWidget daisy
@import '~help-daisy/styles/CxWidget';

// PDP Internal imports
@import '../../../components/iframe/styles/iframe';
@import '../../../components/thumbnail/thumbnail';
@import '../../../components/benefits/styles/benefits';
@import '../../../components/additional-info/styles/additional-info';
@import '../../../components/buy-protected/styles/buy-protected';
@import '../../../components/buybox/actions/styles/action';
@import '../../../components/buybox/styles/buybox';
@import '../../../components/grid/styles/grid';
@import '../../../components/media/media';
@import '../../../styles/icons';
@import '../../../components/title/styles/title';
@import '../../../components/payment/payment';
@import '../../../components/share/share';
@import '../../../components/specs/specs';
@import '../../../components/shipping/shipping';
@import '../../../components/pick-up/pick-up';
@import '../../../components/summary-list/summary-list';
@import '../../../components/placeholder/placeholder';
@import '../../../components/reviews/styles/reviews';
@import '../../../components/flash-message/flash-message';
@import '../../../components/returns/styles/returns';
@import '../../../components/card-tooltip/styles/card-tooltip';
@import '../components/views/not-found/not-found';
@import '../components/views/error/error';
@import '../../../components/qadb/styles/qadb';
@import '../../../components/rating-levels/styles/rating-levels';
@import '../../../components/data-display/progress-bar/styles/progress-bar';
@import '../../../components/seller-info/styles/seller-info';
@import '../../../components/seller-data/styles/seller-data';
@import '../../../components/seller-data/styles/banner';
@import '../../../components/seller-data/styles/header';
@import '../../../components/seller-data/styles/status';
@import '../../../components/seller-data/styles/footer';
@import '../../../components/buybox/counter-button/component/styles/counter-button';
@import '../../../components/buybox/cart-actions/styles/cart-actions-container';
@import '../../../components/buybox/cart-actions/styles/cart-actions';
@import '../../../components/apparel-size-specs/styles/apparel-size-specs';
@import '../../../components/loyalty/styles/loyalty';
@import '../../../components/exit-popup/styles/exit-popup';
@import '../../../components/exit-popup-web-mobile/styles/exit-popup-web-mobile';
@import '../../../components/on-demand-iframe/styles/on-demand-iframe';
@import '../../../components/card-with-thumbnails/styles/card-with-thumbnails';
@import '../../../components/clip-video/styles/';
@import '../../../components/clip-multimedia/styles/';
@import '../../../components/dots-navigation/styles/dots-navigation';
@import '../../../components/enhanced-content/styles/enhanced-content';
@import '../../../components/price-per-quantity-shortcuts/styles/price-per-quantity-shortcuts';
@import '../../../components/coachmarks/styles/coachmarks';

$pdp-tap-highlight-color: color(color_black_01);

.#{$pdp-prefix} {
  font-size: $font-size;
  margin: 0 auto;

  &-separator {
    width: 1px;
    height: 12px;
    background-color: color(color_gray_middle);
    display: inline-block;
    vertical-align: 2px;
    margin: 0 10px;
  }

  a {
    -webkit-tap-highlight-color: $pdp-tap-highlight-color; // sass-lint:disable-line no-vendor-prefixes
  }

  sup {
    vertical-align: top;
    font-size: 10px;
    position: relative;
    top: 2px;
  }
}

.andes-tabs__wrapper {
  box-shadow: unset;
}

.andes-tabs__slider {
  border-radius: 3px 3px 0 0;
}

.ui-recommendations-comparator {
  .andes-tabs-content {
    position: relative;
  }

  .andes-tabs-content::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 0;
    box-shadow: inset 0 -1px 0 0 rgb(0 0 0 / 10%); // sass-lint:disable-line no-color-literals
    pointer-events: none;
    z-index: 3;
  }

  .andes-tabs__slider {
    height: 2px !important;
  }
}

.ui-review-capability-vpp {
  width: 100%;
  margin-bottom: 80px;

  .andes-button[type='submit'] {
    width: 100%;
    margin-top: $andes-spacing-20;
  }

  @media (width >= 800px) {
    padding: 0 $andes-spacing-24;
  }
}
