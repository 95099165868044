// sass-lint:disable class-name-format
@import '../../styles/mixins';
@import '../../styles/variables';

$pdp-prefix-other-sellers-item: 'ui-pdp-other-sellers-item';
$pdp-other-sellers-animation-speed: 0.3s;

.#{$pdp-prefix-other-sellers-item} {
  border-bottom: 1px solid $andes-gray-100;
  padding: 0;

  &__tag {
    background-color: $andes-bg-secondary;
    border-radius: 10px;
    color: color(color_gray_middle);
    font-size: $font-size-12;
    font-weight: $font-weight-semibold;
    padding: 2px $andes-spacing-8;
    margin-left: auto;
    position: absolute;
  }

  &__list-top {
    padding: $andes-spacing-16;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;
    position: relative;

    .ui-pdp-icon {
      margin-left: $andes-spacing-8;
    }

    .ui-pdp-other-sellers-item__price {
      .ui-pdp-price {
        .ui-pdp-price__main-container {
          display: flex;
          flex-direction: column;
          align-items: start;

          .ui-pdp-price__second-line {
            margin-left: 0;
            padding-left: 0;
          }
        }
      }
    }
  }

  &__list {
    padding: 0 $andes-spacing-16;
    margin-bottom: $andes-spacing-16;
    overflow: hidden;
    transition-property: height, margin, opacity;
    transition-duration: $pdp-other-sellers-animation-speed;
    transition-timing-function: ease-out;
  }

  .#{$pdp-prefix-other-sellers-item}__chevron {
    transform: rotate(270deg);
    transition: transform $pdp-other-sellers-animation-speed ease-out;
  }

  &--is-collapsed {
    .#{$pdp-prefix-other-sellers-item}__list-top {
      cursor: pointer;
    }

    .#{$pdp-prefix-other-sellers-item}__list {
      margin-top: 0;
      margin-bottom: 0;
      opacity: 0;
    }

    .#{$pdp-prefix-other-sellers-item}__chevron {
      transform: rotate(90deg);
    }
  }

  &--is-not-ready {
    .#{$pdp-prefix-other-sellers-item}__list {
      opacity: 1;
    }
  }

  &__price {
    font-size: $font-size-20;
    line-height: 1;

    .ui-pdp-price {
      font-weight: $font-weight-semibold;
      margin: 0;

      &__second-line {
        line-height: 1.3;

        .ui-pdp-price__part {
          font-weight: $font-weight-semibold;
        }
      }

      &__original-value {
        font-weight: $font-weight-semibold;
      }
    }
  }

  &__cbt {
    .ui-pdp-icon--cbt-summary {
      width: 173px;
      height: 16px;
    }

    .ui-pdp-icon--cbt-world {
      height: 16px;
      width: 18px;
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .ui-pdp-color--BLACK {
    &::before {
      background: color(color_gray_dark);
    }
  }

  &__seller {
    color: color(color_gray_middle);
    font-size: $font-size-12;
  }

  &__actions {
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    margin-top: $andes-spacing-16;

    .ui-pdp-actions__container {
      display: flex;
      align-items: center;

      .andes-button {
        margin-top: 0;
        padding: 0 6px;

        &__content {
          line-height: 1;
          font-size: $font-size-12;
        }

        & + .andes-button {
          margin-left: $andes-spacing-8;
        }
      }
    }
  }

  &__media {
    .ui-pdp-media__figure {
      margin: 0 8px 0 0;
      height: 15px;

      svg {
        width: 15px;
        height: 15px;
      }
    }

    .ui-pdp-media__title {
      font-size: $font-size-14;

      .ui-pdp-price {
        line-height: 1.4em;
      }
    }
  }
}

/* stylelint-disable-next-line no-duplicate-selectors */
.#{$pdp-prefix-other-sellers-item}__seller {
  margin-top: $andes-spacing-16;

  .ui-pdp-seller__header {
    margin: 0;
  }

  .ui-pdp-seller__header__title,
  .ui-pdp-seller__label-bill,
  .ui-pdp-seller__header__subtitle,
  .ui-pdp-seller__link-trigger a {
    font-size: $font-size-12;
    margin-top: 0;
  }

  &.ui-pdp-seller__with-logo {
    .ui-pdp-seller__header__title {
      display: flex;
      align-items: baseline;
      margin-top: -2px;

      .ui-pdp-seller__brand-title-container {
        .ui-pdp-seller__brand-title {
          margin-top: 1px;
        }

        .ui-pdp-cockade-icon {
          bottom: -2px;
        }
      }
    }
  }

  .ui-pdp-seller__header__subtitle {
    line-height: 14px;
    margin-top: 2px;
  }

  .ui-pdp-seller__link-trigger {
    display: inline-block;
    margin-top: 0;
  }
}
