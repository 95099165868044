.on-demand-iframe {
  .andes-modal__content {
    padding: 0;
  }

  iframe {
    overflow: hidden;
    width: 100%;
    border: 0;
    -webkit-overflow-scrolling: touch;
    user-select: none;
  }

  .center-spinner {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    border-radius: 10px 10px 0 0;
  }

  &--no__close__button {
    .andes-modal__close-button {
      display: none;
    }
  }
}
