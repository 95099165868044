.#{$pdp-prefix}-seller {
  &__container-qualification {
    display: flex;
    flex-direction: column;

    .ui-pdp-seller__header + .ui-thermometer {
      margin-top: $andes-spacing-16;
    }
  }

  &__container-info {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
  }

  &__link-trigger {
    font-weight: $font-weight-regular;
    color: color(color_gray_dark);
    margin-left: 2px;

    &:hover,
    &:visited {
      color: $andes-black;
    }
  }

  &__icon-arrow {
    margin: 10px 0;
  }

  &__list-info {
    width: 165px;
  }

  &__list-description {
    margin-bottom: 0;
    display: flex;
  }

  &__item-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: $font-size-12;
    position: relative;
    line-height: 1;
    padding: 0 5px;

    &::after {
      background: $light-grey;
      content: '';
      height: 91%;
      position: absolute;
      right: 0;
      top: 8%;
      width: 1px;
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }

  &__sales-description {
    font-size: $font-size-24;
    color: $andes-black;
    line-height: 1.2;
    font-weight: $font-weight-regular;
    display: block;
  }

  &__icon-description {
    display: inline-block;
    width: 28px;
    padding-top: 3px;
    vertical-align: top;
  }

  &__header + &__seller-status {
    margin-top: $andes-spacing-24;
  }

  &__seller-status + .ui-thermometer {
    margin-top: 28px;
  }

  &__status-title {
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    color: $andes-success-green;
  }

  &__text-description {
    margin-top: 8px;
    line-height: 1;
  }

  &__status-text {
    margin: 0;
    font-size: $font-size-14;
    color: color(color_gray_middle);
    line-height: 1.2;
    font-weight: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__subtitle-info {
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    color: $andes-black;
  }

  &__text-info {
    font-size: $font-size-14;
    line-height: 1.2;
  }

  &__item-info {
    margin-top: 21px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__reputation-info {
    margin-top: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    align-content: baseline;
    margin-bottom: 18px;

    &__info-container__title {
      display: flex;
      align-items: center;

      .ui-pdp-seller__header__title-label {
        display: flex;

        span {
          margin-left: 2px;
        }
      }

      .ui-pdp-cockade-icon {
        margin-left: $andes-spacing-4;
        position: relative;
        bottom: -2px;
      }
    }

    &__info-container__subtitle {
      display: flex;
      align-items: center;

      &-one-line {
        display: flex;
        align-items: center;

        .ui-pdp-cockade-icon {
          margin-right: $andes-spacing-4;
          align-self: center;
        }

        .#{$pdp-prefix}-seller__header__subtitle {
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          width: 100%;
        }
      }
    }

    &__image-container {
      width: 48px;
      height: 48px;
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 14px;
      padding: 2px;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: color(color_gray_lighter);
        border-radius: 4px;
        left: 0;
      }

      &__image {
        border-radius: 4px;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__title {
      color: $andes-black;
      font-weight: $font-weight-regular;
      font-size: $font-size-14;
      display: flex;
    }

    &__subtitle-separator {
      margin: 0 3px;
    }
  }

  &__extra-info {
    margin-top: $andes-spacing-20;

    &__title {
      font-weight: $font-weight-semibold;
      font-size: $font-size-16;
      color: $andes-black;
    }

    &__subtitle {
      margin-top: $andes-spacing-8;
      font-size: $font-size-14;
      color: color(color_gray_middle);
    }
  }

  &__with-logo {
    .ui-pdp-seller__header {
      margin-bottom: 0;
    }
  }
}
