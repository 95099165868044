@import '../components/content-trade-in/';

$trade-in-prefix: #{$pdp-prefix}-trade-in;

.#{$trade-in-prefix} {
  position: relative;
  width: 100%;

  &__card {
    margin-top: $andes-spacing-24;

    &--content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: $andes-spacing-12 0 $andes-spacing-16;

      .text {
        width: 100%;
      }

      .text__title {
        display: flex;
        align-items: center;
        border-bottom: 1px solid $andes-gray-100-solid;
        padding: $andes-spacing-12 $andes-spacing-16;

        &--info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        &--primary {
          padding: $andes-spacing-12 $andes-spacing-16;
        }

        .ui-pdp-media__figure {
          height: 18px;
          width: $andes-spacing-16;
          margin-right: 6px;
        }

        .ui-pdp-icon {
          width: 14px;
          height: 15px;
        }

        .text__plan-canje {
          display: inherit;
          align-items: center;
        }

        .ui-pdp-action-modal {
          margin-top: 0;
        }

        .andes-tooltip__trigger {
          position: unset;
        }

        .ui-pdp-action-modal__link {
          display: flex;
          font-size: $font-size-12;
          font-weight: $font-weight-semibold;
        }
      }

      .text__second-line {
        span {
          display: inline;
        }

        .ui-pdp-price__part {
          font-weight: $font-weight-semibold;
        }
      }
    }
  }

  &__spinner-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $andes-white;
    opacity: 0.9;
    z-index: 1;

    &__spinner {
      display: unset;
      left: calc(50% - $andes-spacing-16);
      top: calc(50% - $andes-spacing-16);
    }
  }

  &__skeleton {
    margin-top: $andes-spacing-40;
    margin-bottom: $andes-spacing-16;
  }

  .ui-pdp-media__title {
    font-weight: $font-weight-semibold;
    font-size: $font-size-14;
  }
}

.ui-pdp-remove-trade-in {
  .andes-modal__content,
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__title,
  &__subtitle {
    line-height: $line-height-base;
  }

  &__title {
    margin-bottom: $andes-spacing-12;
  }

  &__actions {
    display: flex;
    gap: $andes-spacing-8;
  }

  .andes-modal__scroll {
    display: flex;
    justify-content: space-between;

    .andes-modal__content {
      padding: 0;
    }

    .andes-modal__header {
      display: none;
    }
  }
}

.andes-progress-indicator-circular.andes-progress-indicator-circular--fullscreen {
  background-color: $andes-white;
}
