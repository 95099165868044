$pdp-trigger-border-color: color(color_black_20);
$pdp-trigger-background-error-color: color(color_black_05);

@mixin label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 20px;
  max-width: 220px;
}

.andes-dropdown.andes-dropdown--form.andes-form-control--error
  .andes-floating-menu
  .andes-dropdown__trigger:focus:not(:focus-visible) {
  box-shadow: inset 0 0 0 2px #f23d4f;
}

.andes-dropdown--form.ui-pdp-dropdown-selector.andes-form-control {
  padding-top: 0;

  .andes-card__content {
    .andes-list.andes-floating-menu.andes-list--default.andes-list--selectable {
      overflow-x: hidden;
    }
  }

  button {
    margin-top: 0;
  }
}

.#{$pdp-prefix}-dropdown-selector {
  &.andes-dropdown--form {
    padding-top: 0;

    .andes-dropdown__trigger {
      height: 48px;
      justify-content: space-between;

      .#{$pdp-prefix}-dropdown-selector__item {
        display: inline;
      }

      .#{$pdp-prefix}-dropdown-selector__item-with-thumbnail {
        display: inline-flex;
      }

      .#{$pdp-prefix}-dropdown-selector__item--label-small .#{$pdp-prefix}-dropdown-selector__item--subtitle {
        width: fit-content;
      }
    }

    .andes-floating-menu {
      width: 100%;
      left: 0;

      .andes-list__item {
        height: 48px;
        font-size: 18px;
        padding: 14px $andes-spacing-8;
      }

      .andes-list__item-text {
        width: 100%;
      }

      .andes-list__item-primary {
        display: flex;
        justify-content: space-between;
      }

      .andes-list__item-secondary {
        position: absolute;
        right: $andes-spacing-8;
        bottom: $andes-spacing-16;
      }
    }
  }

  .andes-dropdown__trigger &__item--label,
  .andes-dropdown__trigger &__item--subtitle {
    width: fit-content;
  }

  &__item {
    display: flex;
    position: relative;
    align-items: center;

    &-first-column {
      margin-left: 2px;
    }

    &--label {
      @include label;

      width: 185px;
    }

    &--label-small {
      @include label;
    }

    &--subtitle {
      margin-left: $andes-spacing-4;
      height: 20px;

      &::before {
        content: '|';
        margin-right: $andes-spacing-4;
      }
    }

    &--secondary_text {
      font-size: $font-size-12;
      color: color(color_gray_middle);
      line-height: 1.1;
      margin-left: $andes-spacing-8;
      margin-right: $andes-spacing-12;
      max-width: 90px;
      align-items: center;
      text-align: right;

      + .#{$pdp-prefix}-dropdown-selector__item--label {
        padding-right: 80px;
      }
    }

    &-blocked {
      img {
        opacity: 0.5;
      }

      .#{$pdp-prefix}-dropdown-selector__item--secondary_text {
        top: 3px;
      }
    }
  }

  &__thumbnail {
    width: 32px;
    height: 32px;
    margin-right: 12px;

    + .#{$pdp-prefix}-dropdown-selector__item--label {
      margin-top: 6px;
    }
  }

  &--error .andes-dropdown__trigger {
    border: 1px solid color(color_red);
    background-color: $pdp-trigger-background-error-color;
  }

  &--warning .andes-dropdown__trigger {
    border: 1px solid color(color_orange);
  }
}
