// sass-lint:disable class-name-format
@import '../../../../styles/variables';

.#{$pdp-prefix}-payment-sprites,
.#{$pdp-prefix}-payment-icon {
  display: flex;
  align-items: center;
  margin-top: 4px;
  flex-wrap: wrap;

  /* stylelint-disable-next-line selector-class-pattern */
  .ui-vip-payment_methods__icon {
    margin: 0 20px 20px 0;
  }
}

// @TODO refactor a display:     display: grid; grid-template-columns: repeat(4,1fr);
.#{$pdp-prefix}-generic-summary,
.#{$pdp-prefix}-payment {
  .#{$pdp-prefix}-payment-sprites__icon-container,
  .#{$pdp-prefix}-payment-icon__container {
    margin: 0 0 0 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}
