@import '../../../styles/variables';

.#{$pdp-prefix}-features {
  font-weight: $font-weight-semibold;
  list-style: none;
  padding: 0;

  &__item {
    padding-left: 11px;
    text-indent: -10px;
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 2px;
    font-weight: $font-weight-regular;

    &::before {
      content: '•';
      padding-right: 5px;
    }
  }
}
