.#{$pdp-prefix}-extended-menu {
  margin-top: $andes-spacing-40;

  .seo-ui-extended-menu__header__title {
    font-size: $font-size-20;
    font-weight: $font-weight-semibold;
    line-height: $line-height-base;
    margin-top: $andes-spacing-8;
    color: $andes-gray-900;
  }

  .seo-ui-extended-menu {
    margin-bottom: $andes-spacing-8;
  }
}
