.#{$pdp-prefix}-share {
  align-items: center;
  display: flex;
  font-size: $font-size-14;
  line-height: 1;

  .andes-button__content {
    top: 1px;
  }

  &__tooltip {
    width: auto;
    padding: unset;

    .andes-tooltip__trigger {
      display: inline-block;
      width: 100px;
    }

    .andes-tooltip-button-close {
      display: none;
    }
  }

  // FIXME: remove the important when the update to Andes8 is done
  &__link__label {
    color: $andes-blue-500 !important;
    font-size: $font-size-14 !important;
    font-weight: $font-weight-regular !important;
    line-height: $line-height-xs !important;
    height: 14px !important;
    padding: 0 $andes-spacing-8 !important;

    &:hover {
      cursor: pointer;
      background-color: transparent;
    }
  }

  &__link {
    &__input {
      display: none;
    }

    &__input:checked + div {
      display: block;
    }

    &__tooltip {
      display: none;
      padding: $andes-spacing-8;
      background: $andes-white;
      border-radius: $andes-spacing-4;
      position: absolute;
      margin-top: 26px;
      margin-left: -88px;
      box-shadow: 0 1px 3px $andes-gray-250;
      border: 1px solid $andes-bg-primary;
    }
  }
}

// agregamos el separador cuando viene el bloque de vender uno igual o editar
.#{$pdp-prefix}-eyi + .#{$pdp-prefix}-share::before,
.#{$pdp-prefix}-syi + .#{$pdp-prefix}-share::before {
  content: '';
  display: inline-block;
  width: 0.5px;
  height: 14px;
  background-color: $pdp-gray-200;
  margin: 2px 1px 0 11px;
  vertical-align: middle;
}
