@import '../../../styles/variables';

$video-overflow-compensation: 101%;

.pdp-enhanced-content {
  &__content {
    display: block;
    width: 100%;
  }

  &__title {
    padding: 0 $andes-spacing-20;
    font-size: $font-size-20;
    font-weight: $font-weight-regular;
    margin-bottom: $andes-spacing-24;
  }

  &__image-wrapper {
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    height: fit-content;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1;
  }

  &__play-icon {
    width: 60px;
    height: 60px;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  &__pictures {
    display: contents;

    .video-js:has(> div) {
      aspect-ratio: 16 / 9;
    }

    .vjs-poster {
      height: $video-overflow-compensation;
    }

    .video-js {
      width: 100%;
      height: 100%;
    }

    .vjs-poster img {
      height: fill-available;
      object-fit: initial;
    }

    .vjs-big-play-button {
      display: none;
    }

    .vjs-tech {
      width: $video-overflow-compensation;
      height: $video-overflow-compensation;
    }
  }

  &__expandable {
    position: relative;
    border-bottom: 1px solid $andes-white;
  }

  &__gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 300px;
    background-image: linear-gradient(to bottom, rgb(255 255 255 / 0%), #fff);
  }

  &__expande-btn {
    cursor: pointer;
    background: none;
    border: none;
    margin-top: $andes-spacing-16;
    color: $andes-blue-500;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    padding: 0;
  }

  &__chevron {
    transform: rotate(90deg);
    margin-left: 6px;
    color: $andes-blue-500;
  }

  &__wrapper {
    position: relative;
    overflow: hidden;
  }

  &__poster-wrapper {
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
  }

  &__poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__blacker {
    position: absolute;
    inset: 0;
    border: none;
    transition: 0.3s;
    background-color: rgb(0 0 0 / 50%);
    width: 100%;
    height: 100%;
    z-index: 1;
    animation: fadeIn 0.2s forwards;

    &--hidden {
      animation: fadeOut 0.2s forwards;
      all: unset;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    z-index: 10;

    button {
      background: none;
      border: none;
      cursor: pointer;
      position: relative;
    }
  }

  &__backwards {
    transition: 0.3s;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    width: fit-content;
    height: fit-content;
    background: none;
    border: none;
    cursor: pointer;
    color: $andes-white;
    z-index: 1;
  }

  &__backwards-icon {
    width: 60px;
    height: 60px;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  &__forwards {
    transition: 0.3s;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    width: fit-content;
    height: fit-content;
    background: none;
    border: none;
    cursor: pointer;
    color: $andes-white;
    z-index: 1;
  }

  &__forwards-icon {
    width: 60px;
    height: 60px;
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0; /* Start state */
    }

    to {
      opacity: 1; /* End state */
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1; /* Start fully opaque */
    }

    to {
      opacity: 0; /* End fully transparent */
    }
  }
}
