.#{$pdp-prefix}-qadb__card-answer {
  margin-top: $andes-spacing-24;

  &__smart-answers {
    position: relative;
    background-color: color(color_gray_lighter);
    border-radius: 4px;

    &::before {
      border-left: 4px solid $andes-blue-500;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -1px;
      border-radius: $border-radius-6 0 0 $border-radius-6;
    }
  }
}
