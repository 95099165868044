@import '../../../styles/variables';

.#{$vpp-prefix}-denounce {
  display: flex;
  font-size: $font-size-14;
  font-weight: $font-weight-regular;

  &__info {
    padding: 0 12px;
  }

  &__code-internal {
    padding: 0 12px;
    position: relative;

    &::after {
      content: '';
      width: 1px;
      height: 12px;
      display: inline-block;
      background-color: $andes-gray-250;
      position: absolute;
      top: 3px;
      right: 0;
    }
  }

  &__action {
    position: relative;
    padding: 0 12px;

    &::before {
      content: '';
      width: 1px;
      height: 12px;
      display: inline-block;
      background-color: $andes-gray-250;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
}
