.#{$pdp-prefix}-related-search {
  margin-top: $andes-spacing-16;
  margin-bottom: $andes-spacing-12;
  width: 900px;
  height: 20px;
  overflow-wrap: break-word;
  overflow: hidden;
  color: color(color_gray_dark);

  &__item {
    display: inline;
    font-size: 14px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    color: color(color_gray_dark);

    &:not(:first-of-type, :nth-child(2))::before {
      content: '-';
      padding: 0 $andes-spacing-4;
    }

    &:first-of-type {
      font-weight: 600;

      &::after {
        content: ':';
        padding: 0 $andes-spacing-4 0 0;
      }
    }

    &:last-of-type {
      &::after {
        content: none;
      }
    }
  }

  &__list {
    color: color(color_gray_dark);
    font-weight: $font-weight-regular;
  }

  &__link {
    color: color(color_gray_dark);

    &:active,
    &:visited {
      color: color(color_gray_dark);
    }

    &:hover {
      color: color(color_gray_dark);
      text-decoration: none;
    }
  }
}
