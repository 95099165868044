// prefix
$pdp-prefix-reviews: 'ui-pdp-reviews';

// font-size
$font-size-64: 64px;

// line-height
$line-height-base-flat: 1.3;
$line-height-sm: 1.14;
$line-height-ssm: 1.03;
$line-height-xxs: 0.53;

// spacing
$spacing-6: 6px;
$spacing-14: 14px;
$spacing-15: 15px;
$spacing-20: 20px;
$spacing-25: 25px;
$spacing-30: 30px;
$spacing-42: 42px;
