.#{$compats-prefix}-highlight-icon {
  width: $andes-spacing-16;
  height: $andes-spacing-16;
  margin-right: $andes-spacing-4;
}

.#{$compats-prefix}-highlight {
  display: inline-flex;
  margin: $andes-spacing-8 0;
  line-height: 15px;

  &__tooltip-compats {
    max-width: 300px;
    padding: $andes-spacing-16 $andes-spacing-32 $andes-spacing-16 $andes-spacing-16;
    position: relative;
    white-space: normal;

    .andes-tooltip-button-close {
      top: 10px;
      right: 10px;

      &::before,
      &::after {
        opacity: 1;
      }
    }

    .andes-tooltip__text {
      line-height: 18px;
    }
  }
}
