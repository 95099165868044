@import '../../../styles/variables';

.calculator-wrapper {
  &--not-logged {
    position: relative;
    pointer-events: all;
    cursor: pointer;
  }

  &__calculator {
    &--not-logged {
      pointer-events: none;
    }
  }
}
