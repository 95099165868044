@import './html-description';

.#{$pdp-prefix}-description {
  min-height: 78px; // For Spinner

  .lazy-loadable {
    min-height: 100px;
  }
}

.#{$pdp-prefix}-description-content {
  margin-left: -5px;
  margin-right: -5px;
}

.#{$pdp-prefix}-description__action--center {
  text-align: left;
  position: relative;

  .#{$pdp-prefix}-collapsable__container {
    text-align: left;
  }

  .#{$pdp-prefix}-collapsable__action {
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    margin-top: $andes-spacing-24;
  }
}

.#{$pdp-prefix}-description__spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  .andes-spinner__mask {
    display: none;
  }

  .andes-spinner__icon {
    height: 64px;
    width: 64px;
  }
}
