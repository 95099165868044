.#{$pdp-prefix}-questions {
  &__stock-modal {
    width: 100%;

    &__text {
      font-size: $font-size-16;
      color: color(color_gray_dark);
    }

    &__button {
      margin-top: 24px;
      margin-bottom: 16px;
    }

    .andes-modal__header {
      padding: 24px 32px;

      &-title {
        font-size: $font-size-24;
        font-weight: $font-weight-semibold;
        color: color(color_gray_dark);
      }
    }

    .andes-modal__content {
      padding: 0 32px 32px;
    }
  }
}
