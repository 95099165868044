.nav-search .nav-category {
  right: 46px;
  padding: 2px 14px;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
.nav-search .nav-category input[type='checkbox'] {
  margin: 3px 9px 0 0;
  @media (width >= 768px) {
    vertical-align: top;
  }
}
