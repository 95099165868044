@import '../quantity/styles/quantity.desktop';
@import '../payment/styles/payment-sprites';

.#{$pdp-prefix}-buybox {
  font-size: $font-size-14;
  width: 100%;
  border: solid 1px $andes-gray-100;
  padding: 25px $andes-spacing-16;
  border-radius: 8px;

  &__container {
    width: 100%;
  }

  .#{$pdp-prefix}-icon--chevron {
    vertical-align: bottom;
    height: 18px;
    width: 7px;
    margin-right: 2px;
    transition: transform 0.15s linear;

    .#{$pdp-prefix}-icon--chevron--rotate-180 {
      transform: rotate(-180deg);
    }
  }

  .#{$pdp-prefix}-seller {
    &__brand-title {
      margin-bottom: 4px;
    }
  }
}

// action tooltip
.#{$pdp-prefix}-action__tooltip {
  width: 290px;
}

.#{$pdp-prefix}-iframe-modal {
  max-width: 100%;
  transition: 0.5s cubic-bezier(0.72, 0.04, 0.12, 0.97);
  width: 840px;
  height: 70%;
  max-height: 92%;

  .andes-modal__content {
    padding: 0;
  }
}

.andes-modal.ui-pdp-action-modal__modal.ui-pdp-iframe-modal.ui-pdp-fc-us-taxes.andes-modal--small {
  width: 568px;

  .andes-modal__header {
    padding: $andes-spacing-32 $andes-spacing-32 0;
  }

  .andes-modal__scroll {
    overflow: hidden;
    border-radius: $border-radius-6;
  }

  .andes-modal__content {
    height: 50%;
  }
}
