@import '../../../styles/variables';

@mixin header-brand-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $pdp-gray-01;

  &__image {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
