@import '../../../styles/variables';

$max-height-modal: 780px;
$ui-recommendations: 'ui-recommendations';
$andes-modal-name: 'andes-modal';

.#{$pdp-prefix}-exit-popup-web-mobile {
  height: 600px;

  &__div-modal-container {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    background-color: $pdp-white;
  }

  &__div-modal-items-list {
    height: auto;
    max-width: 790px;
    max-height: $max-height-modal;
    margin: 0 32px 24px;
  }

  &__card-item {
    width: 400px;
  }

  &__div-close-button-container {
    position: absolute;
    top: -40px;
    z-index: 11;
    right: -5px;
  }

  &__div-modal-button {
    width: 100%;
    display: flex;
    position: sticky;
    z-index: 10;
    justify-content: center;
    align-items: center;
    bottom: 0;
    padding: 32px 24px 24px;
    background-color: $pdp-white;
    border-radius: 0 0 6px 6px;
    box-shadow: 0 -1px 5px 2px rgb(0 0 0 / 7%);

    .andes-button__content {
      line-height: normal;
    }
  }

  &__exit-popup-button {
    width: 100%;
  }

  &__div-modal-title {
    width: 100%;
    height: auto;
    padding: 12px 20px;
    background: $pdp-white;
    max-height: $max-height-modal;
    border-radius: 6px 0;
  }

  &__title {
    font-size: $font-size-18;
    line-height: 25px;
    font-weight: bold;
    text-align: center;
  }

  // clases sobreescritas de ui-recommendations
  &__div-modal-list-container {
    .#{$ui-recommendations}-list {
      &__items-wrapper--double {
        margin-bottom: 0;

        .#{$ui-recommendations}-card--horizontal {
          padding: 12px 24px;

          .#{$ui-recommendations}-card__image-container {
            width: 110px;
            height: 110px;
          }

          .#{$ui-recommendations}-card__image {
            width: 110px;
            height: 110px;
          }

          .#{$ui-recommendations}-card__installments {
            &-middle-text {
              white-space: normal !important;
            }

            .andes-money-amount {
              font-size: $font-size-14 !important;
            }

            .andes-money-amount__currency-symbol {
              padding: 0 0.2em;
            }

            .#{$ui-recommendations}-card__installments-quantity {
              padding-right: 0.2em;
            }
          }
        }
      }
    }

    .#{$ui-recommendations}-list__header {
      display: none;
    }
  }

  // clases sobreescritas de andes-modal
  &__bg-modal-exit-popup {
    min-height: 500px !important;

    .#{$andes-modal-name}__scroll {
      .#{$andes-modal-name}__header {
        display: none;
      }

      .#{$andes-modal-name}__header--sticky {
        display: none;
      }
    }
  }
}
