@import './gift-registry';

.#{$vpp-prefix}-gift-registry {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;

  &.#{$vpp-prefix}-gift-registry--desktop {
    display: block;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-top: 32px;

    .andes-checkbox.#{$vpp-prefix}-gift-registry__checkbox {
      width: fit-content;
    }

    .#{$vpp-prefix}-gift-registry__checkbox-container {
      width: 90%;
      display: flex;
      justify-content: end;

      .#{$vpp-prefix}-gift-registry__list {
        text-align: left;
        width: auto;
        flex-grow: 1;

        .#{$vpp-prefix}-gift-registry__label {
          margin-left: $andes-spacing-12;
          margin-right: $andes-spacing-16;
          width: auto;
          text-align: left;
          word-break: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .#{$vpp-prefix}-gift-registry__container {
      cursor: pointer;
      background-color: inherit;
      border-radius: $andes-spacing-8 $andes-spacing-8 0 0;
      border: none;
      border: 1px solid $andes-gray-100;
      border-right: 1px solid $andes-gray-100;
      border-left: 1px solid $andes-gray-100;
      border-top: 1px solid $andes-gray-100;
      padding: $andes-spacing-16;
      font-size: $font-size-14;
      font-style: normal;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: fill-available;

      .andes-checkbox--label-left {
        display: flex;
        align-items: center;
      }

      .andes-thumbnail.andes-thumbnail--square.andes-thumbnail--32.andes-thumbnail--neutral.#{$vpp-prefix}-gift-registry__icon {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        background: $andes-gray-070-solid;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }
    }

    .#{$vpp-prefix}-gift-registry__tooltip {
      width: auto !important;

      :first-child {
        padding-right: 6px;
      }

      button {
        width: 16px;
        height: 16px;

        &::before {
          transform: translate(2px, 1px) rotate(-45deg);
          height: 11px;
        }

        &::after {
          transform: translate(2px, 1px) rotate(45deg);
          height: 11px;
        }
      }
    }
  }
}
