.#{$vpp-prefix}-highlighted-specs__striped-specs {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 40px;
  padding-top: 20px;

  .ui-vpp-striped-specs {
    &__table {
      width: 100%;
      display: inline-block;
      margin-bottom: 32px;

      .andes-table__column--value {
        white-space: initial;
      }
    }
  }
}
