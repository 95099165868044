.#{$pdp-prefix}-list {
  $self: &;

  color: color(color_gray_dark);
  font-weight: $font-weight-regular;

  &__item {
    font-size: $font-size-12;
    padding-bottom: $andes-spacing-12;
  }

  &__item__label {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;

    &::after {
      content: ':\00a0';
    }
  }

  &__text {
    font-size: $font-size-14;
    display: inline;
  }

  &--floating {
    padding-inline-start: 0;

    #{$self}__item {
      width: 50%;
      display: inline-block;
      padding-bottom: $andes-spacing-32;
    }

    #{$self}__item__label {
      color: color(color_gray_middle);
      font-size: $font-size-14;
      padding-bottom: $andes-spacing-4;

      &::after {
        content: none;
      }
    }

    #{$self}__text {
      font-weight: $font-weight-regular;
      display: block;
    }
  }
}
