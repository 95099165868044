$waper-bgcolor: color(color_black_04);
$date-color: color(color_black_45);

.#{$pdp-prefix}-qadb__questions-list {
  margin-top: 40px;

  &.#{$pdp-prefix}-iframe {
    margin-top: 0;
  }

  &__answer-item__answer {
    word-break: break-word;
  }

  &__answer-item__with-tapeables-links > a {
    word-break: break-all;
  }

  &__question-item__separate {
    color: $date-color;
    margin-left: 8px;
    font-size: 14px;
  }

  &__wraper-owner {
    background-color: $waper-bgcolor;
    border-radius: 6px;
    padding: 16px;
    margin-top: 25px;

    .ui-pdp-qadb__questions-list {
      &__question {
        &:first-child {
          margin-top: 0;
        }
      }

      &__see-more__modal {
        margin-bottom: 0;
      }
    }
  }

  &__answer-item__denounce,
  &__question-item__denounce {
    opacity: 0;
    margin-left: 8px;
  }

  &__question__answer-container:hover,
  &__question__container:hover {
    .#{$pdp-prefix}-qadb__questions-list__answer-item__denounce,
    .#{$pdp-prefix}-qadb__questions-list__question-item__denounce {
      opacity: 1;
    }
  }

  &__container-answer__isNoCollapsed {
    width: 100%;
    display: inline;
  }

  &__question {
    p {
      word-break: break-word;
    }

    &__container {
      word-break: break-word;
    }
  }

  &__see-more__modal {
    padding: 24px 0;

    &-content {
      .andes-modal__content {
        padding: 0;
        margin: 0;
      }
    }
  }

  &__question__answer-container {
    margin-top: $andes-spacing-8;
    margin-left: 12px;
    display: flex;
    align-items: flex-start;

    &__icon {
      margin-right: 8px;
      overflow: visible;
    }

    &__answer {
      width: 100%;
    }

    &__see-more {
      margin-left: $andes-spacing-8;
    }
  }

  &__see-more {
    display: flex;
    width: fit-content;
    margin-top: 10px;
    padding-left: 0;
    align-items: center;

    &:hover {
      background-color: transparent;
      cursor: pointer;
    }
  }

  &__see-more & {
    &__see-more__link {
      margin-right: 10px;
    }

    &__chevron {
      margin-top: 3px;
    }
  }

  &__see-more:hover & {
    cursor: pointer;

    &__see-more__link {
      color: $andes-blue-500;
    }

    &__chevron {
      stroke: $andes-blue-500;
    }
  }
}
