@import '../../../styles/variables';
@import '~apparel-sizechart-web-lib/src/styles/desktop';

.#{$vpp-prefix}-apparel-size-specs-preview {
  width: 100%;

  &__title {
    font-size: $font-size-24;
    font-weight: $font-weight-regular;
    padding-bottom: $andes-spacing-24;
  }

  &__table {
    position: relative;

    .sizechart::after {
      content: '';
      inset: 0;
      position: absolute;
      background-image: linear-gradient(
        to bottom,
        color(color_black_1000),
        color(color_gray_500) 60%,
        color(color_white) 100%
      );
      z-index: 1;
    }

    .sizechart {
      width: 100%;
      overflow-x: auto;
    }
  }
}
