@import './trade-in';

.ui-pdp-trade-in-modal {
  overflow: hidden;
}

.ui-pdp-trade-in-modal__quote {
  width: 840px;
}

.ui-pdp-trade-in-modal__how-works {
  width: 800px;
  overflow: hidden;

  .andes-modal__actions {
    padding: $andes-spacing-16 $andes-spacing-48 $andes-spacing-48;
  }

  .andes-modal__actions.padding {
    .ui-pdp-trade-in-modal__actions {
      padding-bottom: $andes-spacing-16;
    }
  }

  .andes-modal__actions.andes-modal__actions--border {
    padding-bottom: $andes-spacing-16;
  }
}

.ui-pdp-remove-trade-in {
  &__container {
    margin-bottom: 35px;
  }

  .andes-modal__scroll {
    padding: $andes-spacing-32;
  }
}
