.ui-vpp-coupons {
  &__pills-container {
    margin-top: $andes-spacing-8;
  }

  &__title {
    height: $andes-spacing-20;
    margin-bottom: 0;
  }

  &__pill {
    display: inline-flex;
    align-items: center;
    width: max-content;
    padding: 4px $andes-spacing-8;
    border-radius: 2px;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    line-height: 16px;
    height: 28px;

    .andes-money-amount {
      font-weight: $font-weight-semibold;
      margin-right: 4px;
    }

    .ui-pdp-icon--coupon {
      margin-right: 4px;
    }
  }

  .ui-pdp-price__part {
    display: initial;
  }

  &__text {
    margin-top: $andes-spacing-4;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    line-height: 18px;
    margin-bottom: $andes-spacing-8;
  }
}
