.ui-pdp-reputation__seller {
  margin: $andes-spacing-24 0;

  .ui-pdp-reputation-name {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ui-pdp-reputation-main-info-seller {
    font-weight: $font-weight-regular;

    div:nth-child(3) {
      display: block;
    }
  }

  .ui-pdp-reputation-seller {
    display: flex;
    flex: 1;
    white-space: nowrap;

    .ui-pdp-reputation-title-link {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: $andes-spacing-4;
      margin-right: $andes-spacing-8;

      .reputation-span {
        color: $andes-black;
      }

      .repupation-span-seller-name {
        white-space: pre-wrap;
      }
    }

    .non-margin-left {
      margin-left: 0;
    }
  }

  figure {
    margin: 0;
  }

  .ui-pdp-color--green {
    color: $andes-green-500;
  }

  .ui-pdp-reputation-seller-info {
    display: flex;
    flex-direction: row;
  }

  .ui-pdp-reputation-mercado-lider {
    flex-direction: row;
    display: inline-block;

    figure {
      margin: 0;
      display: inline-block;
      vertical-align: sub;
    }

    .ui-pdp-reputation__title {
      vertical-align: middle;
    }
  }

  .ui-pdp-seller-logo-reputation {
    margin-right: $andes-spacing-12;
    width: 40px;
    height: 40px;
    position: relative;
    border: 0;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background: $andes-gray-040;
      border-radius: 4px;
    }
  }

  .ui-pdp-icon--medal {
    width: 14px;
    height: $andes-spacing-16;
    margin-right: 2px;

    img {
      width: 14px;
      height: 14px;
      margin-top: 3px;
    }
  }
}
