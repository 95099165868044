.ui-pdp-installation {
  width: 100%;

  &__actions {
    display: flex;
    gap: 6px;
    padding-top: $andes-spacing-16;
  }

  &__price {
    font-size: $font-size-22;
    line-height: $andes-spacing-24;
  }

  &__elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .andes-card__content {
    display: grid;

    .ui-pdp-installation__text {
      line-height: 18px;
    }
  }

  .andes-card__header {
    padding-top: $andes-spacing-12;
    padding-bottom: $andes-spacing-12;
  }

  &-title__icon-title &__icon {
    .ui-pdp-media__body {
      align-self: center;
    }

    .ui-pdp-media__figure {
      width: 20px;
      height: 20px;
      margin: 0 6px 0 0;
    }
  }
}

.ui-installations-title__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cx-widget {
  .cx-widget__actions {
    .button-minimize {
      display: none;
    }
  }

  &:focus:not(:focus-visible) {
    .cx-widget__actions {
      .button-minimize {
        display: none;
      }
    }
  }
}
