.#{$pdp-prefix}-description {
  position: relative;

  .#{$pdp-prefix}-collapsable--is-collapsed {
    .#{$pdp-prefix}-collapsable__container::after {
      background: linear-gradient(180deg, color(color_black_01) 0, color(color_white_10) 100%);
      content: '';
      display: block;
      height: 48px;
      position: absolute;
      z-index: 5;
    }
  }
}
