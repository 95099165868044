@use 'sass:math';
@import '../../../styles/variables';

.#{$pdp-prefix}-additional-info {
  width: 100%;

  .#{$pdp-prefix}-media__title,
  .#{$pdp-prefix}-media__text,
  .#{$pdp-prefix}-media__action {
    font-size: $font-size-12;
  }

  .#{$pdp-prefix}-media__title {
    color: rgba($andes-black, 0.8);
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
  }

  .#{$pdp-prefix}-media__text {
    color: color(color_gray_middle);
    line-height: $line-height-s;
    margin-bottom: $andes-spacing-16;
  }

  .#{$pdp-prefix}-media__action {
    line-height: 1.25;
  }

  .#{$pdp-prefix}-media__figure {
    margin-right: 8px;

    svg {
      height: initial;
      width: initial;
    }
  }

  &__link {
    font-size: $font-size-12;
    padding-left: 42px;

    &.cho-guest {
      margin: -$andes-spacing-20 0;
    }
  }

  .#{$pdp-prefix}-media__body {
    display: grid;
    grid-template-columns: 46px 2fr;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .#{$pdp-prefix}-media__title {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .#{$pdp-prefix}-media__text {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .#{$pdp-prefix}-image {
    display: block;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    width: 36px;
  }
}

.#{$pdp-prefix}-actions__container--featured {
  box-shadow: $shadow-elevated;
  padding: 14px;
  border-radius: 6px;

  .#{$pdp-prefix}-media__title {
    margin-bottom: 0;
  }

  .#{$pdp-prefix}-media__body {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: $andes-spacing-16;
  }

  .#{$pdp-prefix}-image {
    margin-right: $andes-spacing-8;
  }
}
