.#{$pdp-prefix-reviews}__comments__review-comment {
  &__rating {
    margin-bottom: $andes-spacing-8;
  }

  &__rating__star {
    margin-right: 8.2px;
  }

  &__title {
    color: color(color_gray_dark);
    font-size: $font-size-16;
    line-height: $line-height-xs;
    font-weight: $font-weight-semibold;
  }

  &__version {
    color: color(color_gray_middle);
    font-size: $font-size-14;
    line-height: $line-height-sm;
    margin-top: $andes-spacing-8;
  }

  &__comment {
    color: color(color_gray_dark);
    font-size: $font-size-16;
    font-weight: $font-weight-regular;
    line-height: $line-height-s;
    margin-top: $andes-spacing-8;
  }

  &__likes {
    margin-top: $spacing-14;
    color: color(color_gray_dark);
  }

  &__likes__like {
    margin-right: $spacing-6;
    vertical-align: middle;
  }

  &__likes__like:hover {
    cursor: pointer;
  }

  &__likes__dislike {
    margin-left: $spacing-25;
    margin-right: $spacing-6;
    vertical-align: top;
    margin-top: 2px;
  }

  &__likes__dislike:hover {
    cursor: pointer;
  }
}
