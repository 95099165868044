.#{$pdp-prefix}-qadb__search-bar {
  display: block;
  position: relative;
  width: 100%;

  &__input-container {
    position: relative;
    display: block;
  }

  &__input {
    padding: 0;

    &.andes-form-control {
      padding-top: 0;
    }

    .andes-form-control--textfield {
      padding-top: 0;
    }

    .andes-form-control__field {
      margin-bottom: 0;
      margin-top: 0;
      padding-left: $andes-spacing-16;
      text-indent: 0;
    }

    .andes-form-control__field::placeholder {
      padding-left: 0;
      color: $andes-gray-250;
    }

    .andes-form-control__message {
      display: none;
    }
  }

  &__search {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 48px;
    background-color: $andes-blue-500;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: 0;
    box-sizing: border-box;
    display: block;
    text-align: center;
    padding: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 0;

    &:hover {
      cursor: pointer;
      background-color: $andes-blue-600;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background: $andes-gray-100;
      color: $andes-text-color-disabled;
    }
  }

  &__clear-search {
    position: absolute;
    right: 0;
    top: 17px;
    text-align: center;
    align-self: center;
    display: flex;
    height: fit-content;
  }

  &__clear-search:hover {
    cursor: pointer;
  }

  &__auto-suggest {
    position: absolute;
    width: 100%;
    box-shadow: 0 8px 16px 0 rgba($andes-black, 0.15);
    background-color: $andes-white;
    border-radius: 6px;

    &__row {
      width: 100%;
      background-color: $andes-white;
      padding-left: 16px;
      padding-bottom: 18px;
      padding-top: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px;

      &__icon {
        margin-right: 16px;
      }

      &:focus {
        outline: 0;
      }
    }

    &__row:hover {
      cursor: pointer;
      background-color: color(color_gray_lighter);
    }
  }

  &__tooltip {
    padding: 16px 26px 16px 16px;

    .andes-tooltip__text {
      font-weight: $font-weight-light;
      line-height: 1.3;
    }

    .andes-tooltip-arrow::before {
      top: -6px;
    }

    .andes-tooltip-button-close {
      right: 15px;
      top: 13px;
      width: 16px;
      height: 16px;

      &::before,
      &::after {
        top: 0;
        opacity: 1;
      }
    }
  }
}
