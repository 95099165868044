// sass-lint:disable bem-depth
.ui-qadb__make-question-sa {
  &__label {
    margin-top: $andes-spacing-28;
  }

  &__form {
    display: flex;
  }

  &__content {
    display: inline-block;
    width: 100%;

    .andes-form-control.ui-qadb__make-question-sa__input {
      padding-top: 0;
    }
  }
}
