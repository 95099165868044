.#{$pdp-prefix}-buybox__quantity {
  &__input {
    padding: 24px 12px 12px;
    position: relative;

    &__custom-options {
      padding: $andes-spacing-16 0 $andes-spacing-20;
      width: 100%;

      .ui-pdp-buybox__quantity__button {
        margin: 0;
        width: 68px;
        height: 34px;
        font-family: $font-family-primary;
        font-size: $font-size-14;
        font-weight: $font-weight-semibold;
        line-height: $line-height-xs;
        text-align: center;
      }

      .andes-button.ui-pdp-buybox__quantity__button {
        margin: 7px;

        &-bulk-custom-options {
          margin: $andes-spacing-16 0 0 0;
          height: 48px;
          font-size: $font-size-16;
          width: 100%;
        }
      }

      .andes-form-control--textfield .andes-form-control__field {
        padding-left: 6px;
        padding-right: 0;
      }

      .andes-form-control__field::placeholder {
        padding-left: $andes-spacing-4;
      }

      .andes-form-control--textfield .andes-form-control__control {
        min-height: 0;
        height: 46px;
      }

      .andes-form-control {
        width: 100%;
      }

      .andes-button__content {
        top: 0;
        left: 1px;
      }

      .andes-money-amount__suffix {
        color: $andes-text-color-primary;
        font-size: $font-size-12;
        margin-left: 0;
      }
    }

    &-textfield {
      .andes-form-control__field {
        width: 100%;
      }
    }

    .andes-form-control {
      display: inline-block;
      padding: 0;
    }

    .andes-button {
      margin: 8px;
    }

    // [START] remove when fixed form andes -- andes list svg path overlaps with andes textfieldqq
    /* stylelint-disable-next-line selector-class-pattern */
    .andes-form-control---error-icon path {
      fill: $andes-white;

      &:first-child {
        fill: $andes-red-500;
      }
    }

    // [END] remove when fixed form andes -- andes list svg path overlaps with andes textfield
  }

  &__stock {
    font-size: 12px;
    color: $andes-gray-250;
  }

  &__disclaimer {
    margin-top: 8px;
  }
}
