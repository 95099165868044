@import './gradings';

$degrees: 90deg;

.#{$gradings-prefix} {
  &-wrapper {
    margin: 0;

    a {
      color: $andes-link-state-link;
      font-size: $font-size-12;
      line-height: $line-height-m;

      &:hover {
        color: $andes-link-state-hover;
      }

      &:visited {
        color: $andes-link-state-link;
      }
    }
  }

  &-modal {
    width: 600px;

    .andes-modal__scroll {
      overflow: unset;

      .andes-modal__header {
        display: none;
      }

      .andes-modal__content {
        height: 100%;
        padding: 0;
      }
    }

    &__button {
      background-color: transparent;
      border: 0;
      color: $andes-blue-500;
      padding: 0;
      cursor: pointer;

      &:hover {
        color: $andes-link-state-hover;
      }

      &:visited {
        color: $andes-link-state-link;
      }

      svg {
        margin-left: $andes-spacing-4;
      }
    }

    &__icon {
      width: $andes-spacing-12;
      height: $andes-spacing-12;
    }
  }
}
