@import '../../../../styles/variables';

.#{$pdp-prefix}-shipping {
  position: relative;

  &__spinner {
    position: absolute;
    top: 60%;
    left: 46%;
    transform: translate(-50%, -50%);
  }

  &__info-tooltip {
    &__ctas-container {
      display: flex;
    }

    &__text {
      display: block;
      margin-bottom: 14px;
      line-height: 1.3em;
    }

    &__button {
      padding: 6px 12px;
    }

    &__link {
      display: flex;
      margin-left: 24px;
    }

    &__link-anchor {
      align-self: center;
    }
  }

  &__message {
    &__text {
      padding-right: em(16, 14);
    }
  }
}

.andes-tooltip__trigger {
  position: relative;
}

.#{$pdp-prefix}-shipping-disclaimer {
  margin-bottom: $andes-spacing-20;

  .#{$pdp-prefix}-card-tooltip__card--arrow-top-left::before {
    left: 8%;
  }
}
