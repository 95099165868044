.#{$pdp-prefix}-container__comparator {
  .andes-tab__link {
    font-size: $font-size-18;
  }
}

.#{$pdp-prefix}-container {
  &__col.col-1.pl-24.pr-24 {
    .ui-recommendations-comparator__icon {
      fill: transparent;
      stroke: $andes-blue-500;
    }

    .ui-recommendations-comparator__wrapper-ref:not(:empty)::after {
      padding-bottom: 7px;
      content: '';
      display: block;
      margin: 40px 0;
      border-bottom: 1px solid $andes-gray-100;
      height: 1px;
      width: 100%;
    }
  }
}
