@import '../../../styles/mixins';

.#{$pdp-prefix}-review {
  display: inline-block;

  &__rating {
    color: $andes-text-color-secondary;
    font-weight: $font-weight-regular;
  }

  &__amount {
    color: $andes-text-color-secondary;
    font-weight: $font-weight-regular;
  }

  &__ratings {
    display: flex;

    .#{$pdp-prefix}-icon {
      margin-right: 3px;
    }
  }

  &__label {
    display: flex;
    align-items: center;

    &--link:hover {
      cursor: pointer;
    }
  }
}
