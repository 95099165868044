@import './header-commons';

.#{$pdp-prefix}__header-top-brand {
  @include store-header;

  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.#{$pdp-prefix}-container__row--header-top-brand {
  max-height: 24px;
  margin: -9px 0 17px;
}

.#{$vpp-prefix}-header-top-brand {
  &__bg-image {
    position: relative;
  }

  &__bg-image,
  &__image-wrapper {
    height: 250px;
    width: 100%;
  }

  &__image-wrapper {
    pointer-events: none;
    position: absolute;
    z-index: -1;

    img {
      object-fit: cover;
      object-position: left top;
      width: 100%;
      height: 100%;
    }
  }

  &__logo-container {
    max-width: $pdp-layout-width;
    margin: 0 auto;
    position: relative;
    transform: translateY(50%);
    display: flex;
  }

  &__logo-image-wrapper {
    border-radius: 4px;
    position: absolute;
    width: 96px;
    height: 70px;
    background-color: $andes-white;
  }

  &__logo-image {
    width: auto;
    max-width: 96px;
    height: auto;
    max-height: 70px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 4px;
  }

  &-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $andes-spacing-16;
    width: 225px;
    height: 72px;
    background: $andes-white;
    border-radius: 6px;

    &__title-container {
      display: flex;
      flex-direction: column;
    }

    &__title-and-icon {
      text-wrap: balance;

      .ui-pdp-cockade-icon {
        margin-left: 2px;
        margin-bottom: -2px;
      }
    }

    &__image-container {
      width: 40px;
      height: 40px;
      position: relative;
      display: flex;
      align-items: center;
      padding: 2px;
      margin-right: $andes-spacing-12;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: color(color_gray_lighter);
        border-radius: 4px;
        left: 0;
      }

      &__image {
        border-radius: 4px;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
}
