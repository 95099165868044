.ui-vpp-questions-ai-form {
  &__andes-form {
    display: flex;
    gap: $andes-spacing-12;
  }

  &__content {
    flex: 1;
    display: flex;
    position: relative;
  }

  &__input {
    width: 100%;

    .andes-form-control__field {
      padding-right: 42px;
    }
  }

  &__clear {
    position: absolute;
    right: $andes-spacing-16;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    svg {
      fill: color(color_blue);
      fill-opacity: 1;
      width: $andes-spacing-12;
      height: $andes-spacing-12;
    }
  }

  &__actions {
    width: 185px;
  }

  &__actions-span-progress {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__actions-button {
    .andes-button__content {
      .andes-button__text {
        margin-left: 0;
      }

      .ui-pdp-action-icon {
        &--BLUE {
          fill: color(color_blue);
        }

        &--WHITE {
          fill: color(color_white);
        }
      }
    }
  }
}
