.#{$pdp-prefix-reviews}__actions {
  &__container {
    display: flex;
  }

  &__divider {
    color: $andes-gray-250;
    font-weight: bold;
    margin: 0 $andes-spacing-12;
  }
}
