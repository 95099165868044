@import './hs-highlighted-attributes';

$highlighted-attributes-border: #e5e5e5;

.#{$vpp-prefix}-highlighted-specs__highlighted-attributes {
  &__card {
    box-shadow: 0 1px 2px 0 $andes-gray-100;
    border: solid 1px $highlighted-attributes-border;
    position: relative;

    &:not(:first-of-type) {
      margin-left: 8px;
    }
  }

  .andes-card__content {
    padding: 12px 16px;
  }

  &__card-labels {
    margin-left: 12px;
  }

  &__card-content {
    padding-left: 16px;
    padding-right: 16px;
  }
}
