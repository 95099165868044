.#{$vpp-prefix}-highlighted-specs__discrete-bar {
  width: 100%;
  display: flex;
  align-items: flex-start;

  &__attribute {
    width: 100%;
    margin-left: 16px;
  }

  &__title {
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
  }

  &__discrete {
    margin-top: 8px;
  }
}
