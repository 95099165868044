.#{$vpp-prefix}-store {
  &__bg-image {
    position: relative;
  }

  &__bg-image,
  &__image-wrapper {
    height: 250px;
    width: 100%;
  }

  &__image-wrapper {
    pointer-events: none;
    position: absolute;

    img {
      object-fit: cover;
      object-position: left top;
      width: 100%;
      height: 100%;
    }
  }

  &__logo-container {
    max-width: $pdp-layout-width;
    margin: 0 auto;
    position: relative;
  }

  &__logo-image-wrapper {
    border-radius: 4px;
    position: absolute;
    width: 96px;
    height: 70px;
    transform: translateY(50%);
    background-color: $andes-white;
  }

  &__logo-image {
    width: auto;
    max-width: 96px;
    height: auto;
    max-height: 70px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 4px;
  }
}
