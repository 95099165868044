.#{$pdp-prefix}-qadb__question-form {
  &__form {
    &__input {
      padding-top: 0;

      .andes-form-control__message {
        display: none;
      }

      .andes-form-control__control {
        textarea {
          padding: 16px;
          margin: 0;
          text-indent: 0;
        }
      }
    }

    &__actions {
      // @TODO Andes Revisar esto, tal vez la clase deberia ser .andes-button
      .andes-button__content {
        font-size: $font-size-16;
        font-weight: $font-weight-semibold; // revisar si pisa valores por defecto
      }
    }
  }
}
