@import '../../../styles/variables';
@import './lightbox';

.pswp__counter {
  border-radius: 12px;
  background-color: $andes-gray-250;
  font-size: $font-size-12;
  height: 24px;
  line-height: 24px;
  left: 46px;
  min-width: 42px;
  top: 46px;
}

// sass-lint:disable max-line-length
.pswp__button--close {
  background-color: $andes-gray-250;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIyMyIgdmlld0JveD0iMCAwIDMwIDIzIj4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxwYXRoIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTE1IDEwLjk0bDUuNDctNS40NyAxLjA2IDEuMDZMMTYuMDYgMTJsNS40NyA1LjQ3LTEuMDYgMS4wNkwxNSAxMy4wNmwtNS40NyA1LjQ3LTEuMDYtMS4wNkwxMy45NCAxMiA4LjQ3IDYuNTNsMS4wNi0xLjA2TDE1IDEwLjk0eiIvPiAgICA8L2c+PC9zdmc+') !important;
  background-position: center;
  background-size: 44px;
  top: 41px;
  right: 45px;
  width: 32px;
  height: 32px;
}

.pswp__button--arrow--left::before,
.pswp__button--arrow--right::before {
  opacity: 0.75;
  background-color: $andes-gray-250;
}

.pswp--svg {
  .pswp__button--arrow--left::before,
  .pswp__button--arrow--right::before {
    width: 50px;
    height: 50px;
    background-size: 50px;
    background-position: center;
  }

  .pswp__button--arrow--left {
    left: 41px;

    &::before {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBjbGFzcz0idWktaWNvbiI+Cgk8ZyBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgoJCTxwYXRoIGQ9Ik0xMC42NDYgNC42NDZsLTQgNEw2LjI5MyA5IDcgOS43MDdsLjM1NC0uMzUzIDQtNEwxMS43MDcgNSAxMSA0LjI5M3oiPjwvcGF0aD4KCQk8cGF0aCBkPSJNMTEuMzU0IDEyLjY0NmwtNC00TDcgOC4yOTMgNi4yOTMgOWwuMzUzLjM1NCA0IDQgLjM1NC4zNTMuNzA3LS43MDd6Ij48L3BhdGg+Cgk8L2c+Cjwvc3ZnPg==');
    }
  }

  .pswp__button--arrow--right {
    right: 41px;

    &::before {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBjbGFzcz0idWktaWNvbiB1aS1pY29uLS1jaGV2cm9uLXJpZ2h0Ij4KCTxnIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CgkJPHBhdGggZD0iTTYuNjQ2IDUuMzU0bDQgNCAuMzU0LjM1My43MDctLjcwNy0uMzUzLS4zNTQtNC00TDcgNC4yOTMgNi4yOTMgNXoiPjwvcGF0aD4KCQk8cGF0aCBkPSJNNy4zNTQgMTMuMzU0bDQtNEwxMS43MDcgOSAxMSA4LjI5M2wtLjM1NC4zNTMtNCA0LS4zNTMuMzU0LjcwNy43MDd6Ij48L3BhdGg+Cgk8L2c+Cjwvc3ZnPg==');
    }
  }

  .pswp__container {
    iframe {
      position: absolute;
      margin: 0 auto;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
      right: 0;
    }
  }
}
