@import './wrapper-eshop';

@media (min-width: $pdp-layout-width) {
  .wrapper-sticky {
    margin-left: calc(($pdp-layout-width - 100vw) / 2);
    width: 100vw;
  }

  .ui-pdp-extended .wrapper-sticky {
    margin-left: unset;
    width: 100%;
  }

  .ui-ms-profile__item svg {
    box-sizing: content-box;
  }
}
