.#{$pdp-prefix}-syi {
  align-items: center;
  display: flex;
  font-size: $font-size-14;
  line-height: 1;
  position: relative;

  &__link {
    color: $andes-blue-500;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    line-height: $line-height-xs;
    display: inline-block;
    vertical-align: middle;

    &:hover {
      cursor: pointer;
    }
  }
}

// agregamos el separador cuando viene el bloque de editar
.#{$pdp-prefix}-eyi + .#{$pdp-prefix}-syi::before {
  content: '';
  display: inline-block;
  width: 0.5px;
  height: 14px;
  background-color: $pdp-gray-200;
  margin: 0 10px;
  vertical-align: middle;
}
