// sass-lint:disable no-vendor-prefixes

@import '../../../styles/mixins';
@import '../../../styles/variables';

$ui-pdp-product-tags: 'ui-pdp-product-tags';

.#{$ui-pdp-product-tags} {
  display: flex;
  width: 100%;

  &__button {
    display: flex;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;

    // inherit font & color from ancestor
    color: inherit;
    font: inherit;

    // Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+.
    line-height: normal;

    // Corrects font smoothing for webkit
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    // Corrects inability to style clickable `input` types in iOS
    appearance: none;

    &:focus {
      outline: 0;
    }
  }

  &__tag {
    cursor: pointer;
    text-transform: uppercase;

    .andes-badge__content {
      color: inherit;
    }
  }
}
