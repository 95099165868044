.andes-modal__overlay--large {
  .#{$pdp-prefix}-question-modal__modal {
    min-height: 345px;
    width: 100%;

    &.modal-fixed-height {
      min-height: 315px;
      height: auto;
    }

    .andes-modal__header {
      padding: $andes-spacing-32 $andes-spacing-32 0;
    }

    .andes-modal__content {
      padding: 0 $andes-spacing-32;
    }

    .andes-modal__close-button {
      margin: 0;
    }

    .andes-modal-title {
      font-size: $font-size-24;
      line-height: $line-height-s;
      color: color(color_gray_dark);
    }

    .ui-pdp-question-modal__subtitle {
      font-size: $font-size-16;
      line-height: $line-height-s;
      color: color(color_gray_dark);
      margin: $andes-spacing-12 0 $andes-spacing-28;
    }

    .andes-form-control--textfield.andes-form-control--multiline .andes-form-control__field {
      min-height: 125px;
      max-height: 125px;
    }
  }

  .#{$pdp-prefix}-qadb__question-form__form__actions {
    margin-bottom: 0;

    &__button {
      padding: 14px $andes-spacing-24;
    }
  }
}
