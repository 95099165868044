// sass-lint:disable bem-depth
.#{$pdp-prefix}-qadb__make-question {
  &__label {
    margin-top: $andes-spacing-28;
  }

  &__button {
    height: 32px;
    padding: 9px 11px;
    margin-top: 17px;

    // @TODO Andes Revisar esto, tal vez la clase deberia ser .andes-button
    .andes-button__content {
      font-size: $font-size-14;
    }
  }

  &__modal {
    height: 312px;
    width: 100%;

    .andes-modal__header {
      padding: 25px 32px;

      &-title {
        font-size: $font-size-24;
        font-weight: $font-weight-semibold;
        color: color(color_gray_dark);
      }
    }

    .andes-modal__content {
      padding-bottom: 32px;
      padding-left: 32px;
      padding-right: 32px;
    }

    // TODO: Esto no pasa el lint, no cumple el formato BEM
    .ui-pdp-qadb__question-form__form__actions__button {
      padding: 14px $andes-spacing-24;
    }

    .andes-form-control--textfield.andes-form-control--multiline .andes-form-control__field {
      min-height: 125px;
      max-height: 125px;
    }
  }
}
