.#{$pdp-prefix}-specs-groups-collapsable {
  color: color(color_gray_dark);

  &__subtitle {
    padding-top: $andes-spacing-20;
    padding-bottom: $andes-spacing-16;
  }

  &__list {
    padding-bottom: 0;
  }

  &__action {
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }
}

// TODO: AB para PDP cuando no viene descripción. Borrarlo cuando no se este usando mas.
.ui-pdp-specs-groups-collapsable--is-collapsed-only-pdp {
  max-height: 494px;
  overflow: hidden;
}

.#{$pdp-prefix}-specs {
  $self: &;

  width: 100%;

  &__action {
    align-items: center;
    color: $andes-blue-500;
    display: inline-flex;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    padding-top: $andes-spacing-8;
    padding-right: $andes-spacing-16;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__chevron {
    margin-left: 6px;
    margin-top: $andes-spacing-4;
  }
}
