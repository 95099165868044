@import '../../../styles/variables';

.#{$pdp-prefix} {
  &-header__info {
    display: flex;
    align-items: center;

    .ui-pdp-review__ratings {
      .ui-pdp-icon.ui-pdp-icon--star-empty {
        fill: transparent;
        stroke: $andes-blue-500;
      }

      .ui-pdp-icon.ui-pdp-icon--star-full {
        fill: transparent;
        stroke: $andes-blue-500;
      }
    }

    & > div,
    & > span {
      margin-left: 22px;

      &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 12px;
        background-color: $border-grey;
        margin-left: -11px;
        margin-top: 4px;
      }
    }

    & > :first-child {
      margin-left: 0;

      &::before {
        display: none;
      }
    }
  }

  &-header__tag {
    margin-right: 8px;
    font-size: $font-size-14;

    + .#{$pdp-prefix}-header__subtitle {
      display: inline-block;

      .#{$pdp-prefix}-subtitle {
        vertical-align: 6px;
      }
    }
  }
}
