.ui-seller-data-banner {
  &__container {
    height: 93px;
    margin-bottom: 10px;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 84px;
    object-fit: cover;
    border-radius: 6px;
  }

  &__logo-container {
    position: relative;
    top: -47px;
    left: $andes-spacing-8;
    width: fit-content;
  }
}
