@import '../../../../styles/variables';

$pdp-quantity-selector-width: 200px;
$pdp-quantity-selector-width-pdp: 278px;
$pdp-quantity-selector-width-vip: 320px;
$pdp-quantity-selector-width-with-options: 265px;
$quantity-selector-width-options-bulk-sale: 245px;
$pdp-quantity-selector-radius: 0.25em;

.#{$pdp-prefix}-quantity-selector {
  border: none;
  box-shadow: 0 1px 2px 0 $pdp-gray-300;
  padding: 0;
  width: 100%;

  &__list {
    &--default {
      width: 100%;
    }

    &--default-bulk {
      width: $quantity-selector-width-options-bulk-sale;
    }

    &--pdp {
      width: $pdp-quantity-selector-width-pdp;
    }

    &--vip-core {
      width: $pdp-quantity-selector-width-vip;
    }

    &-custom-options {
      .andes-list--selectable {
        li {
          &.andes-list__item {
            border-bottom: 1px solid $andes-gray-070-solid;
          }

          &:last-of-type {
            border-bottom: 0;
          }
        }
      }

      .andes-money-amount {
        .andes-money-amount__suffix {
          color: $andes-text-color-primary;
          font-size: $font-size-12;
          margin-left: 0;
        }
      }
    }
  }

  .andes-tooltip-data__arrow {
    display: none;
  }

  &--volume {
    transform: translateX(-3px);
  }

  &--options-bulk {
    width: $quantity-selector-width-options-bulk-sale;

    .andes-list--dropdown {
      max-height: 310px;
      overflow-y: auto;
    }
  }

  &--options {
    width: $pdp-quantity-selector-width-with-options;

    .andes-list--dropdown {
      max-height: 310px;
      overflow-y: auto;
    }
  }

  &__options-custom-options {
    margin-top: 4px;
    width: 100%;
    overflow: hidden;
  }

  .andes-tooltip-button-close,
  .andes-tooltip-arrow {
    display: none;
  }

  .andes-list__item {
    align-items: center;
    justify-content: center;

    + .andes-list__item {
      border: none;
    }

    &.andes-list__item--selected {
      .andes-list__item-primary {
        font-weight: $font-weight-semibold;
        color: $andes-blue-500;
      }
    }

    &:first-child {
      border-top-left-radius: $pdp-quantity-selector-radius;
      border-top-right-radius: $pdp-quantity-selector-radius;
    }

    &:last-child {
      border-bottom-left-radius: $pdp-quantity-selector-radius;
      border-bottom-right-radius: $pdp-quantity-selector-radius;
      height: auto;
    }
  }
}
