$loyalty-fill-color: #919191;
$andes-help-icon-stroke: #3483fa;

.#{$pdp-prefix}-icon {
  $self: &;

  fill: $andes-gray-250;

  &--MELI_USD {
    /* stylelint-disable-next-line color-function-notation */
    fill: rgba(0, 0, 0, 0%);
    margin-right: 4px;
  }

  &--shipping {
    fill: $andes-gray-250;
  }

  &--pin {
    fill: $andes-blue-500;
  }

  &--chevron {
    height: 14px;
    stroke: $andes-blue-500;
    width: 9px;
    vertical-align: middle;
  }

  &--whatsapp {
    path {
      stroke: none !important;
    }
  }

  &.#{$pdp-prefix}-icon--whatsapp {
    path {
      stroke: none !important;
    }
  }

  &--external-credits {
    height: $andes-spacing-20;
    width: $andes-spacing-20;
    margin-right: $andes-spacing-16;
  }

  &--chevron-down {
    transform: rotate(-270deg);
    transition: 0.3s transform ease-in-out;

    &--error {
      stroke: color(color_red_600) !important;
    }
  }

  &--chevron-down--active {
    transform: rotate(-90deg);
    transition: 0.3s transform ease-in-out;
  }

  &--return {
    height: 18px;
    width: 15px;
  }

  &--full {
    fill: color(color_green);
    position: relative;
    top: $andes-spacing-4;
    height: 16px;
    width: 56px;
    margin-top: -1px;
  }

  &--full-super {
    margin-top: -4px;
  }

  &--plus {
    position: relative;
    top: 1px;
  }

  &--express {
    fill: $andes-gray-100;
    width: 48px;
    height: 48px;
  }

  &--icon-package {
    fill: $andes-gray-100;
    width: 48px;
    height: 48px;
  }

  &--seller-agreement {
    margin-top: 1px;
  }

  &--thumb_up {
    margin-left: $andes-spacing-4;
  }

  &--loyalty {
    fill: $loyalty-fill-color;
  }

  &--medal {
    width: 20px;
    height: 20px;
  }

  &--help {
    fill: $andes-blue-500;
    stroke: $andes-help-icon-stroke;
  }

  &--cbt-summary {
    margin-right: $andes-spacing-8;
  }
}

.#{$pdp-prefix}-color--GREEN {
  &.#{$pdp-prefix}-icon {
    fill: color(color_green);
  }
}

.#{$pdp-prefix}-color--BLACK {
  &.#{$pdp-prefix}-icon {
    fill: color(color_gray_dark);
  }
}

.#{$pdp-prefix}-color--BLACK_100 {
  &.#{$pdp-prefix}-icon {
    fill: color(color_black_100);
  }
}

.#{$pdp-prefix}-color--WHITE {
  &.#{$pdp-prefix}-icon {
    fill: color(color_white);
  }
}

.#{$pdp-prefix}-color--BLUE {
  &.#{$pdp-prefix}-icon {
    fill: color(color_blue);
  }
}

.#{$pdp-prefix}-color--BLUE_700 {
  &.#{$pdp-prefix}-icon {
    fill: color(color_blue_700);
  }
}

.#{$pdp-prefix}-color--GRAY {
  &.#{$pdp-prefix}-icon {
    fill: color(color_gray_middle);
  }
}

.#{$pdp-prefix}-color--ORANGE {
  &.#{$pdp-prefix}-icon {
    fill: color(color_orange);
  }
}

.#{$pdp-prefix}-color--ORANGE_MEDIUM {
  &.#{$pdp-prefix}-icon {
    color: color(color_orange_medium);
  }
}

.#{$pdp-prefix}-icon--coupon.#{$pdp-prefix}-color--BLUE {
  &.#{$pdp-prefix}-icon {
    fill: none;
  }
}
