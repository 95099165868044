.#{$pdp-prefix}-generic-summary {
  $self: &;

  &__message {
    &__text {
      padding-right: em(16, 14);
    }
  }

  .price-tag__disabled {
    color: color(color_gray_middle);
  }

  &__action-icon {
    width: 10px;
    height: 14px;
    margin-right: 6px;
  }

  .price-tag-cents {
    line-height: 1.5;
  }

  &--black-price {
    .ui-pdp-price__part {
      color: color(color_gray_dark);

      &__previous {
        color: color(color_gray_middle);
      }
    }
  }

  &--md {
    .#{$pdp-prefix}-media__text {
      line-height: 1.29;

      .ui-pdp-price__part {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        display: inline;

        .price-tag-cents {
          vertical-align: top;
          font-size: 0.7em;
          position: relative;
        }
      }
    }
  }

  .#{$pdp-prefix}-icon--return {
    height: 22px;
    width: 17px;
  }

  &-modal .andes-modal__close-button {
    backface-visibility: hidden;
  }

  &-modal.#{$pdp-prefix}-action-modal__modal--no-close {
    .andes-modal__close-button {
      display: none;
    }
  }

  &-modal .andes-spinner {
    background-color: $andes-white;
  }

  &--first-buy {
    .#{$pdp-prefix}-media__title .#{$pdp-prefix}-color--BLACK.#{$pdp-prefix}-family--REGULAR {
      white-space: nowrap;
    }
  }
}

.#{$pdp-prefix}-payment-sprites {
  display: flex;
  align-items: center;
  margin-top: 4px;

  &__icon {
    margin: 0 0 0 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}
