.ui-vpp-coupons-awareness {
  &__tag {
    display: inline-block;
    vertical-align: middle;
  }

  &__pill {
    display: inline-flex;
    align-items: center;
    padding: $andes-spacing-4 $andes-spacing-8;
    border-radius: 2px;
    font-size: $font-size-14;
    line-height: 16px;
    height: 28px;

    .andes-money-amount {
      font-weight: $font-weight-semibold;
      margin-right: $andes-spacing-4;
    }

    .ui-pdp-icon--coupon {
      margin-right: $andes-spacing-4;
    }
  }

  &__checkbox-label {
    display: inline;
    vertical-align: middle;
  }

  &__checkbox,
  &__coupon-redeemed {
    margin: 0 $andes-spacing-8;
  }

  &__coupon-redeemed-container {
    display: inline-flex;
    align-items: center;
    height: 28px;
    vertical-align: middle;
  }

  .andes-checkbox__input {
    margin: 1px 0;
  }

  .ui-pdp-price__part {
    display: initial;
  }

  &--redemeed {
    margin-bottom: $andes-spacing-8;
  }
}
