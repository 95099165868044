.#{$pdp-prefix}-container__row--compats-feedback {
  &.#{$pdp-prefix}-compats--store {
    margin: -83px auto 53px;
    max-width: 1184px;
    background: $andes-bg-primary;
    padding: 0 $andes-spacing-16;
  }
}

@media (width <= 768px) {
  .#{$pdp-prefix}-container__row--compats-feedback {
    .ui-vpp-menssage {
      margin: 12px;
    }

    .andes-message__text {
      line-height: 18px;
      margin-right: $andes-spacing-12;
    }
  }
}
