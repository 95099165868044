.ui-pdp.ui-pdp--error {
  .andes-technical-error__container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 25em;
  }
}
