[role='main']:has(.ui-pdp > .andes-technical-error__container) {
  display: table-row;
}

.ui-vip-core,
.ui-pdp {
  .andes-technical-error__container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 25em;
  }
}
