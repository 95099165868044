$recos-fe-namespace: 'ui-recommendations';

.#{$recos-fe-namespace}-carousel-snapped {
  &.recos-override__card-vertical {
    .andes-carousel-snapped__control {
      top: 212px;
    }

    .recos-polycard.poly-card--grid-card {
      margin-bottom: 0;
    }
  }

  .poly-component__compats {
    font-size: 12px;
  }
}
