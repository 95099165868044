.#{$pdp-prefix}-qadb__smart-answer-modal {
  &__button {
    .andes-button {
      margin-left: 8px;
    }

    .andes-button__content {
      font-weight: $font-weight-semibold;
    }
  }

  .ok-cta {
    margin-right: 10px;
  }

  &__label {
    color: color(color_gray_dark);

    .ui-pdp-action-modal {
      display: inline;
      font-size: $font-size-16;

      div,
      .andes-tooltip__trigger {
        display: inline;
      }
    }
  }
}

.#{$pdp-prefix}-qadb__smart-answer-modal__modal {
  height: auto;
  width: 500px;

  .ok-cta {
    margin-right: 10px;
  }

  .andes-modal {
    &__header {
      padding: 24px 32px;
      padding-bottom: 0;

      &-title {
        font-size: $font-size-24;
        font-weight: $font-weight-semibold;
        color: color(color_gray_dark);
      }
    }

    &__header ~ .andes-modal__content,
    &__content {
      padding: 24px 60px 0 32px;
    }

    &__button-close {
      top: 16px;
      right: 32px;
    }

    &__container {
      position: relative;
    }

    &__actions {
      padding: 24px 32px;
      position: relative;
    }
  }

  &.andes-modal--large {
    .andes-modal__header {
      padding: 2em;
    }

    .andes-modal__content {
      padding: 0 5em 2em 2em;
    }

    .andes-modal__actions {
      padding: 0.25em 2em 2em;
    }
  }

  &.andes-modal--large.#{$pdp-prefix}-qadb__smart-answer-modal__modal--without-title {
    .andes-modal__header {
      padding: 24px 32px 0;
    }

    .andes-modal__close-button {
      margin: 0;
    }
  }
}
