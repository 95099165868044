.ui-vpp-questions-ai-denounce {
  &__tooltip {
    padding: $andes-spacing-8 $andes-spacing-4;
    color: $andes-text-color-primary;

    .andes-tooltip {
      &__content {
        margin-right: 0;
        margin-left: $andes-spacing-16;
      }

      &__actions {
        margin-top: 0;
      }
    }

    &--button,
    &--button.andes-button {
      width: $andes-spacing-8;
      height: $andes-spacing-16;
      padding: 0;
      border: none;
      border-radius: 50%;
      vertical-align: top;
      outline: none;
      cursor: pointer;

      &::before,
      &::after {
        display: none;
      }

      &:hover,
      &:active {
        background: transparent;
      }

      & > .andes-button__content {
        flex-wrap: wrap;
        height: 100%;
        padding-top: $andes-spacing-4;
        padding-right: 3px;
        vertical-align: top;

        &::after {
          display: none;
        }
      }

      .denounce-dropdown__circle {
        display: block;
        width: 3px;
        height: 3px;
        border-radius: $andes-spacing-20;
        background-color: $andes-gray-900;
        float: none;

        &:not(&:first-child) {
          margin-top: 2px;
        }
      }
    }
  }

  &-content {
    &__item-menu {
      display: flex;
      align-items: center;
      padding: 0;
      gap: $andes-spacing-12;
      color: $andes-gray-900;
      font-size: $font-size-16;
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:hover {
        color: $andes-black;
      }

      &--denounced {
        opacity: 0.25;
        pointer-events: none;
      }
    }
  }
}
