.#{$pdp-prefix-reviews}__comments {
  height: 42px;

  &__tab-content {
    padding: unset;
  }

  &__button {
    color: $andes-blue-500;
    font-weight: $font-weight-regular;
    line-height: 1.29;
    margin-top: $spacing-20;
    border: none;
    background: none;
    padding: unset;
  }

  &__no-reviews {
    margin: $andes-spacing-64 auto;
    max-width: 250px;
    text-align: center;

    &__title {
      color: color(color_gray_dark);
      font-size: $font-size-18;
      display: block;
      margin: $andes-spacing-16 0 14px;
    }

    &__subtitle {
      color: color(color_gray_middle);
      font-size: $font-size-16;
      font-weight: $font-weight-light;
      line-height: $line-height-s;
    }
  }
}
