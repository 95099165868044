@import '../../../styles/variables';

// sass-lint:disable variable-name-format
/* stylelint-disable-next-line scss/dollar-variable-pattern */
$pswp__assets-path: '~frontend-photoswipe/src/css/default-skin/';
@import '~frontend-photoswipe/src/css/main';
@import '~frontend-photoswipe/src/css//default-skin/default-skin';

.pswp {
  z-index: 9999;
}

.pswp__top-bar {
  background-color: transparent;
}

.pswp__top-bar,
.pswp__button {
  opacity: 1 !important;
}
