@import '../../../styles/variables';

.#{$vpp-prefix}-apparel-size {
  display: flex;

  & &__fit-as-expected {
    background-color: color(color_white);
    border: 0;
    line-height: 14px;
    outline: none;
    padding: 0;

    .#{$vpp-prefix}-apparel-size__fit-as-expected__chevron {
      height: 12px;
      margin-left: 4px;
      vertical-align: middle;
      width: 12px;
    }
  }

  & &__links-separator {
    border-left: 1px solid $light-grey;
    margin-left: 9px;
    padding-left: 9px;
  }
}

.#{$vpp-prefix}-apparel-size__fit-as-expected__tooltip {
  padding: 0;
}
