.ui-seller-data-header {
  &__container {
    display: flex;
    width: 100%;
  }

  &__main-info-container {
    display: flex;
    justify-content: space-between;
  }

  &__description-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__logo-container {
    margin-right: $andes-spacing-12;
  }

  &__follow-action {
    margin-left: 4px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow-wrap: anywhere;
  }

  &__subtitle-container {
    display: flex;
    align-items: center;
    margin: 4px 0;
  }

  &__icon-verified {
    margin-right: 4px;
  }

  &__detail-info-container {
    display: flex;
    margin-top: 4px;
    flex-wrap: wrap;

    :not(:last-child) {
      margin-right: 8px;
    }
  }
}
