@import 'coupons';
@import '../../tag-limited/';

.ui-vpp-coupons {
  &__pills-container {
    margin-top: 0;
  }

  &__text {
    margin-top: $andes-spacing-4;
    margin-bottom: $andes-spacing-8;
  }

  &__action.ui-pdp-media {
    margin-top: $andes-spacing-8;
  }

  .ui-vpp-tag-limited {
    &__plus-button {
      display: inline-grid;

      .andes-button__content {
        top: -2px;
      }
    }
  }

  .#{$pdp-prefix}-action-modal {
    margin-top: 0;
  }
}

// Coupon Modal
.vpp-coupons-modal__modal.ui-pdp-iframe-modal {
  .#{$pdp-prefix}-iframe {
    height: 100%;
  }

  .andes-modal__header {
    padding: $andes-spacing-48;
    padding-bottom: $andes-spacing-12;
  }
}

.ui-pdp-action-modal__modal.ui-vpp-coupons-action-modal__awareness {
  height: 255px;

  @media (width >= 768px) {
    .andes-modal__header {
      padding: 42px 50px $andes-spacing-16;
    }

    .andes-modal__header.andes-modal__header--sticky {
      padding: 17px 49px;
    }

    .andes-modal__header .andes-modal__header__contents .andes-modal__title {
      font-size: 24px;
    }
  }
}

.ui-vpp-coupons--cursor-pointer,
.ui-vpp-coupons__awareness .ui-vpp-coupons__text {
  cursor: pointer;
}
