@import './denounce';

.#{$vpp-prefix}-denounce {
  width: 100%;
  justify-content: flex-end;
  margin-bottom: $andes-spacing-20;

  &__action {
    padding-right: 0;
  }
}
