@import '../components/make-question/styles/make-question';
@import '../components/make-question-sa/styles/make-question';
@import '../components/card-answer/styles/card-answer';
@import '../components/search-bar/styles/search-bar';
@import '../components/question-form/styles/question-form';
@import '../components/feedback/styles/feedback';
@import '../components/questions-list/styles/questions-list';
@import '../../../styles/variables';
@import '../../../styles/mixins';

$highlight: color(color_blow);

.#{$pdp-prefix}-qadb {
  @include action-message-question;

  width: 100%;
  display: block;

  &--highlighted {
    background-color: $highlight;
  }
}
