////
// Thermometer

// Settings
// ---------------------------------------------------------------- //

$ui-thermometer-level-inactive-height: 8px !default;
$ui-thermometer-level-inactive-margin-top: 2px !default;
$ui-thermometer-level-active-height: 12px !default;
$ui-thermometer-level-empty-color: #eee !default;
$ui-thermometer-level-inactive-colors: (#fff0f0, #fff5e8, #fffcda, #f1fdd7, #edf8ee) !default;
$ui-thermometer-level-active-colors: (#ff605a, #ffb656, #fff044, #bbff1f, #39b54a) !default;
$ui-thermometer-separation-width: 6px !default;
$ui-thermometer-separation-color: #fff !default;

// Implementation
// ---------------------------------------------------------------- //

////
// 1. Override default `meter` styles.
// 2. Visually remove separation from first level.
// 3. Used just to clear floats.

.ui-thermometer {
  width: 100%; // 1
  height: auto; // 1
  gap: $ui-thermometer-separation-width; // 2
  appearance: none; // 1
  overflow: hidden; // 3
  display: flex;
  box-sizing: border-box;
  vertical-align: baseline; // 1
}

.ui-thermometer__state {
  display: none;
}

.ui-thermometer__level {
  width: 20%;
  height: $ui-thermometer-level-inactive-height;
  margin-top: $ui-thermometer-level-inactive-margin-top;
  box-sizing: border-box;
  float: left;
}

@for $level from 1 through 5 {
  .ui-thermometer__level--#{$level} {
    /* stylelint-disable-next-line scss/no-global-function-names */
    background: nth($ui-thermometer-level-inactive-colors, $level);
  }
}

.ui-thermometer[value='0'] .ui-thermometer__level {
  margin: 0;
  background: $ui-thermometer-level-empty-color;
}

@for $level from 1 through 5 {
  .ui-thermometer[value='#{$level}'] .ui-thermometer__level--#{$level} {
    height: $ui-thermometer-level-active-height;
    margin: 0;
    /* stylelint-disable-next-line scss/no-global-function-names */
    background: nth($ui-thermometer-level-active-colors, $level);
  }
}
