.#{$pdp-prefix}-payment {
  $self: &;

  .price-tag__disabled {
    color: color(color_gray_middle);
  }

  svg {
    width: 20px;
    height: 20px;
  }

  &--md {
    .#{$pdp-prefix}-media__text {
      font-size: $font-size-14;
    }
  }
}
