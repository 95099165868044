$thumbnail-border-color: #ccc;
$thubmnail-disabled-label-color: color(color_gray_dark);
$thubmnail-blocked-label-color: $andes-gray-250;
$thumbnail-disabled-border-color: color(color_gray_middle);
$thumbnail-blocked-border-color: $andes-gray-250;
$thumbnail-shadow-color: rgba($andes-black, 0.15);
$thumbnail-blocked-opacity: color(color_black_04);

.#{$pdp-prefix}-thumbnail {
  border: 1px solid $andes-gray-250;
  border-radius: 6px;
  cursor: pointer;
  display: inline-flex;
  font-size: $font-size-12;
  font-weight: $font-weight-regular;
  padding: 0;
  margin-right: 8px;
  overflow: hidden;
  position: relative;

  &__picture {
    width: 54px;
    height: 54px;
    font-size: 12px;
    padding: 2px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &--SMALL {
      width: 36px;
      height: 36px;
    }
  }

  &__picture--list {
    display: inline-block;
  }

  &__label {
    padding: 8px 14px;
  }

  &--BLOCKED {
    color: $thubmnail-blocked-label-color;
    border: 1px solid '#f5f5f5';
    background: $thumbnail-blocked-opacity;

    .ui-pdp-thumbnail__picture {
      opacity: 0.15;
    }

    .ui-pdp-thumbnail__container {
      .ui-pdp-thumbnail__label {
        color: $andes-gray-250;
      }
    }
  }

  &--BLOCKED:hover {
    cursor: not-allowed;
  }

  &--DISABLED {
    color: $thubmnail-disabled-label-color;
    border: 1px dashed $thumbnail-disabled-border-color;
  }

  &--DISABLED:hover {
    cursor: pointer;
  }

  &--SELECTED {
    border: 2px solid $blue;
  }

  &__tooltip {
    pointer-events: none;
    padding: 11px $andes-spacing-16 11px $andes-spacing-16;
    width: fit-content;
  }

  &--shadow {
    box-shadow: 0 1px 3px 0 $thumbnail-shadow-color;
  }

  &--overlay {
    width: 100%;
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $andes-blue-500;
    background-color: $pdp-gallery-gray;
    font-size: 28px;
  }
}
