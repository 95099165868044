.#{$pdp-prefix}-qadb__smart-answer {
  &__feedback {
    margin-top: 24px;
    display: flex;

    span:first-of-type {
      margin-left: 32px;
    }

    span {
      margin-left: 40px;
    }

    &__action:hover {
      cursor: pointer;
    }
  }
}
