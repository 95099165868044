.#{$pdp-prefix}-warning-message {
  &__title {
    font-size: $font-size-22;
    line-height: 1.18;
    font-weight: $font-weight-regular;
  }

  &__subtitle {
    font-size: $font-size-14;
    line-height: 1.14;
    font-weight: $font-weight-regular;
  }
}
