@import './header';
@import '../../../styles/mixins';

.#{$pdp-prefix}-header {
  position: relative;

  &:has(.ui-pdp-highlights) {
    display: flex;
    flex-direction: column;
    gap: $andes-spacing-8;
  }

  .#{$pdp-prefix}-container__row--highlights {
    margin: 0;
  }

  &__subtitle {
    margin-right: $andes-spacing-40;
    margin-bottom: $andes-spacing-8;
  }

  &__bottom-subtitle {
    margin-right: $andes-spacing-40;
    line-height: 1;
    white-space: pre-wrap;

    /* stylelint-disable-next-line selector-class-pattern */
    &.ui-pdp-background-color--LIGHT_GRAY {
      width: fit-content;
      padding: 2px $andes-spacing-4;
    }
  }

  &__store {
    font-size: $font-size-14;
    color: color(color_gray_middle);
    font-weight: $font-weight-regular;
    margin-top: 2px;

    a {
      font-weight: $font-weight-regular;
    }
  }

  &__title-container {
    display: flex;
    flex: initial;
    gap: $andes-spacing-8;

    &:has(.ui-pdp-highlights) {
      flex-direction: column-reverse;
    }

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .#{$pdp-prefix}-subtitle {
    @include label;

    white-space: pre-wrap;
  }

  .#{$pdp-prefix}-subtitle_rex {
    font-size: $andes-spacing-12;
  }

  .#{$pdp-prefix}-title {
    flex: auto;
    padding: 0;
    margin-right: $andes-spacing-28;
    font-size: $font-size-22;
  }
}

.#{$pdp-prefix}-container__row--header {
  .#{$pdp-prefix}-header {
    width: 100%;
  }
}
