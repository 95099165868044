.#{$vpp-prefix}-grouped-share-bookmark {
  display: flex;
  justify-content: center;
  width: 100%;

  &-wishlist {
    align-items: center;
    margin-top: 10px;
    height: fit-content;
    width: inherit;

    &.desktop {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 8px;

      .ui-pdp-add-to-list {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;

        &__link {
          margin-top: $andes-spacing-12;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          width: 100%;
          color: var(--text-andes-text-color-link-ml, $andes-help-icon-stroke);
          text-align: center;
          font-size: $font-size-14;
          font-style: normal;
          font-weight: $font-weight-semibold;
          line-height: 20px;
          background: none;
          height: 52px;
          padding: $andes-spacing-16;
          align-items: center;
          border-radius: 8px;
          border: 1px solid $andes-gray-100;

          &--chevron {
            display: flex;
          }

          &.gift-registry {
            margin-top: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            color: var(--text-andes-text-color-link-ml, $andes-help-icon-stroke);
            text-align: center;
            font-size: $font-size-14;
            font-style: normal;
            font-weight: $font-weight-semibold;
            line-height: 20px;
            background: none;
            border: none;
            height: 52px;
            padding: $andes-spacing-16;
            align-items: center;
            border-radius: 0 0 8px 8px;
            border-bottom: 1px solid $andes-gray-100;
            border-left: 1px solid $andes-gray-100;
            border-right: 1px solid $andes-gray-100;
          }
        }
      }
    }

    &.mobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 0;

      .andes-card.andes-popper.tooltip.andes-tooltip.andes-tooltip--dark.andes-card--flat.andes-card--padding-0 {
        width: 272px !important;
        font-size: 14px;
      }

      .ui-pdp-add-to-list {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;

        &__link {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          width: 100%;
          color: var(--text-andes-text-color-link-ml, $andes-help-icon-stroke);
          text-align: center;
          font-size: $font-size-14;
          font-style: normal;
          font-weight: $font-weight-semibold;
          line-height: 20px;
          background: none;
          height: 52px;
          padding: $andes-spacing-16;
          align-items: center;
          border-radius: 8px;
          border: 1px solid $andes-gray-100;

          &--chevron {
            display: flex;
          }

          &.gift-registry {
            margin-top: 0;
            display: flex;
            justify-content: space-between;
            color: var(--text-andes-text-color-link-ml, $andes-help-icon-stroke);
            text-align: center;
            font-size: $font-size-14;
            font-style: normal;
            font-weight: $font-weight-semibold;
            line-height: 20px;
            background: none;
            border: none;
            height: 52px;
            padding: $andes-spacing-16;
            width: 100%;
            align-items: center;
            border-radius: 0 0 8px 8px;
            border-bottom: 1px solid $andes-gray-100;
            border-left: 1px solid $andes-gray-100;
            border-right: 1px solid $andes-gray-100;
          }
        }
      }
    }
  }
}

.ui-vpp-grouped-share-bookmark-no-logged {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .ui-pdp-bookmark__link-bookmark {
    height: 20px;
    border: 0;
    background: transparent;
    align-items: center;
    position: relative;
    display: block;
  }
}
