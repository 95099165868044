.#{$vpp-prefix}-progress-bar {
  &__container {
    width: 100%;
    border-radius: 8px;
    margin: 8px 0 6px;

    &.small {
      height: 6px;
    }

    &.large {
      height: 8px;
    }
  }

  &__fill {
    height: 100%;
    border-radius: inherit;
  }
}
