.#{$pdp-prefix}-quick-access__container {
  margin-top: 40px;

  .#{$pdp-prefix}-questions__subtitle {
    font-weight: $font-weight-semibold;
    padding-bottom: $andes-spacing-8;
  }

  .ui-pdp-action-modal {
    margin-top: $andes-spacing-8;
  }
}
