@import '~@andes/breadcrumb/index';
@import '../../../styles/variables';

.#{$pdp-prefix}-breadcrumb {
  font-size: $font-size-14;
  font-weight: $font-weight-regular;
  line-height: 1;
  display: flex;
  align-items: center;

  &__link {
    float: left;
    margin: 0 25px 0 0;
    position: relative;
    text-wrap: nowrap;
    margin-bottom: 2px;

    &::after {
      background-color: $andes-gray-200;
      content: '';
      height: 12px;
      position: absolute;
      right: -14px;
      top: 2px;
      width: 1px;
    }
  }

  .ui-pdp-bookmark__list-share {
    display: inline-block;
  }

  .andes-breadcrumb {
    margin: 0;

    &__chevron {
      padding: 0 6px;
    }

    & > li:last-child > .andes-breadcrumb__chevron {
      display: none;
    }
  }

  .andes-tooltip {
    padding: 0;
  }

  .andes-tooltip-hidden {
    display: block;
  }
}
