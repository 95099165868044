.#{$pdp-prefix}-banner {
  display: flex;
  height: fit-content;

  .#{$pdp-prefix}-banner-container {
    overflow: hidden;
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;

    .#{$pdp-prefix}-banner-card {
      overflow: hidden;
      width: 100%;
      position: relative;

      .#{$pdp-prefix}-banner-card-conten {
        padding: 0;
        display: flex;
        height: fit-content;

        .#{$pdp-prefix}-banner-imagen {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
