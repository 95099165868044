@import '../../../../styles/variables';

.#{$pdp-prefix}-shipping {
  &--remaining {
    .ui-pdp-media__text {
      max-width: 223px;

      [data-country='BR'] & {
        max-width: 209px;
      }

      span {
        display: inline-table;
      }
    }
  }

  &__info-tooltip__button {
    padding: 8px 16px;
  }

  &__message {
    margin: 10px 0 20px;
  }
}

.#{$pdp-prefix}-shipping-modal.andes-modal.andes-modal--small {
  width: 672px;

  .andes-modal__header,
  .andes-modal__header--sticky {
    padding: $andes-spacing-48 $andes-spacing-48 0;
    position: relative;
    background: none;
    z-index: 1;
  }

  .andes-modal__content {
    margin-top: (-$andes-spacing-28);
    position: relative;
    z-index: 0;
  }

  .andes-modal__header--sticky {
    box-shadow: none;
  }

  &.#{$pdp-prefix}-action-modal__modal--no-title {
    .andes-modal__content {
      margin: 0;
    }
  }
}
