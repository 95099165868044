@import '../components/rating/rating.desktop';
@import '../components/comments/comments.desktop';
@import '../components/review-comment/review-comment.desktop';
@import '../components/review-actions/styles/review-actions.desktop';

.#{$pdp-prefix-reviews} {
  width: 100%;

  &__header {
    &__title {
      font-size: $font-size-24;
      line-height: $line-height-xs;
    }
  }

  &__comments__tab-content,
  .andes-tab-content {
    margin-top: $andes-spacing-32;
    padding: 0 0 $andes-spacing-32;
  }

  .andes-tabs__slider {
    top: 40px;
  }
}

.#{$pdp-prefix-reviews}__empty-reviews {
  .#{$pdp-prefix-reviews} {
    &__actions__container {
      padding-left: 0;
    }

    &__header {
      margin-bottom: 0;
    }

    &__rating {
      margin-bottom: 0;
    }
  }
}
