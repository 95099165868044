@import '../../../styles/variables';

.container-advertising.ui-billboard-ad.billboard-context {
  width: 100%;
  padding-top: $andes-spacing-8;
}

.ui-billboard-ad {
  &__title-container {
    display: flex;
    align-items: center;
    margin-bottom: $andes-spacing-8;
  }

  &__title {
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    color: #999;
    padding-right: $andes-spacing-4;
  }

  &__line {
    border-top: solid 1px $andes-gray-100;
    flex-grow: 1;
  }
}
