@import '../../styles/variables';

.#{$pdp-prefix}-eyi {
  line-height: 1;
  position: relative;

  &__link {
    color: $andes-blue-500;
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    line-height: $line-height-xs;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
}
