.#{$pdp-prefix}-qadb__find-in-questions {
  &__button {
    display: inline-flex;
    margin-top: $andes-spacing-4;
    margin-bottom: 0;
    padding-left: 0;
  }

  &__button:hover {
    cursor: pointer;
    background-color: transparent;
  }

  &__button-text:hover {
    color: $andes-blue-500;
  }
}
