@import './smart-answer';

.#{$pdp-prefix}-qadb__smart-answer {
  &__text {
    line-height: 20px;
  }

  .ui-pdp-action-modal {
    display: inline-block;
  }

  .ui-pdp-action-modal__link {
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
  }
}
