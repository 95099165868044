.#{$pdp-prefix}-pick-up {
  $self: &;

  .ui-pdp-price__part {
    color: color(color_gray_dark);
  }

  .price-tag__disabled {
    color: color(color_gray_middle);
  }

  &--md {
    .#{$pdp-prefix}-media__text {
      font-size: $font-size-14;
    }
  }
}
