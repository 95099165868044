@import '../../../styles/variables';

$max-height-modal: 580px;

.#{$pdp-prefix}-exit-popup {
  &__div-exit-popup-button {
    width: 100%;
    padding: 2em 2em 0.5em;
  }

  &__div-exit-popup-title {
    padding: 2em 2em 0.5em;
  }

  &__bg-modal-exit-popup {
    background-color: color(color_black_1000);

    .andes-modal__close-button path {
      stroke: $andes-bg-white;
    }

    .andes-modal__close-button svg {
      height: 15px;
    }

    .andes-modal {
      &__header {
        padding: 0 0.5em 0.9em 0;
        background-color: color(color_black_1000);
      }

      &__content {
        padding: 0;
        background-color: #ededed;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        height: auto;
        max-height: $max-height-modal;
      }

      &__scroll {
        overflow: hidden;
      }
    }
  }

  &__div-title-tag {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  &__div-modal-title {
    background-color: #fff;
    width: 310px;
    height: auto;
    max-height: $max-height-modal;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top-left-radius: 6px;
  }

  &__div-modal-container {
    display: flex;
  }

  &__badge-exit {
    background-color: #00a650;
  }

  &__div-modal-carousel {
    height: auto;
    max-width: 790px;
    max-height: $max-height-modal;
    margin: 0 32px 24px;
  }

  &__title {
    font-size: 32px;
    margin-bottom: 10px;
    line-height: 40px;
  }

  &__card-item {
    width: 400px;
  }
}
