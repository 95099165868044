@import './compats-widget-sticky';

.#{$pdp-prefix}-container__row--compats-widget-sticky {
  z-index: 99;

  &.ui-pdp-compats--intersecting {
    .ui-compats-header {
      box-shadow: 0 6px 16px $andes-gray-100;
    }
  }

  .andes-tooltip.ui-compats-toolip-with-dropdown {
    position: relative;
    left: -60px;
  }
}

.#{$pdp-prefix}-container__row--compats-widget-sticky-mshop {
  z-index: 500;

  &.ui-pdp-compats--intersecting {
    z-index: 1000;

    .ui-compats-header {
      box-shadow: 0 6px 16px $andes-gray-100;
    }
  }

  .andes-tooltip.ui-compats-toolip-with-dropdown {
    position: relative;
    left: -60px;
  }
}
