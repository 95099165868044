@import '../../../styles/variables';

.navigation-official-stores {
  border-top: 1px solid $pdp-gray-100;
  padding: 30px;
  width: 100%;
  padding-top: $layout-padding-40;

  .andes-carousel-snapped {
    height: auto;

    &__wrapper {
      height: 215px;
    }

    &__slide {
      cursor: auto;
    }

    &__controls-wrapper {
      height: 230px;
    }

    &__control--next {
      right: -28px;
    }

    &__control--previous {
      left: -28px;
    }
  }

  .andes-card {
    width: fit-content;
    height: 210px;
    border-radius: 4px;
    box-shadow: 0 7px 11px 0 $pdp-gray-100, 0 2px 4px 0 $pdp-gray-100;
    left: 8px;
    position: relative;
  }

  &__logo {
    border-radius: 6px;
    box-shadow: 0 2px 15px 0 $pdp-gray-100;
    margin: 0 $andes-spacing-16;
    overflow: hidden;
    height: 80px;
    width: 80px;
    position: relative;

    img {
      height: auto !important;
    }
  }

  &__header {
    display: flex;
    align-items: baseline;
    margin-bottom: $andes-spacing-24;
    margin-left: 7px;
  }

  &__view-more {
    margin-left: $andes-spacing-12;
  }

  &__description {
    margin: $andes-spacing-12 0 0 $andes-spacing-16;

    &__title {
      display: flex;
      align-items: center;

      .ui-pdp-cockade-icon {
        margin-left: $andes-spacing-4;
      }
    }
  }

  &__background {
    margin-bottom: -55px;
    height: 107px;
    width: 260px;
    overflow: hidden;

    img {
      border-radius: 4px 4px 0 0;
    }
  }

  &__divider {
    background: $pdp-gray-100;
    height: 1px;
    position: relative;
    top: 55px;
  }
}
