@import '../../../styles/variables';
@import '~drift-zoom/dist/drift-basic.min.css';

.#{$pdp-prefix}-gallery__zoom {
  $self: &;

  &-container {
    background-color: $andes-white;
    display: none;
    height: 572px;
    position: fixed;
    width: 674px;
    z-index: $z-index + 4; // @TODO - Change should be in navigations. We added 2 more than dropdown

    &--visible {
      display: block;
      border: solid 1px $andes-gray-100;
      box-sizing: content-box;
    }

    img {
      object-fit: contain;
    }
  }
}

.#{$pdp-prefix}-gallery__zoom-zoom-pane {
  background: $andes-white;
  transform: translate3d(255, 255, 255);
}

.#{$pdp-prefix}-gallery__zoom-zoom-pane.#{$pdp-prefix}-gallery__zoom-opening,
.#{$pdp-prefix}-gallery__zoom-zoom-pane.#{$pdp-prefix}-gallery__zoom-closing {
  animation: none;
}
