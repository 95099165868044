.#{$pdp-prefix-reviews}__rating__summary {
  &__average {
    color: color(color_gray_dark);
    font-size: $font-size-64;
    font-weight: $font-weight-light;
  }

  &__label {
    color: color(color_gray_middle);
  }
}
