// sass-lint:disable class-name-format
.#{$pdp-prefix}-summary-list {
  &__item {
    font-size: $font-size-14;
    font-weight: $font-weight-regular;
    margin-bottom: $andes-spacing-4;
    line-height: 1.2;
    position: relative;

    /* stylelint-disable-next-line selector-class-pattern */
    &.ui-pdp-color--GREEN::before {
      background-color: color(color_green);
    }

    &-icon {
      .ui-pdp-icon {
        height: 14px;
        width: 54px;
      }
    }

    .ui-pdp-price__part {
      color: inherit;
      line-height: inherit;
      display: inline;

      .price-tag-cents {
        line-height: 1.4;
      }
    }
  }
}
