.ui-qadb__make-question-sa {
  margin-top: 40px;

  &__form {
    margin-top: 16px;
  }

  &__input {
    padding-top: 0;
    max-width: 768px;

    &.no-message {
      .andes-form-control__bottom {
        display: none;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
  }

  &__content {
    .andes-form-control--textbox {
      padding-top: 0;
    }

    .andes-form-control__field {
      min-height: 48px;
      margin-bottom: 0;
      margin-top: 0;
      padding-left: 16px;
      text-indent: 0;
      line-height: 1;
      padding-top: 15px;

      &::placeholder {
        font-size: 16px;
      }
    }

    .andes-form-control__message-fixed {
      margin-top: 8px;
      font-size: 13px;
    }
  }

  &__button {
    margin-left: 16px;
    vertical-align: top;
    width: 184px;
  }
}
