@import '../../../styles/variables';

.#{$vpp-prefix}-apparel-size {
  align-items: center;
  display: flex;
  padding: 0;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;

  .ui-pdp-action-modal {
    font-size: $font-size-14;
    margin-top: $andes-spacing-32;
    line-height: $andes-spacing-12;
  }

  .ui-pdp-action-modal a {
    color: $andes-blue-500;
    display: flex;
    flex-direction: row;

    .ui-vpp-apparel-size__action-modal {
      display: flex;
      align-items: center;
    }

    & svg {
      margin-right: 0.5rem;
    }
  }
}

.#{$vpp-prefix}-apparel-size-picker {
  justify-content: inherit;
  position: inherit;
  margin-bottom: $andes-spacing-8;

  .ui-pdp-action-modal {
    margin-top: 0;
    line-height: inherit;
  }
}
