.ui-pdp-accessibility-texts {
  &__buy {
    position: absolute;
    left: -10000px;
    top: auto;
    margin-top: 0.3rem;
    height: 1px;
    overflow: hidden;
    width: 1px;

    &:focus,
    &:focus-visible {
      position: static;
      height: auto;
      padding-top: -10px;
      width: auto;
    }
  }
}
