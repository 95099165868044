@import 'seller-info';

.#{$pdp-prefix}-buybox {
  .#{$pdp-prefix}-seller {
    $seller: &;

    &__header {
      align-items: flex-start;
    }

    &__header__title {
      flex-wrap: wrap;
      margin-top: 0;
      font-size: $font-size-14;
    }

    &__with-logo {
      .#{$pdp-prefix}-seller__header {
        &__title {
          font-size: $font-size-14;
          display: flex;
          align-items: baseline;

          .#{$pdp-prefix}-action-modal__link {
            font-size: $font-size-14;
          }
        }
      }
    }

    &--lowend {
      #{$seller}__container-qualification {
        margin-right: 64px;
      }
    }

    &__link-trigger.ui-pdp-action-modal {
      font-weight: $font-weight-regular;
      margin-top: $andes-spacing-4;
    }

    &-modal {
      width: 720px;
    }

    &__second-line {
      display: flex;
      align-items: flex-end;
    }
  }
}

.#{$pdp-prefix}-modal-seller {
  .andes-modal__header {
    user-select: none;
  }

  .andes-modal__content {
    padding: 0 64px 64px;
  }

  .#{$pdp-prefix}-seller {
    flex-direction: column;
    gap: 48px;

    &__header {
      margin: 0;
    }

    &__list-info {
      width: 100%;
    }
  }

  .andes-modal__close-button:focus {
    outline: transparent;
  }

  .andes-modal__close-button {
    margin-top: $andes-spacing-4;
  }

  .andes-modal__header__contents {
    margin-right: $andes-spacing-16;
  }
}

.#{$pdp-prefix}-s-results {
  .#{$pdp-prefix}-seller__with-logo {
    .#{$pdp-prefix}-seller__header {
      &__subtitle {
        line-height: 14px;
      }
    }
  }
}

.#{$pdp-prefix}-seller {
  &__compats {
    width: 100%;
    padding-top: $andes-spacing-32;
  }

  &__link {
    display: inline;
  }
}
