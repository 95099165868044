@import '../../styles/mixins';
@import '../../styles/variables';
@import './other-sellers-item';
@import '~@seo-frontend-components/seo-anchor/index';

$pdp-prefix-other-sellers: 'ui-pdp-other-sellers';

.#{$pdp-prefix-other-sellers} {
  $block: &;

  width: 100%;
  border-radius: 8px;
  border: 1px solid $andes-gray-100;

  &__title {
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    line-height: 16px;
    color: color(color_gray_dark);
    padding: $andes-spacing-16;
    border-bottom: 1px solid $andes-gray-100;
  }

  &__button {
    padding: 0;
    font-weight: $font-weight-semibold;

    .andes-button__content {
      font-size: $font-size-14;
      justify-content: left;
      padding: $andes-spacing-16;
    }

    .ui-pdp-price__part {
      color: inherit;
      font-weight: inherit;
      margin-left: $andes-spacing-4;
      line-height: 18px;
    }
  }
}
