@import '../../../styles/variables';

.#{$vpp-prefix}-fit-as-expected {
  margin-bottom: $andes-spacing-8;
  width: 100%;
  white-space: nowrap;

  & &__fit-as-expected {
    background-color: color(color_white);
    border: 0;
    line-height: 14px;
    outline: none;
    padding: 0;
    cursor: pointer;
    font-size: $font-size-12;

    .#{$vpp-prefix}-fit-as-expected__chevron {
      height: 12px;
      margin-left: 4px;
      vertical-align: middle;
      width: 12px;
    }
  }

  :focus {
    outline: none;
  }

  .andes-tooltip__trigger {
    margin-right: 0;
  }
}

.#{$vpp-prefix}-fit-as-expected__tooltip {
  width: max-content;
}
