@import '../../../styles/variables';

.#{$pdp-prefix}-returns {
  width: 100%;

  &__card {
    padding: 16px 12px;
    border-radius: 6px;
    background-color: $pdp-green-100;
    display: flex;
    align-items: flex-start;

    &__main {
      margin-left: 12px;

      &__title {
        font-size: $font-size-14;
        font-weight: $font-weight-semibold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
      }

      &__subtitle {
        font-size: $font-size-14;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        font-weight: $font-weight-regular;
        margin-top: 2px;
      }

      &__link {
        font-size: $font-size-14;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.14;
        letter-spacing: normal;
        margin-top: $andes-spacing-8;
        font-weight: $font-weight-regular;
      }

      .ui-pdp-action-modal {
        margin-top: 0;
      }

      &__modal {
        width: 840px;

        .andes-modal__close-button {
          top: 56px;
        }
      }
    }
  }
}
