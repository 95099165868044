.#{$pdp-prefix}-buybox__quantity {
  &__input {
    &__custom-options-quantity {
      display: flex;
      padding-top: $andes-spacing-16;
      font-size: $font-size-16;
      font-weight: $font-weight-semibold;
      color: $andes-text-color-primary;
      width: 100%;
      align-items: baseline;

      &__label {
        display: flex;

        .andes-money-amount {
          font-weight: $font-weight-semibold;
        }

        .andes-money-amount__suffix {
          color: $andes-text-color-primary;
          font-size: $font-size-12;
          margin-left: 0;
        }

        &-quantity {
          flex: 2;
          justify-content: flex-start;

          span {
            margin-left: $andes-spacing-4;
          }
        }

        &-amount {
          flex: 1;
          justify-content: flex-end;
        }
      }
    }
  }
}
