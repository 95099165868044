/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin fill-svg--white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(194deg) brightness(104%) contrast(102%);
}

.#{$vpp-prefix}-highlighted-specs__highlighted-attributes {
  display: flex;

  &__card {
    background-color: $andes-white;
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 $andes-gray-100;
    position: relative;

    &:not(:first-of-type) {
      margin-left: 8px;
    }
  }

  &__card-border {
    background-color: $andes-blue-500;
    border-radius: 6px 0 0 6px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }

  &__card-labels {
    margin-left: 8px;
  }

  &__card-content {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    padding-top: 12px;
  }

  .ui-vpp-image-component {
    @include fill-svg--white;
  }
}
