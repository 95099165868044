.#{$vpp-prefix}-highlighted-specs__filled-icon {
  position: relative;
  display: block;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    margin: auto;
  }

  &--ROUNDED {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    border-radius: 50%;
  }

  &--SQUARE {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    border-radius: 4px;
  }
}
