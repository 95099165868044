$thermometer-level-inactive-height: 6px !default;
$thermometer-level-active-height: $andes-spacing-8 !default;
$thermometer-level-empty-color: rgb(0 0 0 / 70%) !default;
$thermometer-level-colors: (#f23d4f, #f73, #ffe600, #aadb1e, #00a650) !default;
$thermometer-separation-width: $andes-spacing-4 !default;

.ui-seller-data-status {
  &__container {
    margin-top: $andes-spacing-16;
    width: 100%;
  }

  &__lider-seller {
    width: 100%;
    margin-left: 6px;
    display: flex;
    flex-direction: column;
  }

  &__icon-medal {
    width: 16px;
    height: 16px;
    margin-top: 2px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &__thermometer-container {
    display: flex;
  }

  &__info-container {
    display: flex;
    text-align: center;
    margin-top: $andes-spacing-12;
  }

  &__info {
    flex: 1 1 0;
  }

  &__info-icon {
    margin: auto;
    height: 18px;

    img {
      height: $andes-spacing-16;
      width: 18px;
    }
  }

  &__info-title {
    line-height: 18px;
  }

  &__info-subtitle {
    margin-top: $andes-spacing-8;
    line-height: 14px;
  }

  &__default-info {
    margin-top: 12px;
  }

  &__thermometer {
    display: flex;
    justify-content: end;
    margin-top: $andes-spacing-8;
    align-items: center;

    .thermometer__level {
      height: $thermometer-level-inactive-height;
    }

    .thermometer__level:not(:first-child) {
      margin-left: $thermometer-separation-width;
    }
  }
}

.thermometer-large {
  width: 100%;
}

@for $level from 1 through 5 {
  .thermometer__level--#{$level} {
    width: 18px;
    /* stylelint-disable-next-line scss/no-global-function-names */
    background: nth($thermometer-level-colors, $level);
    opacity: 0.15;
  }

  .thermometer__level--#{$level}-large {
    width: 20%;
  }
}

.ui-seller-data-status__thermometer[value='0'] .thermometer__level {
  background: $thermometer-level-empty-color;
  width: 100%;
}

@for $level from 1 through 5 {
  .ui-seller-data-status__thermometer[value='#{$level}'] .thermometer__level--#{$level} {
    width: 40px;
    height: $thermometer-level-active-height;
    /* stylelint-disable-next-line scss/no-global-function-names */
    background: nth($thermometer-level-colors, $level);
    opacity: 1;
  }

  .ui-seller-data-status__thermometer[value='#{$level}'] .thermometer__level--#{$level}-large {
    width: 20%;
  }
}

.ui-seller-data.ui-box-component-pdp__visible--desktop {
  @for $level from 1 through 5 {
    .thermometer__level--#{$level} {
      width: 6px;
    }

    .thermometer__level--#{$level}-large {
      width: 20%;
    }
  }

  @for $level from 1 through 5 {
    .ui-seller-data-status__thermometer[value='#{$level}'] .thermometer__level--#{$level} {
      width: $andes-spacing-40;
    }

    .ui-seller-data-status__thermometer[value='#{$level}'] .thermometer__level--#{$level}-large {
      width: 20%;
    }
  }
}
