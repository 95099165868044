.#{$compats-prefix}-highlight-card {
  border-radius: $border-radius-6;
  padding: $andes-spacing-16;
  position: relative;
  margin-bottom: $andes-spacing-4;

  &.#{$compats-prefix}-highlight-card--BLUE::before {
    background-color: color(color_blue);
  }

  &.#{$compats-prefix}-highlight-card--ORANGE::before {
    background-color: color(color_orange);
  }

  &.#{$compats-prefix}-highlight-card--GREEN::before {
    background-color: color(color_green);
  }

  &.#{$compats-prefix}-highlight-card--RED::before {
    background-color: color(color_red);
  }

  &.#{$compats-prefix}-highlight-card::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: $andes-spacing-4;
    border-radius: $border-radius-6 0 0 $border-radius-6;
  }

  .#{$compats-prefix}-highlight {
    cursor: auto;
  }

  .#{$compats-prefix}-highlight-card__description,
  .#{$compats-prefix}-highlight {
    margin-bottom: 10px;

    &__text {
      max-width: 265px;
    }
  }

  .#{$compats-prefix}-highlight-card__description,
  .#{$compats-prefix}-highlight-card__action {
    margin-left: $andes-spacing-20;
  }
}
