.#{$buy-box-offers-component-prefix}__desktop {
  .#{$buy-box-offers-component-prefix}__filter-message {
    padding: $andes-spacing-12 0 $andes-spacing-24;
  }

  .#{$buy-box-offers-component-prefix}__offer-price {
    padding: $andes-spacing-4 0;
  }

  .#{$buy-box-offers-component-prefix}__offer-content {
    padding: 3px 2px 0 6px;
  }

  .#{$buy-box-offers-component-prefix}-bottom__promotions,
  .#{$buy-box-offers-component-prefix}-bottom__tags {
    padding-top: $andes-spacing-16;
    padding-bottom: $andes-spacing-16;
  }

  .#{$buy-box-offers-component-prefix}-bottom__promotions {
    .#{$vpp-prefix}-payment-promotion__container {
      margin: 0;
    }
  }

  .#{$buy-box-offers-component-prefix}-bottom__tags {
    .#{$pdp-prefix}-price__tags {
      margin: 0;
    }
  }

  .#{$buy-box-offers-component-prefix}__offer-generic-summary {
    margin-bottom: $andes-spacing-20;
  }

  .#{$buy-box-offers-component-prefix}__offer-see-more {
    background: none !important;
    cursor: default;
  }

  .andes-list--selectable .andes-list__item {
    &.#{$buy-box-offers-component-prefix}__offer-list-item--SELECTED {
      padding-bottom: 0;
    }

    &.#{$buy-box-offers-component-prefix}__offer-list-item--NOT-SELECTED {
      .andes-list__item-action {
        cursor: pointer;
      }

      .#{$pdp-prefix}-price__subtitles {
        margin-top: $andes-spacing-8;
        gap: $andes-spacing-8;
      }
    }
  }

  .#{$buy-box-offers-component-prefix}__offer-list-children {
    font-size: $font-size-14;
    margin: $andes-spacing-20 6px $andes-spacing-16;

    .#{$pdp-prefix}-seller {
      &__header {
        margin-bottom: 0;
        width: 100%;

        &__title {
          display: flex;
          align-items: baseline;
          flex-wrap: wrap;
          margin-bottom: 4px;
          line-height: 1;
        }

        &__subtitle {
          line-height: 14px;

          &:not(:last-child) {
            margin-bottom: 6px;
          }
        }

        &__image-container {
          width: 40px;
          height: 40px;
          margin-right: $andes-spacing-16;
          flex-shrink: 0;
        }
      }

      &__brand-title {
        margin-bottom: $andes-spacing-4;
      }
    }
  }
}
