.#{$pdp-prefix}-iframe-reviews {
  width: 840px;
  max-width: unset;
  position: relative;
  max-height: 640px !important;
  min-height: 480px;
  height: 100%;
  overflow: hidden;

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .andes-modal__scroll {
    height: 100%;
    overflow-y: hidden;
  }

  .andes-modal__content {
    padding: unset;
    height: 100%;
  }

  .andes-modal__close-button {
    opacity: 0;
  }
}
