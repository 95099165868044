@import '../../../styles/variables';
@import '../../../styles/functions';

@mixin tooltip($side) {
  position: relative;
  border: solid 0.5px $pdp-gray-100;
  box-shadow: 0 8px 16px 0 $pdp-gray-100, 0 0 6px 0 $pdp-gray-100;

  &.andes-tooltip--highlight::before {
    background-color: $andes-blue-500;
  }

  &::before {
    content: '';
    background-color: $andes-white;
    width: 1em;
    height: 1em;
    position: absolute;
    border-bottom: solid 0.5px $pdp-gray-100;
    border-right: solid 0.5px $pdp-gray-100;
    box-shadow: 1px 1px 1px -1px $pdp-gray-200;

    @if $side == top-center {
      transform: rotate(225deg);
      left: 50%;
      margin-left: -0.5em;
      top: -0.45em;
    }

    @if $side == top-left {
      transform: rotate(225deg);
      left: 14%;
      margin-left: -0.5em;
      top: -0.45em;
    }

    @if $side == bottom-center {
      transform: rotate(45deg);
      left: 50%;
      margin-left: -0.5em;
      bottom: -0.55em;
    }
  }
}

.#{$pdp-prefix}-card-tooltip {
  &__card {
    padding: 10px $andes-spacing-16;
    position: relative;
    font-weight: weight(weight_regular);

    &--has-shadow {
      box-shadow: 0 8px 16px 0 $pdp-gray-100, 0 0 6px 0 $pdp-gray-100;
    }

    &--arrow {
      &-top-center {
        @include tooltip(top-center);
      }

      &-top-left {
        @include tooltip(top-left);
      }

      &-bottom-center {
        @include tooltip(bottom-center);
      }
    }

    .ui-pdp-color--white {
      color: $andes-white;
    }

    &__icon--close {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      line-height: 0;

      svg {
        height: 8px;
        width: 8px;

        path {
          fill: $andes-white;
          fill-opacity: 0.45;
        }
      }
    }

    &__content {
      display: flex;
      align-items: center;

      &__icon {
        flex-shrink: 0;
        margin-right: 15px;
      }

      &__texts {
        width: 100%;
        padding: 0 5px 0 0;
      }

      &__subtitle {
        margin-top: 4px;

        .#{$pdp-prefix}-price__part {
          display: inline;
          line-height: 1.35;

          .price-tag-cents {
            line-height: 1.7;
          }
        }
      }

      &__progressBar__container {
        margin: $andes-spacing-8 0 6px;
      }

      &__title {
        sup {
          vertical-align: top;
          font-size: 10px;
          position: relative;
          top: 1px;
        }

        .ui-pdp-icon--full {
          width: 50px;
          height: 15px;
          top: 4px;
        }

        .ui-pdp-icon--full-super {
          width: fit-content;
          height: fit-content;
          top: 5px;
        }

        .ui-pdp-price__part {
          font-weight: weight(weight_bold);
          line-height: inherit;

          .price-tag-cents {
            line-height: 1.4em;
          }
        }

        .ui-pdp-icon--supermarket-fs {
          vertical-align: middle;
        }
      }
    }
  }
}

.#{$vip-prefix}-card-tooltip {
  margin-top: 20px;

  .ui-pdp-card-tooltip__card {
    padding: 18px $andes-spacing-16 $andes-spacing-16;

    &__content {
      &__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .ui-pdp-icon {
          margin-right: $andes-spacing-8;
        }
      }

      &__action {
        margin-top: $andes-spacing-8;

        .#{$vip-prefix}-credits-bau__action-label-component {
          margin-block: 0;

          .ui-pdp-media__action {
            margin-left: 0;
          }
        }
      }
    }
  }
}
