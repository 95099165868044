.#{$vpp-prefix}-merch-ecosystem {
  &__box {
    $block: &;

    width: 100%;
    border-radius: 8px;
    border: 1px solid $andes-gray-100;
  }

  &__title {
    padding: $andes-spacing-16;
    border-bottom: 1px solid $andes-gray-100;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    color: color(color_gray_dark);
  }

  &__content {
    padding: 0 $andes-spacing-16;
  }

  &__image {
    margin-top: $andes-spacing-24;
    margin-bottom: $andes-spacing-24;
  }

  &__content-title {
    font-weight: $font-weight-regular;
    font-size: $font-size-16;
    line-height: $font-size-20;
    color: color(color_gray_dark);
  }

  &__description {
    font-size: $font-size-16;
    line-height: $font-size-20;
    margin: $andes-spacing-4 0 $andes-spacing-24;
    color: color(color_gray_middle);
    font-weight: $font-weight-regular;
  }

  &__button-box {
    padding: $andes-spacing-16;
    border-top: 1px solid $andes-gray-100;
  }

  &__button {
    font-size: $font-size-14;
    width: 100%;
    height: 32px;
  }
}

.#{$vpp-prefix}-merch-ecosystem-container.#{$pdp-prefix}--component-list {
  .#{$vpp-prefix}-merch-ecosystem__box {
    border: none;
  }
}
