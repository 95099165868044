.#{$pdp-prefix}-container__row--compats-widget-sticky {
  position: sticky;
  top: 0;

  &.ui-pdp-compats--store {
    max-width: 1184px;
    margin: -66px auto 68px;
  }
}

.#{$pdp-prefix}-container__row--compats-widget-sticky-mshop {
  position: sticky;
  top: 0;

  &.ui-pdp-compats--store {
    max-width: 1184px;
    margin: -66px auto 68px;
  }
}

.ui-vip-grouped-header__header-store.ui-vip-grouped-header__header--with-compats {
  padding-bottom: $andes-spacing-20;
  z-index: 100;
}
