@import '../../../styles/variables';
@import './zoom';
@import '../../lightbox/styles/lightbox.desktop';

.#{$pdp-prefix}-gallery {
  $self: &;

  height: 100%;
  position: relative;
  width: 478px;
  min-height: 504px;

  &__figure__image {
    cursor: zoom-in;
    height: auto;
    object-fit: contain;
    width: 100%;
  }

  &__column {
    position: absolute;
    height: auto;
    margin-left: $andes-spacing-24;
    margin-top: $andes-spacing-24;

    &__variation-gallery {
      cursor: zoom-in;
      height: auto;
      object-fit: contain;
      width: 100%;
    }

    &__variation-picture {
      background-color: $andes-white;
      display: none;
      left: 50px;
      margin: $andes-spacing-24 0 0;
      min-height: 500px;
      height: 100%;
      position: absolute;
      top: 0;
      width: 410px;
      z-index: 1;
      padding: $andes-spacing-16;
    }
  }

  &__figure {
    display: flex;
    left: 56px;
    height: 504px;
    margin: 0 $andes-spacing-24;
    min-height: 500px;
    position: absolute;
    text-align: center;
    opacity: 0;
    top: 0;
    visibility: hidden;
    background-color: $andes-white;
    width: 358px;
    padding: 0;

    &__with-overlay {
      border-radius: $andes-spacing-8;
      overflow: hidden;
    }
  }

  &__figure__clip {
    width: 20.231rem;
    margin: auto;
  }

  &__figure-VIDEO {
    width: 100%;
  }

  &__input {
    display: none;
  }

  &__input:checked + &__wrapper {
    #{$self}__figure {
      opacity: 1;
      visibility: visible;
      z-index: 1;
    }

    .#{$pdp-prefix}-thumbnail__picture {
      margin: -1px;
    }

    #{$self}__thumbnail {
      border: 2px solid $andes-blue-500;
    }
  }

  &__wrapper {
    display: block;
    margin-bottom: $andes-spacing-8;
  }

  &__input:hover + &__wrapper {
    #{$self}__figure {
      opacity: 1;
      z-index: 3;
      visibility: visible;
    }
  }

  &__thumbnail {
    border-radius: 4px;
    margin-right: 0;
    z-index: 5;

    button {
      outline: none;
      border: none;
      background-color: color(color_white);
      cursor: pointer;
    }
  }

  &__label {
    position: relative;
    display: flex;
  }

  &__see-more {
    background-color: $pdp-gallery-gray;
    padding: $andes-spacing-12;
  }

  &__thumbnail-icon {
    display: flex;
    width: 24px;
    height: 24px;
  }

  &__video-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 3px solid $andes-blue-500;
    background-color: $pdp-gallery-gray;
    border-radius: 50%;
    display: block;
  }

  &__overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: $andes-spacing-8;
    pointer-events: none;
  }

  &--fallback-boundary {
    object-fit: contain;
  }
}

// FIX: para ie11
// sass-lint:disable
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  _:-ms-fullscreen,
  :root .#{$pdp-prefix}-gallery__figure__image {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

// sass-lint:enable
