.#{$pdp-prefix-reviews}__rating {
  margin-bottom: $andes-spacing-24;
}

.#{$pdp-prefix-reviews}__rating__summary {
  display: inline-block;

  &__average {
    line-height: $line-height-xxs;
    text-align: right;
  }

  &__rating {
    margin-top: $spacing-14;
    text-align: right;
  }

  &__rating__star {
    width: 24px;
    height: 22.8px;
    margin-left: $andes-spacing-8;

    &:first-child {
      margin-left: 0;
    }
  }

  &__label {
    font-size: $font-size-14;
    font-weight: $font-weight-light;
    margin-top: $andes-spacing-12;
    position: relative;
    top: -$andes-spacing-8;
  }
}

.#{$pdp-prefix-reviews}__rating__extended {
  display: inline-block;
  max-width: 315px;
  width: 100%;
}

.#{$pdp-prefix-reviews}__rating__extended__level {
  height: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;

  &__column {
    display: flex;
    flex-direction: column;

    &--right--margin {
      margin-right: $andes-spacing-24;
    }

    &--left--margin {
      margin-left: $andes-spacing-24;
    }
  }

  &__text {
    color: color(color_gray_middle);
    min-width: 72px;
    text-align: right;
  }

  &__value {
    color: color(color_gray_middle);
  }
}

.#{$pdp-prefix-reviews}__rating__extended__level__progress-bar {
  width: 120px;
  height: 4px;
  border-radius: 100px;
  margin-top: $andes-spacing-4;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  &__background {
    background-color: $andes-gray-100;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    display: block;
    position: absolute;
    inset: 0;
  }

  &__fill-background {
    background-color: $andes-blue-500;
    height: 100%;
    border-radius: 100px;
    display: block;
    position: absolute;
    inset: 0;
  }
}
