@import '../shipping/styles/shipping';

.#{$pdp-prefix}-iframe-modal {
  iframe {
    width: 100%;
    border: 0;
    -webkit-overflow-scrolling: touch;
    transition: 0.5s cubic-bezier(0.72, 0.04, 0.12, 0.97);
  }

  .andes-spinner__mask {
    background-color: $andes-white;
  }
}

.#{$pdp-prefix}-buybox {
  .#{$pdp-prefix}-card-tooltip .andes-card--flat {
    box-shadow: 0 8px 16px 0 $pdp-gray-100, 0 0 6px 0 $pdp-gray-100;
  }
}
