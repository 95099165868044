.#{$pdp-prefix}-seller__list-extra-info {
  .ui-seller-info__status-info {
    margin-top: 32px;
  }

  .ui-seller-info__status-info:last-child {
    margin-bottom: 0;
  }

  .#{$pdp-prefix}-seller__extra-info-subtitle {
    margin-left: 30px;
    font-size: $font-size-14;
  }

  .#{$pdp-prefix}-seller__extra-info-figure-pin {
    fill: rgba($andes-black, 0.65);
  }
}

.ui-seller-info {
  color: color(color_gray_dark);
  width: 100%;

  &__status-info {
    display: flex;
    margin-bottom: $andes-spacing-20;

    &__icon {
      margin: 2px 10px 0 0;
      width: 20px;
      text-align: center;

      .#{$pdp-prefix}-icon--pin {
        width: 20px;
        height: 18px;
      }

      // todo: change color in vpp-backend
      .#{$pdp-prefix}-icon--pin.#{$pdp-prefix}-color--GRAY {
        fill: color(color_gray_dark);
      }
    }

    &__title {
      font-size: $font-size-16;
      font-weight: $font-weight-regular;
    }

    &__subtitle {
      font-size: $font-size-14;
      color: color(color_gray_middle);
    }
  }

  &__location-pin {
    & > svg {
      width: 20px;
      height: 20px;
      fill: color(color_gray_dark) !important;
    }
  }
}
