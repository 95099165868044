.#{$vpp-prefix}-discrete-bar {
  &__bar {
    display: flex;
    flex-direction: row;
    width: 100%;

    &__interval {
      width: 100%;
      height: 8px;
      background-color: color(color_gray_light);

      &:not(:first-of-type) {
        margin-left: 2px;
      }

      &--highlighted {
        background-color: $andes-blue-500;
      }
    }

    &--rounded {
      .#{$vpp-prefix}-discrete-bar__bar__interval {
        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }

  &__description {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 8px;

    &__label {
      font-size: $font-size-12;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: color(color_gray_dark);
    }
  }
}
