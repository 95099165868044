.#{$vpp-prefix}-highlighted-specs__key-value {
  width: 100%;
  display: flex;
  align-items: center;

  &__labels {
    margin-left: 16px;
    display: flex;
    align-items: center;

    &__value {
      margin-left: 4px;
    }
  }

  .ui-vpp-highlighted-specs__filled-icon {
    align-self: start;
  }
}
