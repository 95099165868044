$button-border: rgb(196 196 196);

.ui-vpp-questions-ai-suggestion {
  &__spinner-container {
    display: flex;
    justify-content: center;
  }

  &__spinner-container__spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__card-content {
    display: flex;
    flex-direction: column;
    gap: $andes-spacing-8;
  }

  &__answered-by {
    display: flex;
    gap: $andes-spacing-4;
    margin-top: $andes-spacing-8;
  }

  &__icon {
    height: 16px;
    width: 17px;
    fill: #3483fa;
    margin-top: 1px;
  }

  &__content {
    padding: $andes-spacing-16 $andes-spacing-16 10px;
  }

  &__content-answer {
    display: flex;
    justify-content: space-between;
    gap: $andes-spacing-20;
  }

  &__footer {
    padding: $andes-spacing-16;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgb(0 0 0 / 10%);
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  p.ui-vpp-questions-ai-suggestion__ask-seller {
    cursor: pointer;
    margin: 0;
  }

  .andes-card {
    background-color: #e9f2ff;

    &--outline {
      border: none;
    }

    &.andes-popper {
      width: 196px;
      height: $andes-spacing-48;
      background-color: $andes-white;
      align-content: center;
    }
  }
}
