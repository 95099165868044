@import '~@andes/money-amount/index';

$buy-box-offers-component-prefix: #{$pdp-prefix}-buy-box-offers;

.#{$buy-box-offers-component-prefix} {
  letter-spacing: normal;
  font-weight: $font-weight-regular;
  color: $andes-gray-900;
  display: inline-block;

  &__offer-list {
    border: 1px solid $andes-gray-100-solid;
    border-radius: $border-radius-6;
    line-height: 1.3;

    &.andes-list--selectable .andes-list__item {
      &.#{$buy-box-offers-component-prefix}__offer-list-item-action,
      &.#{$buy-box-offers-component-prefix}__offer-list-item--SELECTED {
        cursor: default;
      }

      &.#{$buy-box-offers-component-prefix}__offer-list-item-action:hover,
      &.#{$buy-box-offers-component-prefix}__offer-list-item--SELECTED:hover {
        background-color: transparent;
      }

      &.#{$buy-box-offers-component-prefix}__offer-list-item--SELECTED {
        transition: max-height 800ms ease-in-out;

        input[type='radio'] {
          accent-color: $andes-blue-500;
        }
      }

      &.#{$buy-box-offers-component-prefix}__offer-list-item--NOT-SELECTED {
        background-color: $andes-gray-040;
      }

      &.#{$buy-box-offers-component-prefix}__offer-list-item--NOT-SELECTED:hover {
        background-color: $andes-gray-070;
      }
    }
  }

  &__offer-list-item {
    display: flex;
    flex-direction: column;
    justify-content: normal;
    padding: 13px 10px 10px;
    height: auto;

    .andes-list__item-action {
      display: block;
      margin: 0;
      padding: 0;
    }

    .andes-radio {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
    }

    .andes-radio__label {
      font-size: $font-size-14;
      font-weight: $font-weight-semibold;
      margin-left: $andes-spacing-4;

      &.#{$buy-box-offers-component-prefix}__with-icon {
        display: flex;
        align-items: center;

        .ui-pdp-cockade-icon {
          margin-left: $andes-spacing-4;
          display: inline-flex;
        }
      }
    }

    &.andes-list__item {
      padding: 13px 10px 10px;
    }
  }

  &__offer-content {
    width: 100%;

    &--subtitles {
      padding-bottom: $andes-spacing-4;

      .#{$pdp-prefix}-price__part {
        display: inline;
      }
    }

    .#{$buy-box-offers-component-prefix}__offer-price {
      .#{$pdp-prefix}-price {
        &__second-line {
          .#{$pdp-prefix}-price__info-icon {
            margin-top: 6px;
          }
        }
      }
    }
  }

  &__offer-price {
    &__tooltip--price {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &__label {
        font-weight: $font-weight-regular;
        margin-left: $andes-spacing-8;
      }

      &__icon {
        margin-right: 5px;
        vertical-align: top;
      }

      .#{$pdp-prefix}-icon--price-info {
        position: relative;
        top: 2px;
        margin-left: $andes-spacing-8;
      }
    }

    .andes-money-amount {
      font-weight: $font-weight-regular;
    }

    .andes-money-amount-combo {
      .andes-money-amount-combo__main-container .andes-money-amount {
        font-weight: $font-weight-light;
      }

      .andes-money-amount-combo__previous-value {
        font-weight: $font-weight-regular;
      }
    }

    .ui-pdp-icon {
      display: inline;
      vertical-align: sub;
    }

    &__subtitles {
      display: flex;
      align-items: baseline;
      flex-direction: column;
      line-height: 1;
      margin-top: $andes-spacing-4;

      & > p {
        display: inline;
      }

      .#{$pdp-prefix}-price__part {
        font-weight: $font-weight-regular;
        display: inline-flex;
      }
    }
  }

  &__offer-generic-summary {
    .#{$pdp-prefix}-generic-summary {
      padding: 0;
    }

    .#{$pdp-prefix}-generic-summary--md .#{$pdp-prefix}-media__text {
      font-size: $font-size-14;
    }

    .#{$pdp-prefix}-media__text {
      font-size: $font-size-14;
    }
  }

  &__action-link {
    display: flex;
    font-size: $font-size-14;
    padding-bottom: $andes-spacing-4;
    padding-left: $andes-spacing-4;

    .#{$pdp-prefix}-action-modal {
      margin-top: 2px;
    }

    .#{$pdp-prefix}-action-modal__link,
    .#{$pdp-prefix}-media__action {
      font-weight: $font-weight-semibold;
    }
  }

  &__promotions {
    margin-top: 14px;
  }

  &-bottom__tags {
    .#{$pdp-prefix}-action-modal {
      margin-top: 0;
    }
  }
}

.ui-vpp-coupons {
  margin: 0;
}

.andes-list__item-action {
  text-align: inherit;
}

.ui-bbo-wrapper__tooltip {
  z-index: $z-index-tooltip;
  width: fit-content;
}
