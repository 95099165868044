@import './review-summary';

.#{$pdp-prefix}-review {
  &__rating {
    font-size: $font-size-14;
    margin-right: $andes-spacing-8;
    position: relative;
    top: 1px;
  }

  &__amount {
    font-size: $font-size-14;
    margin-left: 5px;
  }

  &__ratings {
    .#{$pdp-prefix}-icon {
      width: 15px;
      height: 15px;
    }
  }
}
