.#{$pdp-prefix}-qadb__questions-list {
  &__see-more__modal-content {
    width: 840px;
    max-height: 90%;
    max-width: unset;
    height: 700px;
  }

  &__question {
    margin-top: 24px;
  }
}
