$striped-row-odd-background: color(color_gray_lighter);

.#{$vpp-prefix}-striped-specs {
  .andes-table {
    table-layout: fixed;
    border-collapse: separate;
    border-radius: 5px;
    overflow: hidden;
  }

  .andes-table__head {
    display: block;
    position: relative;
    height: auto;
    width: auto;
    padding: 12px;
    text-align: left;
    background: $andes-white;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
  }

  &__header {
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    color: color(color_gray_dark);
    padding-bottom: $andes-spacing-16;
  }

  &__row {
    display: flex;
    border: none;
    width: 100%;

    &__column {
      width: 50%;
      background-color: inherit;
      border: none;
      padding: 12px;
      word-break: break-word;
      overflow: hidden;
      text-align: left;
      font-size: 12px;
      overflow-wrap: break-word;
      white-space: break-spaces;

      &::before {
        content: none;
      }
    }

    &:nth-of-type(odd) {
      background: $striped-row-odd-background;

      &:hover {
        background: $striped-row-odd-background;
      }
    }

    &:nth-of-type(even) {
      background: $andes-white;

      &:hover {
        background: $andes-white;
      }
    }
  }
}
