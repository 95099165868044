.#{$pdp-prefix}-qadb__make-question {
  &__label {
    width: 100%;

    b {
      font-weight: $font-weight-semibold;
    }
  }

  &__button {
    // @TODO Andes Revisar esto, tal vez la clase deberia ser .andes-button
    .andes-button__content {
      font-weight: $font-weight-semibold; // ver si pisa valores por defecto
    }
  }
}
