@import '../../../styles/variables';
@import './header-brand-logo-commons';

.#{$pdp-prefix}__header-brand-logo {
  @include header-brand-logo;

  aspect-ratio: 25 / 2;
  border-top-left-radius: $border-radius-4;
  border-top-right-radius: $border-radius-4;
  overflow: hidden;
}
