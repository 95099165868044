@import '../../../styles/variables';

.#{$pdp-prefix}-benefits {
  width: 100%;

  &__item:not(:last-child) {
    margin-bottom: $andes-spacing-16;
  }

  .#{$pdp-prefix}-media__title {
    font-weight: $font-weight-regular;
    font-size: $font-size-14;

    .#{$pdp-prefix}-action-modal {
      display: inline;

      .andes-tooltip__trigger {
        display: inline;
      }

      div {
        display: inline;
      }
    }
  }

  .#{$pdp-prefix}-media__figure {
    margin-top: 1px;
  }

  .#{$pdp-prefix}-icon {
    width: 16px;
    height: 16px;
  }

  .#{$pdp-prefix}-icon--mpago_logo {
    margin-top: 4px;

    img {
      max-width: 20px;
      vertical-align: baseline;
    }
  }
}
