@import './compats-highlight';

.#{$compats-prefix}-highlight {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &__text {
    max-width: 298px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__tooltip-compats {
    right: $andes-spacing-20;
  }

  &__tooltip-compats .andes-tooltip-data__arrow {
    &::before {
      width: 11px;
      height: 11px;
      right: -2px;
    }
  }
}
