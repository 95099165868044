@import './hs-highlighted-features-list';

.#{$vpp-prefix}-highlighted-specs__features-list {
  &-item {
    margin-top: 8px;
    line-height: 1.43em;
    padding-left: 10px;
    text-indent: -11px;

    &:first-child {
      margin-top: 16px;
    }

    &::before {
      color: color(color_gray_dark);
      padding-right: 5px;
    }
  }
}
