@import '../../../../../styles/variables';

.counter-button {
  position: relative;
  border: 1px solid $andes-gray-250;
  color: $andes-gray-550;
  text-align: center;
  border-radius: $border-radius-6;
  display: flex;
  transition: transform 0.2s ease-in-out, opacity 0.15s ease-in-out;
  overflow: hidden;

  &--large {
    font-size: $font-size-16;
    height: $andes-spacing-48;

    .andes-button {
      padding: 10px $andes-spacing-8;
    }
  }

  &--small {
    font-size: $font-size-14;
    height: $andes-spacing-32;
  }

  &--fade {
    .counter-button__selector {
      animation: translateOutLabel 0.2s, fadeOut 0.15s;
      animation-fill-mode: forwards;
    }

    .counter-button__spinner-container {
      animation: translateInSpinner 0.2s, fadeIn 0.15s;
      animation-fill-mode: forwards;
    }
  }

  &__selector {
    display: flex;
    flex: 1;
    align-items: center;

    &--animate {
      animation: translateInLabel 0.2s, fadeIn 0.15s;
      animation-fill-mode: forwards;
    }

    &__label {
      flex: 1;
    }

    &__button {
      &.andes-button {
        border: 0;
        margin: 0;
        height: auto;
      }

      .andes-button__content {
        top: 0;
      }

      &--large {
        &.counter-button__selector__button--decrement {
          margin-left: $andes-spacing-4;
        }

        &.counter-button__selector__button--increment {
          margin-right: $andes-spacing-4;
        }
      }

      &--increment,
      &--decrement {
        stroke-width: 0;

        svg {
          max-width: unset;
          max-height: unset;

          path {
            fill: $andes-blue-500;
          }
        }
      }

      &.andes-button--disabled {
        &.counter-button__selector__button--increment,
        &.counter-button__selector__button--decrement {
          svg path {
            fill: $andes-gray-250;
          }
        }
      }
    }

    @media (hover: none) {
      &__button:hover {
        background: none;
      }
    }
  }

  &__spinner-container {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: translateOutSpinner 0.2s, fadeOut 0.15s;
    animation-fill-mode: forwards;

    &__spinner {
      display: unset;

      // Esto es porque el width y heigth del spinner es 32px y left al 50%
      // lo posiciona en base al (0;0) del spinner. Por lo tanto para centrarlo
      // hay que moverlo la mitad del width total.
      // Idem para el heigth.
      left: calc(50% - 16px);
      top: calc(50% - 16px);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes translateInLabel {
    from {
      transform: translateY(32px);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes translateOutLabel {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(32px);
    }
  }

  @keyframes translateInSpinner {
    from {
      transform: translateY(-36px);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes translateOutSpinner {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-36px);
    }
  }
}
