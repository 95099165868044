// sass-lint:disable no-vendor-prefixes, no-duplicate-properties //
$rating-prefix: '#{$vpp-prefix}-rating';

.#{$rating-prefix} {
  display: inline-block;
  padding-right: $andes-spacing-16;
  width: 100%;
}

.#{$rating-prefix}__title {
  margin-bottom: $andes-spacing-16;
}

.#{$rating-prefix}__level {
  align-items: center;
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 1fr 35px;
  -ms-grid-columns: 1fr 1fr 35px;
  grid-column-gap: 16px;
  justify-content: space-between;
  vertical-align: middle;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__text-container {
    width: 100%;
    grid-column: 1;
    grid-row: 1;

    .#{$rating-prefix}__level__text--left {
      text-align: left;
    }

    .#{$rating-prefix}__level__text--right {
      text-align: right;
    }
  }

  &__progress-bar-container {
    grid-column: 2;
    grid-row: 1;

    .#{$rating-prefix}__level__progress-bar {
      border-radius: 100px;
      display: inline-block;
      height: 4px;
      margin-top: $andes-spacing-4;
      position: relative;
      vertical-align: middle;

      &__background {
        background-color: $andes-gray-100;
        border-radius: 100px;
        inset: 0;
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
      }

      &__fill-background {
        background-color: $andes-blue-500;
        border-radius: 100px;
        inset: 0;
        display: block;
        height: 100%;
        position: absolute;
      }
    }
  }

  &__value-container {
    grid-column: 3;
    grid-row: 1;
  }
}
