.#{$pdp-prefix}-buybox__quantity {
  &--native {
    height: 50px;
    width: 50px;
    border: 1px solid $andes-gray-100;
    border-radius: 4px;
    top: -1px;
    margin-bottom: 10px;

    &__input {
      padding: 8px;
      width: 100%;
      height: 100%;
      border: 0;
      outline: none;
      border-radius: 4px;
      color: $andes-black;
      font-weight: $font-weight-semibold;
    }

    &__input::placeholder {
      color: $andes-black;
    }
  }

  &__lowend__input {
    margin: 12px 0;

    .andes-form-control__field {
      width: 95px;

      // sass-lint:disable no-vendor-prefixes //
      &::-webkit-inner-spin-button {
        appearance: none;
      }
    }
  }

  &__lowend--mobile {
    .andes-form-control__field {
      width: 100%;
    }
  }
}
