// VPP Common
@import '../../../styles/common';

@import '../../../styles/common-andes-fixes';
@import '../../../styles/common-navigation-fixes';

@import './questions-ai-form/styles/questions-ai-form';
@import './questions-ai-suggestion/styles/questions-ai-suggestion';
@import './questions-ai-user-feedback/styles/questions-ai-user-feedback';
@import './questions-ai-denounce/styles/questions-ai-denounce';

.#{$pdp-prefix}-questions {
  &__questions-ai {
    margin: 36px 0;
    display: flex;
    flex-direction: column;
    gap: $andes-spacing-24;

    .andes-form-control {
      // Hacemos esto para que el focus no tenga un doble box-shadow
      &__control.visible-focused,
      &--focused.visible-focused {
        box-shadow: 0 0 0 0.125em #3483fa;
      }
    }
  }
}
