.#{$vpp-prefix}-highlighted-specs__highlighted-attributes-list {
  width: 100%;
  columns: 3;

  & > div {
    width: 100%;
    display: inline-block;
    margin-bottom: 24px;
  }
}
