/* stylelint-disable prettier/prettier */
$andes-bundle: 'desktop';

// VPP Common
@import '../../../styles/common';

// Andes Shared for VPP and VPP SHOPS
@import './vpp.andes.desktop';

// Andes styles for PDP Desktop
@import '~@andes/button/lib/styles/modifier/white';
@import '~@andes/button/lib/styles/size/small';
@import '~@andes/dropdown/index';
@import '~@andes/floating-menu/base';
@import '~@andes/modal/lib/styles/large/actions';
@import '~@andes/modal/lib/styles/large/index';

// Recos FE
@import '~@recommendations-fe/carousel-snapped/src/styles/recommendations-fe-carousel-snapped.desktop';
@import '~@recommendations-fe/combos/src/styles/desktop';
@import '~@recommendations-fe/comparator/src/styles/recommendations-fe-comparator.desktop';
@import '~@recommendations-fe/list/src/styles/recommendations-fe-list.desktop';
@import '~@recommendations-fe/shops/src/styles/recommendations-fe-shops.desktop';
@import '~@recommendations-fe/card/src/styles/recommendations-fe-card-horizontal.desktop';
@import '~@recommendations-fe/card/src/styles/recommendations-fe-card-vertical.desktop';
@import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-horizontal--for-carousel-snapped';
@import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-horizontal--for-list-pads';
@import '~@recommendations-fe/card/src/styles/bundles/recommendations-fe-card-vertical--for-carousel-snapped';

// Seo Frontend Components
@import '~@seo-frontend-components/extended-menu/index';

// Polycard
@import '~@polycard/phrase/build';
@import '~@polycard/card/build';

// UI Reviews
@import '~@ui-reviews/ui-reviews-capability/src/styles/reviews.desktop';

// CPG Nordic
@import '~cpg-nordic/src/styles/cpg.desktop';

// EShops Components Library
@import '~eshops-components-library/src/components/ProfileHeader/lib/desktop';
@import '~eshops-components-library/src/components/BottomSheet/index';

// VPP Components Library
@import '~@vpp-frontend-components/product-suggestions/desktop';

// UI Comps web
@import '~@ui-autoparts-web/compats/src/styles/desktop';

// VPP Common
@import './vpp';

// VPP Internal imports
@import '../../../components/thumbnail/thumbnail.desktop';
@import '../../../components/buybox/actions/styles/action.desktop';
@import '../../../components/buybox/styles/buybox.desktop';
@import '../../../components/buybox/shipping/styles/shipping.desktop';
@import '../../../components/seller-reputation-info/styles/seller-reputation';
@import '../../../components/seller-reputation-info/styles/seller-reputation-pdp';
@import '../../../components/description/styles/description.desktop';
@import '../../../components/header/styles/header.desktop';
@import '../../../components/review-summary/styles/review-summary.desktop';
@import '../../../components/gallery/styles/gallery.desktop';
@import '../../../components/grouped-share-bookmark/grouped-share-bookmark';
@import '../../../components/gift-registry/styles/gift-registry.desktop';
@import '../../../components/list/list.desktop';
@import '../../../components/price/styles/price.desktop';
@import '../../../components/buy-box-offers/styles/buy-box-offers';
@import '../../../components/buy-box-offers/styles/buy-box-offers.desktop';
@import '../../../components/warning-message/styles/warning-message.desktop';
@import '../../../components/promotions/styles/promotions.desktop';
@import '../../../components/variations/styles/variations.desktop';
@import '../../../components/variations/styles/variations-dropdown';
@import '../../../components/generic-summary/generic-summary.desktop';
@import '../../../components/seller-info-label/styles/seller-info.desktop';
@import '../../../components/other-products/other-products.desktop';
@import '../../../components/iframe-reviews/styles/iframe-reviews.desktop';
@import '../../../components/related-searches/styles/related-searches.desktop';
@import '../../../components/features/styles/features';
@import '../../../components/share/share.desktop';
@import '../../../components/share/share-content.desktop';
@import '../../../components/specs/specs.desktop';
@import '../../../components/specs/spec-groups-collapsable';
@import '../../../components/action-modal/styles/action-modal.desktop';
@import '../../../components/reviews/styles/reviews.desktop';
@import '../../../components/bookmark/styles/bookmark.desktop';
@import '../../../components/questions/styles/questions.desktop';
@import '../../../components/accessibility-texts/styles/index';
@import '../../../components/action-collapsable/styles/action-collapsable.desktop';
@import '../../../components/other-sellers/other-sellers.desktop';
@import '../../../components/product-tags/styles/desktop';
@import '../../../components/qadb/styles/qadb.desktop';
@import '../../../components/pick-up/pick-up.desktop';
@import '../../../components/sell-your-item/sell-your-item';
@import '../../../components/edit-your-item/edit-your-item';
@import '../../../components/payment-methods/styles/payment-methods.desktop';
@import '../../../components/box-component/styles/box-component.desktop';
@import '../../../components/onboarding/styles/onboarding.desktop';
@import '../../../components/tooltip/components/styles/information-tooltip-content';
@import '../../../components/tooltip/tooltip.desktop';
@import '../../../components/tooltip/tooltip';
@import '../../../components/breadcrumb/styles/breadcrumb';
@import '../../../components/data-display/discrete-bar/styles/discrete-bar';
@import '../../../components/data-display/progress-bar/styles/progress-bar';
@import '../../../components/highlighted-specs/styles/highlighted-specs.desktop';
@import '../../../components/specs/striped-specs';
@import '../../../components/cpg/styles/summary.desktop';
@import '../../../components/html-description/styles/html-description.desktop';
@import '../../../components/recommendations/styles/comparator.desktop';
@import '../../../components/recommendations/styles/polycard-overrides.desktop';
@import '../../../components/merch-ecosystem/styles/merch-ecosystem';
@import '../../../components/sticky-wrapper/styles/sticky-wrapper.desktop';
@import '../../../components/apparel-size-specs/styles/apparel-size-specs.desktop';
@import '../../../components/apparel-size-specs/styles/fit-as-expected.desktop';
@import '../../../components/moq-information/styles/moq-information.desktop';
@import '../../../components/stock-information/styles/stock-information.desktop';
@import '../../../components/shipping-message/styles/shipping-message';
@import '../../../components/tooltip-legal/styles/tooltip-legal.desktop';
@import '../../../components/apparel-size-specs-preview/styles/apparel-size-specs-preview.desktop';
@import '../../../components/official-stores/styles/carousel.desktop';
@import '../../../components/header-top-brand/styles/header.desktop';
@import '../../../components/header-brand-logo/styles/header-brand-logo.desktop';
@import '../../../components/on-demand-iframe/styles/on-demand-iframe.desktop';
@import '../../../components/eshops/styles/eshops.desktop';
@import '../../../components/coupons/styles/coupons.desktop';
@import '../../../components/coupons-awareness/styles/coupons-awareness.desktop';
@import '../../../components/trade-in/styles/trade-in.desktop';
@import '../../../components/preload-iframe/styles/index.desktop';
@import '../../../components/header-official-store/styles/header.desktop';
@import '../../../components/denounce/styles/denounce.desktop';
@import '../../../components/store-header/styles/store-header.desktop';
@import '../../../components/buybox/banner/styles/banner';
@import '../../../components/calculator/styles/calculator-wrapper';
@import '../../../components/advertising-adn/styles/billboard-context.desktop';
@import '../../../components/installation-service/styles/installation-widget.desktop';
@import '../../../components/enhanced-content/styles/enhanced-content.desktop';
@import '../../../components/extended-menu/styles/extended-menu.desktop';
@import '../../../components/grading-button-item/styles/gradings.desktop';
@import '../../../components/component-list/component-list';
@import '../../../components/compats-widget/styles/compats-widget.main.desktop';
@import '../../../components/kits/styles/kits.desktop';

// Recos overrides Polycard
@import '~@recommendations-fe/commons/src/styles/overrides/polycard_card-list';
@import '~@recommendations-fe/commons/src/styles/overrides/polycard_card-grid';
@import '~@recommendations-fe/list/src/styles/overrides/polycard_list-pads';
@import '@vpp-frontend-components/collapsable-card/index';

html,
body {
  min-width: 1160px;
}

// TO-DO: add this change in navigation
.nav-header,
[role='main'],
.nav-footer {
  display: flex;
  flex-flow: column wrap;
}

.#{$pdp-prefix} {
  max-width: $pdp-layout-width;

  // Official Stores
  .#{$pdp-prefix}-header-official-store-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;

    .ui-vpp-header-top-brand__bg-image,
    .ui-vpp-store__image-wrapper {
      z-index: -1;
    }
  }

  &-container--top.ui-vpp-container-top--gray {
    margin-bottom: 0;
    background-color: #ededed;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 0 2px 0 $andes-gray-250;
    margin-top: 143px;

    .#{$pdp-prefix}-group-header {
      padding: $andes-spacing-16 $andes-spacing-24 0;
      position: relative;
      z-index: 2;
    }

    .#{$pdp-prefix}-group-header-breadcrumb {
      background-color: #ededed;
      position: relative;
      padding: 0 $andes-spacing-24 $andes-spacing-16;
      z-index: 2;
    }

    .#{$pdp-prefix}-related-search,
    .#{$pdp-prefix}-container--breadcrumb-related:empty,
    .#{$pdp-prefix}-container__row--breadcrumb {
      margin-top: 0;
    }
  }

  &-title {
    hyphens: none;
  }

  &-container__row--reverse {
    flex-direction: row-reverse;
  }

  &-container--top {
    margin-bottom: $andes-spacing-12;
  }

  &-container--official-store {
    background: $andes-gray-070-solid;
    padding: 0 16px 12px;
    border-radius: 4px 4px 0 0;
  }

  &-container--recos-top {
    margin-top: $andes-spacing-24;

    &:empty {
      margin-top: 0;
    }
  }

  &-container__row--breadcrumb {
    margin-top: $andes-spacing-12;
  }

  &-container--share {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 200px;
  }

  &-container--pdp {
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 $andes-gray-250;
    background-color: $andes-white;
    min-width: 800px;
    margin-bottom: 24px;

    &.ui-vpp-container--bt-rect {
      border-radius: 0 0 4px 4px;
    }
  }

  &-container__row--available-quantity {
    display: contents;
  }

  &--cbt-taxes-summary:not(:empty) {
    margin-top: 20px;
  }

  &--cbt-taxes-fc-us-summary:not(:empty) {
    margin-top: 5px;
  }

  &--relative {
    position: relative;
  }

  &-container {
    &--border-left {
      border-right: 1px solid $andes-gray-100;
    }

    &--column-right {
      min-width: $pdp-layout-column-right;
      max-width: 325px;
    }

    &--border-bottom {
      border-right: 1px solid $andes-gray-100;
    }
  }

  &-variations {
    .andes-dropdown.andes-dropdown--form {
      padding-top: 0;

      .andes-dropdown__arrow {
        z-index: 0;
      }
    }
  }

  &-container__top-wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-direction: column;
  }

  &-with--separator--40-24:not(:empty)::after {
    margin: $andes-spacing-40 $andes-spacing-24;
  }

  &-with--separator--auto:not(:empty)::after {
    content: '';
    display: block;
    border-bottom: 1px solid $andes-gray-100;
    height: 1px;
    width: auto;
  }

  // Recos

  &-recommendations-row {
    max-width: 860px;

    .ui-recommendations-carousel-snapped {
      &.#{$pdp-prefix}-with--separator:not(:empty)::after {
        width: 100%;
      }

      .andes-carousel-snapped__container {
        max-width: 720px;
      }
    }
  }

  // Combos

  .ui-recommendations-combos.ui-pdp-with--separator:not(:empty)::after {
    width: calc(100% + $andes-spacing-24);
  }

  &-container__col .#{$pdp-prefix}__header-top-brand {
    margin-bottom: -30px;
    margin-top: 16px;
  }

  &-carousel-above-seller {
    border-top: 1px solid $andes-gray-100;

    &.no-border {
      border-top: none;
    }
  }

  &-spot-middle {
    margin-left: $andes-spacing-24;
    margin-right: $andes-spacing-24;

    &__separator:not(:empty)::before {
      content: '';
      display: block;
      margin: $andes-spacing-40 0;
      border-bottom: 1px solid $andes-gray-100;
      height: 1px;
      width: 100%;
    }
  }
}

.ui-pdp-stock-and-full {
  &:has(.ui-pdp-stock-information),
  &:has(.ui-pdp-promotions-pill) {
    margin-top: $andes-spacing-24;
    margin-bottom: $andes-spacing-8;
  }
}

.ui-pdp-banner + .ui-pdp-stock-and-full {
  margin-top: 0;
}

.ui-pdp-banner + .ui-pdp-container__row--returns-summary {
  margin-top: 0;
}

.drift-bounding-box {
  z-index: 2;
}

.ui-vpp-payment-promotion__container.split-payments {
  @media (width <= 1209px) {
    .ui-vpp-payment-promotion {
      &__action_button_chevron {
        padding: 1px 0;
      }
    }
  }
}

.#{$pdp-prefix}-iframe-modal.andes-modal.ui-pdp-action-modal__modal.split-payments-modal.andes-modal--small {
  width: 607px;
  height: 425px;

  .andes-modal__content {
    height: 310px;
    padding: 0;
  }

  iframe {
    width: 100%;
    border: 0;
    transition: 0.5s cubic-bezier(0.72, 0.04, 0.12, 0.97);
  }

  .andes-modal__header {
    padding: $andes-spacing-48 $andes-spacing-48 $andes-spacing-12 $andes-spacing-48;
  }

  .andes-spinner__mask {
    background-color: $andes-white;
  }
}

// TODO: sacar esto cuando termine la prueba de sin descripción
.ui-pdp-container__row--highlighted-specs + .ui-pdp-recommendations-row {
  .ui-recommendations--desktop .slick-list {
    padding-bottom: 0;
  }
}

.ui-pdp-container__row--cbt-summary {
  margin-bottom: $andes-spacing-20;
}

.ui-pdp-container__row--cbt-taxes-summary {
  margin-bottom: $andes-spacing-20;
}

.ui-pdp-container__row--cbt-taxes-fc-us-summary {
  margin-bottom: $andes-spacing-4;
}

.#{$pdp-prefix}-stock-and-full {
  .andes-tooltip__trigger:hover {
    cursor: pointer;
  }

  .ui-pdp-icon--full {
    top: 3px;
    width: 50px;
    height: 15px;
  }

  .ui-pdp-icon--full-super {
    width: fit-content;
    height: fit-content;
  }

  .ui-pdp-icon--tooltip {
    fill: $andes-blue-500;
  }

  .ui-pdp-promotions-pill-label--with-icon {
    padding: 0;
  }
}

// TODO: sacarlos cuando migremos la grilla de VIP a PDP.
.ui-box-component-pdp__visible--desktop {
  width: 100%;
  border-radius: 8px;
  border: 1px solid $andes-gray-100;
  margin-top: $andes-spacing-24;
  padding: 32px 16px 24px;
}

.ui-box-component-pdp__hidden--desktop {
  display: none;
}

// Se agrega las sieguientes reglas de Andes por que al setear andes-bundle estas reglas desaparecen
// TODO: sacar cuando se resuelva con andes-bundle
.andes-tab:nth-child(1):nth-last-child(3),
.andes-tab:nth-child(2):nth-last-child(2),
.andes-tab:nth-child(3):nth-last-child(1) {
  width: 33.3333%;
}

// @TODO en Andes esta con padding 1em por defecto, y no queda muy bien,
// verlo con alguien de Andes y UX pero esto queda como paliativo
.andes-tooltip__content {
  margin-right: 0;
}

.ui-pdp-header .ui-pdp-title {
  margin-right: 0;
}

.ui-review-capability__container-vpp .ui-review-capability {
  > div:first-child {
    flex-shrink: 1;
  }
}

.ui-pdp .ui-pdp-container__row--grouped-share-bookmark {
  position: relative;
  top: -9px;
}

.ui-pdp .ui-pdp-container__row--gift-registry {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.snackbar--remove-bookmark,
.snackbar--add-bookmark {
  margin-bottom: 32px;
}

.ui-pdp-container__row.ui-pdp-group-header-breadcrumb {
  min-height: $andes-spacing-28;

  .ui-pdp-container__col.col-4 {
    flex-grow: 2;
  }
}

// VPP styles overrides
/* stylelint-disable selector-no-qualifying-type */
main[role='main'] {
  position: relative;

  .#{$pdp-prefix} {
    width: $pdp-layout-width;

    // Questions
    .#{$pdp-prefix}-questions__content {
      column-gap: $andes-spacing-16;
    }

    // Official Stores
    .ui-vpp-header-top-brand,
    .ui-vpp-store-header,
    .#{$pdp-prefix}-header-official-store-header {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;

      .ui-vpp-store__image-wrapper {
        z-index: -1;
      }
    }

    .ui-vpp__cpg-header {
      .ui-vpp-header-top-brand,
      .ui-vpp-store-header,
      .#{$pdp-prefix}-header-official-store-header {
        top: 136px;
      }
    }
  }

  .#{$pdp-prefix}-container__row {
    &--top-available-quantity-summary {
      margin: $andes-spacing-24 0 0 0;

      & svg {
        width: 15px !important;
        height: 15px !important;
        top: 1px;
        margin-right: $andes-spacing-4;
      }

      & .ui-pdp-action-modal__link {
        font-weight: 600;
      }
    }

    &--bulk-sale-quantity {
      margin-top: $andes-spacing-8;
      display: inherit;

      & + .ui-pdp-actions {
        margin-top: $andes-spacing-24;
      }
    }
  }

  .#{$pdp-prefix}-container--column-right {
    .#{$pdp-prefix}-component-list,
    .#{$pdp-prefix}-container__row--warranty {
      flex-grow: 1;
    }

    .#{$pdp-prefix}-component-list {
      padding: 0;

      .ui-vpp-box.warranty {
        & > .ui-vpp-box__item {
          margin-bottom: $andes-spacing-24;
        }

        & > .ui-vpp-box__item:last-child {
          margin-bottom: 0;
        }

        .ui-vpp-box__item__title {
          margin-bottom: $andes-spacing-12;
        }
      }

      .#{$pdp-prefix}-container__row .ui-box-component {
        border: none;
        margin: 0;
        padding: 0;
      }

      & > * {
        border: none;
        border-top: 1px solid $andes-gray-100;
        border-radius: 0;
        padding: $andes-spacing-32 $andes-spacing-16;
        margin: 0;
      }

      & > *:first-child {
        border-top: none;
      }
    }

    .mshops-pads-variation-original__client-pdp {
      margin-top: $andes-spacing-24;
    }

    &-bottom {
      .mshops-pads-variation-original__client-pdp {
        margin-top: -$andes-spacing-16;
      }
    }
  }

  .ui-vip-compats--dark {
    margin-bottom: 25px;
  }

  .ui-vip-compats--head {
    margin-bottom: $andes-spacing-12;
  }

  .ui-pdp-compats__compats {
    padding-top: $andes-spacing-24;
  }

  .ui-pdp-collapsable-card__card--skeleton {
    margin-top: $andes-spacing-12;
  }

  .ui-pdp__collapsable-card{
    margin-top: $andes-spacing-12;
  }

  // Questions message UPP
  .#{$pdp-prefix}-questions {
    .#{$pdp-prefix}-question-warning__action {
      background: none;
      padding: 0;
      margin: 0;
      line-height: 1.4;
      flex-shrink: 0;
    }
  }
}

.ui-pdp-iframe-modal-top-quantity-summary {
  height: 570px;
  width: 830px;

  .andes-modal__scroll {
    overflow: hidden;
    height: 100%;
  }

  .andes-modal__header {
    padding: 48px 48px 32px !important;
  }

  .andes-modal__content {
    padding: 0 48px;
    height: 100%;

    iframe {
      overflow: hidden;
      height: 100%;
    }
  }

  .andes-modal__close-button path {
    stroke: $andes-gray-250 !important;
  }
}
