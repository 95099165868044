@import '../../../styles/variables';

@mixin store-header {
  width: 100%;
  display: flex;
  align-items: center;

  .ui-pdp-cockade-icon {
    margin-left: $andes-spacing-4;
    display: inline-flex;
  }

  &__image-container {
    width: 24px;
    height: 24px;
    position: relative;
    display: flex;
    align-items: center;
    margin-right: $andes-spacing-8;
    padding: 2px;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: color(color_gray_lighter);
      border-radius: 4px;
      left: 0;
    }

    &__image {
      border-radius: 4px;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
  }
}
