/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
/* stylelint-disable property-no-vendor-prefix */
@import 'thermometer';
@import 'container-info';

.#{$pdp-prefix}-buybox {
  .#{$pdp-prefix}-seller {
    &__header {
      margin-bottom: 0;
      width: 100%;

      &__title {
        display: flex;
        align-items: baseline;
        margin-bottom: 4px;
        line-height: 1;
      }

      &__subtitle {
        line-height: 14px;

        &:not(:last-child) {
          margin-bottom: 6px;
        }
      }

      &__image-container {
        width: 40px;
        height: 40px;
        margin-right: 16px;
        flex-shrink: 0;
      }
    }
  }
}

.#{$pdp-prefix}-seller {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-weight: $font-weight-regular;
  font-size: $font-size-14;
  width: 100%;
  overflow: hidden;
  max-width: fit-content;

  &__header {
    &__title {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      margin-bottom: 4px;
      line-height: 1;

      &-label {
        .ui-pdp-cockade-icon {
          bottom: 0;
        }
      }
    }
  }

  &__label-sold {
    color: color(color_gray_dark);
    font-weight: $font-weight-regular;
    margin-right: 2px;
  }

  &__label-sold.line-break {
    display: inline-block;
  }

  &__label-text-with-icon {
    display: inline-block;
  }

  &__brand-title {
    display: block;
    margin-bottom: 5px;

    &-container {
      display: flex;
      min-width: 100%;

      .ui-pdp-cockade-icon {
        bottom: 2px;
      }
    }
  }

  &__link-trigger-button {
    display: inline;
    text-align: left;
    text-wrap: wrap;
    word-break: break-word;
    padding: 0;
    margin-bottom: 2px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 2px #fff, 0 0 0 3px #2968c8, 0 0 0 5px rgba(65, 137, 230, 0.3);
      -moz-box-shadow: 0 0 0 2px #fff, 0 0 0 3px #2968c8, 0 0 0 5px rgba(65, 137, 230, 0.3);
      -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 3px #2968c8, 0 0 0 5px rgba(65, 137, 230, 0.3);
      outline: none;
    }

    span:last-of-type {
      color: $andes-blue-500;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      cursor: pointer;
    }
  }

  &__subtitle {
    width: 100%;
  }

  .#{$pdp-prefix}-icon--chevron {
    margin-left: 7px;
  }
}

.#{$pdp-prefix}-seller__tooltip {
  padding: $andes-spacing-24;
  width: 374px;

  .andes-tooltip-arrow::before {
    margin-top: 30px;
  }

  .andes-tooltip-button-close {
    display: none;
  }
}

.#{$pdp-prefix}-seller__row {
  display: block;
}

.#{$pdp-prefix}-seller__title {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 32px;
}
