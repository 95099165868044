$table-column-bg-color: #ebebeb;

.#{$pdp-prefix}-specs {
  color: color(color_gray_dark);

  &__title {
    padding-bottom: $andes-spacing-32;
  }

  &__subtitle {
    font-size: $font-size-20;
    padding-bottom: $andes-spacing-24;
    margin-top: $andes-spacing-32;
  }

  &__list {
    padding-bottom: $andes-spacing-16;
  }

  &__tables {
    display: flex;
    flex-direction: column;
    gap: $andes-spacing-16;
  }

  &__table {
    border: 1px solid $andes-bg-primary;
    border-radius: 5px;

    &__column,
    &__column-title:first-of-type {
      padding-left: $andes-spacing-16;
    }

    &__column-title {
      font-weight: $font-weight-semibold;
    }

    .andes-table__column {
      background-color: transparent;
      border-bottom: 0;
    }

    // *** TO DO **
    // Todo lo que es ".andes-table"
    // es un fix para poder renderizar bien la tabla de andes
    // dentro de mobile ya que desktop tiene una media query
    // que rompe los estilos.
    // El fix real deberia ser desde Andes:
    // Una vez este ese FIX eliminar este bloque de código y agregar @import '@andes/table/lib/styles/desktop';
    .andes-table {
      border: 0;

      &__head {
        position: relative;
        clip-path: none;
        height: auto;
        width: auto;
        overflow: auto;
      }

      &__row {
        display: table-row;
        border: 0;

        &:hover {
          background-color: $table-gray-hover;
        }

        &:last-child {
          .andes-table__column {
            word-break: break-all;
            border-bottom: 0;
          }
        }
      }

      &__header,
      &__column {
        vertical-align: middle;
        display: table-cell;
        text-align: left;
        white-space: normal;

        &::before {
          content: '';
          margin: 0;
          padding: 0;
          width: 100%;
        }
      }

      &__column:last-of-type {
        width: 60%;
        padding-left: $andes-spacing-24;
      }

      &__header--center,
      &__column--center {
        text-align: center;
      }

      &__header--right,
      &__column--right {
        text-align: right;
      }

      &__column--left {
        padding: 16px;
      }

      &__body {
        display: table-row-group;
      }
    }

    // FIN BLOQUE A REEMPLAZAR

    tbody {
      tr:nth-child(even),
      tr:nth-child(even):hover {
        background: $andes-white;
      }

      tr:nth-child(odd),
      tr:nth-child(odd):hover {
        background: $andes-bg-secondary;
      }

      tr:nth-child(even) {
        .andes-table__column:first-child,
        .andes-table__header:first-child {
          background: $andes-bg-secondary;
        }
      }

      tr:nth-child(odd) {
        .andes-table__column:first-child,
        .andes-table__header:first-child {
          background: $table-column-bg-color;
        }
      }

      tr:first-child {
        th {
          border-top-left-radius: $border-radius-4;
        }

        td {
          border-top-right-radius: $border-radius-4;
        }
      }

      tr:last-child {
        th {
          border-bottom-left-radius: $border-radius-4;
        }

        td {
          border-bottom-right-radius: $border-radius-4;
        }
      }
    }
  }

  &__tabs {
    margin-top: $andes-spacing-32;

    .andes-tab-content {
      padding: $andes-spacing-12 $andes-spacing-24;
    }

    .andes-tabs__container {
      position: relative;
    }
  }

  &__tab-spec {
    width: 50%;
    vertical-align: top;
    display: inline-block;
    padding-top: $andes-spacing-12;
    font-size: $font-size-14;
    padding-right: $andes-spacing-12;
  }
}
