@import './installation-widget';

.ui-pdp-installation {
  margin-top: 25px;
}

.ui-pdp-installation-modal {
  display: block;
  height: 100%;
  width: 90%;

  .andes-modal__header {
    padding: $andes-spacing-24;
  }

  .andes-modal__content {
    padding: 0 $andes-spacing-24 $andes-spacing-24;
  }

  .andes-modal__close-button path {
    stroke: $andes-gray-900;
  }
}
