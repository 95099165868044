$trade-in-content-prefix: #{$pdp-prefix}-trade-in__content;

.#{$trade-in-content-prefix} {
  display: grid;
  gap: $andes-spacing-16;
  padding: $andes-spacing-16;
}

.#{$trade-in-content-prefix}__label {
  display: flex;
  align-items: flex-start;

  .ui-pdp-media__figure {
    margin-right: 6px;
  }

  .ui-pdp-icon {
    display: flex;
    height: $andes-spacing-12;
    margin-top: 2px;
  }
}

.#{$trade-in-content-prefix}__actions {
  display: flex;
  align-items: flex-start;
  gap: 6px;
}
