@import '../../../styles/variables';
@import '~@andes/bottom-sheet/index';

.#{$pdp-prefix}-bookmark {
  &__link-bookmark {
    position: relative;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
  }

  &__modal-wishlist {
    width: 100% !important;
    max-width: 480px !important;

    .andes-modal__content {
      padding: 32px !important;
      padding-top: 0 !important;
    }

    .andes-modal__close-button path {
      stroke: $pdp-gray-300;
    }

    .andes-modal__header {
      padding: 20px 32px !important;

      .andes-modal__title {
        font-size: 24px;
      }
    }
  }

  &__icon-bookmark {
    stroke: $andes-blue-500;
    fill: none;
    position: absolute;
    width: 24px;
    height: 21px;
    overflow: visible;

    g {
      stroke-width: 1.5;
    }
  }

  &__icon-bookmark-fill {
    width: 24px;
    height: 21px;
    fill: $andes-blue-500;
    stroke: $andes-blue-500;
    transition: opacity 0.25s ease-in-out, transform 0.35s cubic-bezier(0, 0, 0.3, 1.5);
    opacity: 0;
    transform: scale(0);

    g {
      stroke-width: 1.5;
    }

    &--active {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__label {
    font-size: $font-size-14;
    margin-left: $andes-spacing-8;
    color: $andes-blue-500;
    font-weight: $font-weight-regular;
    white-space: nowrap;
    vertical-align: top;

    &--hidden {
      display: none;
    }
  }
}

.snackbar--remove-bookmark,
.snackbar--add-bookmark {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.andes-modal__header--shadow {
  box-shadow: $pdp-gray-100;

  .andes-modal__title {
    font-size: 20px !important;
  }
}
