.preload-iframe {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;

  &.hidden {
    display: none;
  }

  &.showed {
    display: block;
  }

  .preload-iframe-element {
    width: 100%;
    height: 100%;
    border: none;

    &--overlay {
      width: 100%;
      height: 100%;
    }
  }
}
