@import '../../../styles/variables';

@mixin store-header {
  width: 100%;
  display: flex;
  align-items: center;

  &__image-container {
    align-items: center;
    display: flex;
    height: 56px;
    width: 56px;
    justify-content: center;
    margin-right: $andes-spacing-8;
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: color(color_gray_lighter);
      left: 0;
    }

    &__image {
      height: 56px;
      width: 56px;
      object-fit: contain;
    }
  }

  &__text {
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
  }
}
