.#{$pdp-prefix}--sticky-wrapper {
  position: sticky;
  margin-bottom: $andes-spacing-24;
  z-index: 0;
  will-change: transform, top;

  &-right {
    z-index: 1;
  }
}
