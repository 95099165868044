.#{$vpp-prefix}-gift-registry {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.ui-pdp-container__row .ui-pdp-container__row--gift-registry {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
