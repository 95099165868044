@import './bookmark';

.#{$pdp-prefix}-bookmark {
  position: relative;

  &__link-bookmark {
    position: absolute;
    right: 0;
    top: 0;
  }
}

// sass-lint:disable no-qualifying-elements //
.#{$pdp-prefix}-fetching-component + form.#{$pdp-prefix}-bookmark__link-bookmark {
  position: absolute;
}
