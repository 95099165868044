@import './enhanced-content';

.pdp-enhanced-content {
  &__title {
    font-size: $font-size-24;
    padding: 0;
  }

  &__image {
    padding: 0;
  }

  &__expandable {
    padding: 0;
  }

  &__buttons {
    width: 70%;
  }

  &__pictures {
    .video-js .vjs-skip-backward-10 {
      width: 3em;
    }

    .vjs-icon-replay-10::before,
    .video-js .vjs-skip-backward-10 .vjs-icon-placeholder::before {
      content: '';
      background-image: url('../../../assets/images/backward.svg');
      background-repeat: no-repeat;
      background-size: initial;
      background-position: center;
    }

    .video-js .vjs-skip-forward-10 {
      width: 3em;
    }

    .vjs-icon-forward-10::before,
    .video-js .vjs-skip-forward-10 .vjs-icon-placeholder::before {
      content: '';
      background-image: url('../../../assets/images/forward.svg');
      background-repeat: no-repeat;
      background-size: initial;
      background-position: center;
    }
  }
}
