$no-last-questions: 'no-last-questions';

.#{$pdp-prefix}-qadb__#{$no-last-questions} {
  .#{$no-last-questions}__title,
  .#{$no-last-questions}__subtitle {
    color: color(color_gray_middle);
    font-size: $font-size-14;
  }

  .#{$no-last-questions}__title {
    font-weight: $font-weight-semibold;
  }
}
