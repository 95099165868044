.#{$pdp-prefix}-actions__container .andes-button.andes-spinner__icon-base .andes-spinner__icon {
  position: absolute;
  inset: 0;
  margin: 0 auto;
  animation: rotate-all 1s linear infinite;
  width: 18px;
  height: 18px;
}
.#{$pdp-prefix}-actions {
  &__container {
    .andes-button {
      width: 100%;
    }
  }
}
