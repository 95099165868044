.#{$pdp-prefix} {
  &__info-tooltip {
    &__ctas-container {
      display: flex;
    }

    &__text {
      display: block;
      margin-bottom: 14px;
      line-height: 1.3em;
    }

    &__link {
      align-self: center;
      margin-left: 24px;
    }

    &__withoutMargin {
      align-self: left;
      margin-left: 0;
    }
  }
}
