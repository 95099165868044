@import './header-commons';

.#{$pdp-prefix}__header-official-store {
  @include store-header;

  margin-bottom: 10px;
}

.#{$pdp-prefix}-container__row--header-official-store {
  max-height: 24px;
  margin: -9px 0 17px;
}

.#{$pdp-prefix}-header-official-store {
  &__bg-image {
    position: relative;
  }

  &__bg-image,
  &__image-wrapper {
    height: 250px;
    width: 100%;
  }

  &__image-wrapper {
    pointer-events: none;
    position: absolute;
    z-index: -1;

    img {
      object-fit: cover;
      object-position: left top;
      width: 100%;
      height: 100%;
    }
  }

  &__logo-container {
    max-width: $pdp-layout-width;
    margin: 0 auto;
    position: relative;
    display: flex;
    top: 24px;
    z-index: 2;
  }

  &__logo-image-wrapper {
    border-radius: 4px;
    position: relative;
    width: 96px;
    height: 70px;
    background-color: $andes-white;
  }

  &__logo-image {
    width: auto;
    max-width: 96px;
    height: auto;
    max-height: 70px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 4px;
  }

  &-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $andes-spacing-16;
    width: fit-content;
    height: 96px;
    background: $andes-white;
    border-radius: 6px;

    &__title-container {
      display: flex;
      flex-direction: column;
      cursor: default;
    }

    &__image-container {
      align-items: center;
      border-radius: 4px;
      display: flex;
      height: 64px;
      justify-content: center;
      margin-right: $andes-spacing-12;
      overflow: hidden;
      position: relative;
      width: 64px;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: color(color_gray_lighter);
        left: 0;
      }

      &__image {
        object-fit: contain;
        position: relative;
        width: 64px;
        height: 64px;
      }
    }
  }
}
