.#{$pdp-prefix}-specs {
  color: color(color_gray_dark);

  &__title {
    font-size: $font-size-24;
    padding-bottom: $andes-spacing-32;
  }

  &-groups__subtitle {
    font-size: $font-size-20;
    padding-top: $andes-spacing-20;
  }

  &__list {
    padding-bottom: 0;
  }

  &__table__column-title,
  &__table__column {
    font-size: $font-size-16;
  }

  &__tabs {
    .andes-tabs__container {
      .andes-tab {
        width: initial;
      }
    }
  }

  &__compats {
    margin-bottom: $andes-spacing-32;
  }
}

.#{$pdp-prefix}-specs__compats-disclaimer,
.ui-vpp-highlighted-specs__compats-disclaimer,
.ui-pdp-seller__compats-disclaimer {
  color: $andes-gray-550;
  font-weight: $font-weight-regular;
  font-size: $font-size-14;
  line-height: $font-size-18;
  margin-top: 10px;
  margin-left: $andes-spacing-4;
}
