@import '../components/hs-highlighted-attributes/styles/hs-highlighted-attributes.desktop';
@import '../components/hs-highlighted-attributes-list/styles/hs-highlighted-attributes-list.desktop';
@import '../components/hs-highlighted-features/styles/hs-highlighted-features.desktop';
@import '../components/hs-highlighted-features-list/styles/hs-highlighted-features-list.desktop';
@import '../components/hs-discrete-bar/styles/hs-discrete-bar.desktop';
@import '../components/hs-continous-bar/styles/hs-continous-bar.desktop';
@import '../components/hs-key-value/styles/hs-key-value.desktop';
@import '../components/hs-filled-icon/styles/hs-filled-icon';
@import '../components/hs-striped-specs/styles/hs-striped.desktop';
@import '../components/hs-additional-information/styles/styles.desktop';

.#{$vpp-prefix}-highlighted-specs-rex {
  padding-bottom: $andes-spacing-40;
  border-top: 1px solid rgb(0 0 0 / 10%);
  padding-top: $andes-spacing-40;
}

.#{$vpp-prefix}-highlighted-specs {
  width: 100%;

  &__features {
    max-width: 340px;
    overflow-wrap: break-word;
    width: 100%;
  }

  .ui-pdp-container__row {
    &--highlighted-features-title {
      margin-top: 32px;
      margin-left: 0;
      margin-right: 0;
    }

    &--highlighted-attributes {
      margin-top: 32px;
      display: block;
    }

    &--highlighted-attributes-list {
      margin-top: 32px;
    }

    &--discrete-bar {
      margin-top: 32px;
    }

    &--highlighted-attributes-all {
      margin-top: 8px;
    }

    &--compats-widget,
    &--attributes {
      margin-top: 32px;
    }

    &--technical-specifications {
      margin-top: 16px;
    }
  }

  &__attribute-columns {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;

    &__column {
      width: 100%;
      overflow: hidden;
    }

    &__row {
      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }
  }

  &__key-value__labels,
  &__key-value__labels__key-value {
    overflow: hidden;
  }

  &__key-value__labels__key-value {
    span {
      overflow-wrap: anywhere;
    }
  }
}
