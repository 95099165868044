.#{$pdp-prefix}-pick-up {
  &--remaining {
    .ui-pdp-media__text {
      max-width: 223px;

      [data-country='BR'] & {
        max-width: 209px;
      }

      span {
        display: inline-table;
      }
    }
  }
}
