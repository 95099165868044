.#{$vpp-prefix}-highlighted-specs__continuous-bar {
  width: 100%;
  display: flex;
  align-items: center;

  &__attribute {
    width: 100%;
    margin-left: 12px;
  }

  &__labels {
    display: flex;
    justify-content: space-between;
  }

  &__progress {
    height: 8px;
    margin-top: 6px;
    margin-bottom: 0;
  }
}
