.ui-vpp-questions-ai-user-feedback {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__buttons {
    display: flex;

    .andes-button {
      height: $andes-spacing-32;
      border-radius: 100px;
      border: 1px solid $button-border;
      color: $andes-gray-550;
      margin-right: $andes-spacing-8;
      padding: 0 $andes-spacing-8;

      &:hover {
        background-color: $andes-blue-100;
        color: $andes-blue-500;
        border-color: $andes-blue-500;

        path {
          stroke: none !important;
          fill: $andes-blue-500;
        }
      }
    }

    .andes-button:last-child {
      .andes-button__content {
        left: 1px;
      }
    }

    .ui-vpp-questions-ai-user-feedback__button {
      color: $andes-gray-550;
      padding: 0;
      font-weight: $font-weight-light;
      outline: none;
      box-shadow: none;
      border: 0;

      &:hover {
        cursor: pointer;
        background-color: $andes-blue-100;
        color: $andes-blue-500;
        outline: none;
        box-shadow: none;
        border: 1px solid $andes-blue-500;

        path {
          stroke: transparent !important;
          fill: $andes-blue-500;
        }
      }

      path {
        stroke: transparent !important;
        fill: $andes-gray-550;
        fill-opacity: 1;
      }

      &-like {
        border: 1px solid $button-border;
        border-radius: 100px;
        padding: 0 $andes-spacing-8;
        height: $andes-spacing-32;
        display: flex;
        flex-direction: row;
        gap: $andes-spacing-12;
        box-sizing: border-box;
        font-weight: $font-weight-semibold;

        .andes-button__content {
          gap: 6px;

          &::after {
            content: none;
          }
        }

        &__text {
          font-size: $font-size-12;
          font-weight: $font-weight-semibold;
          white-space: nowrap;
        }
      }

      &-dislike {
        border: 1px solid $button-border;
        border-radius: 100px;
        display: flex;
        padding: 0 $andes-spacing-8;
        box-sizing: border-box;
        width: $andes-spacing-32;
        height: $andes-spacing-32;
      }
    }

    .active {
      pointer-events: none;
      background-color: $andes-blue-500;
      color: $andes-white;
      border: none;

      &.andes-button.andes-button--transparent:focus {
        background-color: $andes-blue-500;
        border: none;
        color: $andes-white;
      }

      &.andes-button.andes-button--transparent:hover {
        background-color: transparent;
        border-color: transparent;
        color: #3483fa;
      }

      path {
        stroke: none !important;
        fill: $andes-white;
      }
    }
  }
}
