.clip-player-container {
  height: inherit;
  position: absolute;
  width: inherit;
  display: none;
  background: transparent;
  z-index: -1;

  video {
    object-fit: cover;
  }

  &--mobile {
    @media screen and (orientation: landscape) {
      video {
        object-fit: contain;
      }
    }
  }
}

.clip-video-container {
  &--hide {
    display: none;
  }
}

.clip-player {
  width: inherit;
  height: inherit;
}

.player-fullscreen {
  height: 100%;
  width: 100%;

  video {
    width: inherit;
    height: inherit;
  }

  .clip-player-container {
    display: block;
  }
}

.video-container {
  height: 100%;
  width: 100%;

  &__tap {
    display: block;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  .clip-player-container {
    display: block;
  }
}
